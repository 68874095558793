import { Menu, Radio } from 'antd';
import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getUserInfo, logout, updateLanguage } from '../services/authServices';
import {
    DashboardOutlined,
    WechatOutlined,
    DeploymentUnitOutlined,
    AppstoreOutlined,
    DatabaseOutlined,
    LogoutOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    HistoryOutlined,
    ExclamationCircleOutlined,
    UserOutlined,
    HolderOutlined,
    FieldTimeOutlined,
} from '@ant-design/icons';
import { useLanguageContext } from '../locales/LanguageContext';
import useTranslation from '../locales/translations';

export default function Sidebar() {
    const { language, changeLanguage } = useLanguageContext();
    const navigate = useNavigate();
    const translation = useTranslation().general;
    const user = getUserInfo();

    const handleSignout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (err) {
            console.log(err);
        }
    };
    const isAdmin = user.role === 'admin';

    const handleChange = async (e) => {
        try {
            await updateLanguage(getUserInfo()._id, e.target.value).then(() => {
                user.language = e.target.value;
                sessionStorage.setItem('user', JSON.stringify(user));
                changeLanguage(e.target.value);
            });
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        changeLanguage(user.language);
    }, []);

    return (
        <>
            <Menu theme="light" mode="inline">
                <div className="width-100 text-center">
                    <Radio.Group
                        className="margin-top-24"
                        optionType="button"
                        buttonStyle="solid"
                        value={language}
                        onChange={handleChange}>
                        <Radio.Button value="fr">Français</Radio.Button>
                        <Radio.Button value="en">English</Radio.Button>
                    </Radio.Group>
                    <hr style={{ width: '80%', margin: 'auto', marginTop: 12 }} />
                </div>

                <Menu.Item key="dashboard">
                    <NavLink to="/">
                        <span className="icon">
                            <DashboardOutlined />
                        </span>
                        <span className="label">{translation.DASHBOARD}</span>
                    </NavLink>
                </Menu.Item>
                <Menu.Item key="mychatbots">
                    <NavLink to="/mychatbots">
                        <span className="icon">
                            <WechatOutlined />
                        </span>
                        <span className="label">{translation.AI_ASSISTANTS}</span>
                    </NavLink>
                </Menu.Item>
                {isAdmin && (
                    <>
                        <Menu.Item className="menu-item-header" key="adminpage">
                            {translation.ADMIN_PAGE}
                        </Menu.Item>
                        <Menu.Item key="pineconeindex">
                            <NavLink to="/pineconeindex">
                                <span className="icon">
                                    <DeploymentUnitOutlined />
                                </span>
                                <span className="label">{translation.PINECONE}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="createbotbulk">
                            <NavLink to="/createbotbulk">
                                <span className="icon">
                                    <AppstoreOutlined />
                                </span>
                                <span className="label">{translation.CREATE_BOT_IN_BULK}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="botsinbulk">
                            <NavLink to="/botsinbulk">
                                <span className="icon">
                                    <DatabaseOutlined />
                                </span>
                                <span className="label">{translation.BOTS_IN_BULK}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="defaultsettings">
                            <NavLink to="/defaultsettings">
                                <span className="icon">
                                    <SettingOutlined />
                                </span>
                                <span className="label">{translation.DEFAULT_SETTINGS}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="manageprompts">
                            <NavLink to="/manageprompts">
                                <span className="icon">
                                    <QuestionCircleOutlined />
                                </span>
                                <span className="label">{translation.MANAGE_PROMPTS}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="promptlogs">
                            <NavLink to="/promptlogs">
                                <span className="icon">
                                    <HistoryOutlined />
                                </span>
                                <span className="label">{translation.PROMPT_LOGS}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="responses">
                            <NavLink to="/responses">
                                <span className="icon">
                                    <FieldTimeOutlined />
                                </span>
                                <span className="label">{translation.RESPONSES}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="errors">
                            <NavLink to="/errors">
                                <span className="icon">
                                    <ExclamationCircleOutlined />
                                </span>
                                <span className="label">{translation.ERROR_LOGS}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="users">
                            <NavLink to="/users">
                                <span className="icon">
                                    <UserOutlined />
                                </span>
                                <span className="label">{translation.USERS}</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="levels">
                            <NavLink to="/levels">
                                <span className="icon">
                                    <HolderOutlined />
                                </span>
                                <span className="label">{translation.USER_LEVEL}</span>
                            </NavLink>
                        </Menu.Item>
                    </>
                )}
                <Menu.Item key="logout">
                    <NavLink to="/signin" onClick={handleSignout}>
                        <span className="icon">
                            <LogoutOutlined />
                        </span>
                        <span className="label">{translation.LOG_OUT}</span>
                    </NavLink>
                </Menu.Item>
            </Menu>
        </>
    );
}
