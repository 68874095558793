import { Tooltip, Button, Divider, Input, notification } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef, useMemo } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getBulkBot, getCalendlyURL } from '../../services/adminServices';
import { PopupModal } from 'react-calendly';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import useTranslation from '../../locales/translations';

export default function Assistant() {
    const translation = useTranslation().admin.assistant;
    const { botId } = useParams();
    const [messageState, setMessageState] = useState({
        messages: [],
        pending: '',
    });
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const msgColor = 'rgb(33, 219, 205)';

    const chatContentRef = useRef(null);
    const suggestMsgRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(60);

    const { messages, pending } = messageState;
    const [freeaccess, setFreeaccess] = useState(true);
    const [url, setUrl] = useState('');
    const [systemMsg, setSystemMsg] = useState('');
    const [calendly, setCalendly] = useState('');
    const [showCalendly, setShowCalendly] = useState(false);
    const [chatId, setChatId] = useState(null);
    const chatSource = translation.CHATSOURCE_ASSIST;

    const openURLInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const urlToOpen = 'https://mr-smith.ai/';

    const fetchBulkBot = async () => {
        try {
            await getBulkBot(botId).then((res) => {
                if (res.data) {
                    setFreeaccess(res.data.freeaccess);
                    setUrl(res.data.website);
                    setSystemMsg(res.data.systemMsg);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchCalendly = async () => {
        try {
            await getCalendlyURL(botId).then((res) => {
                if (res.data) {
                    setCalendly(res.data);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchCalendly();
        fetchBulkBot();
        if (freeaccess) {
            document.getElementById('query').addEventListener('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    if (document.getElementById('query') !== '') {
                        document.getElementById('send').click();
                    }
                }
            });
        }
    }, [botId]);

    useEffect(() => {
        if (freeaccess) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
            if (query) {
                setMessageState((state) => ({
                    ...state,
                    messages: [...state.messages, { content: query, role: 'user' }],
                    pending: '',
                }));
                setIsFetching(true);
            }
            const suggestMsgHeight = suggestMsgRef.current ? suggestMsgRef.current.offsetHeight : 0;
            setMarginBottom(60 + suggestMsgHeight);
        }
    }, [query]);

    useEffect(() => {
        if (freeaccess && isFetching) {
            document.getElementById('loading').style.display = 'flex';
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
            const ctrl = new AbortController();
            async function fetchAnswer() {
                try {
                    await fetchEventSource(`/api/chatbot/getBulkBotReply`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            namespace: botId,
                            messages: messages,
                            systemMsg,
                            chatId: chatId,
                            chatSource: chatSource,
                        }),
                        signal: ctrl.signal,
                        onmessage: (event) => {
                            document.getElementById('loading').style.display = 'none';
                            if (JSON.parse(event.data).data === '[DONE]') {
                                setMessageState((state) => ({
                                    messages: [
                                        ...state.messages,
                                        {
                                            content: state.pending ?? '',
                                            role: 'assistant',
                                        },
                                    ],
                                    pending: '',
                                }));
                                setQuery('');
                                setIsFetching(false);
                                setMsg('');
                                ctrl.abort();
                            } else {
                                const data = JSON.parse(event.data);
                                const chatId = JSON.parse(event.data).chatId;
                                setChatId(chatId);
                                setMessageState((state) => ({
                                    ...state,
                                    pending: (state.pending ?? '') + data.data,
                                }));
                            }
                        },
                        onerror(err) {
                            notification.error({
                                message: translation.ERROR_MSG,
                                placement: 'topRight',
                            });
                            setQuery('');
                            setIsFetching(false);
                            setMsg('');
                            ctrl.abort();
                            document.getElementById('loading').style.display = 'none';
                            throw err;
                        },
                    });
                } catch (err) {}
            }
            fetchAnswer();
        }
    }, [isFetching]);

    const chatMessages = useMemo(() => {
        return [
            ...messages,
            ...(pending
                ? [
                      {
                          content: pending,
                          role: 'assistant',
                      },
                  ]
                : []),
        ];
    }, [messages, pending]);

    //scroll to bottom of chat
    useEffect(() => {
        if (freeaccess && chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const handleSendQuery = async (message) => {
        if (!query) {
            setQuery(message);
        }
    };

    const handleInputChange = (e) => {
        setMsg(e.target.value);
    };

    const getLuminance = (color) => {
        const rgb = color.match(/\d+/g);

        const r = rgb[0];
        const g = rgb[1];
        const b = rgb[2];

        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luminance;
    };

    return (
        <>
            <div className="text-center" style={{ margin: 12 }}>
                <div style={{ width: 200, margin: 'auto', marginTop: 32 }}>
                    <img src="/logo.svg" alt="MrSmith AI" />
                </div>
                <p style={{ fontSize: 24, fontWeight: 600 }}>
                    <p>{translation.FIRST_TEXT}</p>
                </p>
                <div style={{ margin: 'auto', maxWidth: 800 }}>
                    <p>{translation.SECOND_TEXT}</p>
                    <p>
                        {translation.THIRD_TEXT}
                        <span style={{ color: '#21dbcd' }}>{url}</span>)
                    </p>
                    <p>
                        <span>{translation.FOURTH_TEXT}</span>
                        <br />
                        <span>{translation.FIFTH_TEXT}</span>
                    </p>
                </div>
                <p
                    style={{
                        margin: 'auto',
                        fontSize: 20,
                        fontWeight: 600,
                        maxWidth: 1024,
                        marginTop: 12,
                    }}>
                    <Link
                        onClick={() => openURLInNewTab(urlToOpen)}
                        style={{ color: '#21dbcd', textDecoration: 'none' }}>
                        {translation.SIXTH_TEXT}
                    </Link>
                    📈 💼
                </p>

                {freeaccess ? (
                    <div className="history" style={{ maxWidth: 1024, margin: '20px auto' }}>
                        <div className="display-flex margin-12 align-center">
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        fontWeight: 700,
                                        fontSize: 20,
                                        alignItems: 'center',
                                    }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 283.46 283.46"
                                        className="profile-image">
                                        <g id="Calque_2" data-name="Calque 2">
                                            <g id="Calque_1-2" data-name="Calque 1">
                                                <circle
                                                    cx="141.73"
                                                    cy="141.73"
                                                    r="141.73"
                                                    style={{ fill: '#21dbcd' }}
                                                />
                                                <circle
                                                    cx="108.6"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                />
                                                <circle
                                                    cx="174.88"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                />
                                                <path
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                    d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                    <span style={{ marginLeft: 8 }}>{translation.AI_ASSISTANT}</span>
                                </div>
                            </div>
                        </div>
                        <Divider
                            style={{
                                margin: 0,
                            }}
                        />
                        <div className="chat-content" ref={chatContentRef} style={{ marginBottom: marginBottom }}>
                            <div className="bot-text" style={{ padding: 16 }}>
                                {translation.HELLO_HEAD}
                                <span style={{ color: '#21dbcd' }}>{url}</span>
                                {translation.HELLO_END}
                            </div>
                            {chatMessages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`${message.role === 'user' ? 'customer' : 'bot'}-text`}
                                    style={
                                        message.role === 'user'
                                            ? {
                                                  backgroundColor: `${msgColor}`,
                                                  color: `${getLuminance(msgColor) > 180 ? '#2e2d2c' : 'white'}`,
                                              }
                                            : null
                                    }>
                                    <ReactMarkdown
                                        remarkPlugins={[remarkMath, rehypeKatex]}
                                        linkTarget="_blank"
                                        rel="noopener noreferrer">
                                        {message.content}
                                    </ReactMarkdown>
                                </div>
                            ))}

                            <div className="bot-text" id="loading" style={{ display: 'none' }}>
                                <div className="dot-flashing" />
                            </div>
                        </div>
                        <div className="question-container">
                            <Input
                                id="query"
                                placeholder={translation.INPUT_PLACEHOLDER}
                                value={msg}
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                                onChange={handleInputChange}
                                disabled={isFetching}
                                suffix={
                                    <Tooltip title={translation.SEND}>
                                        <Button
                                            id="send"
                                            icon={<SendOutlined style={{ fontSize: 20 }} />}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                            }}
                                            onClick={() => handleSendQuery(msg)}
                                        />
                                    </Tooltip>
                                }
                                autoComplete="off"
                            />
                        </div>
                    </div>
                ) : (
                    <h1>{translation.ACCESS_ERROR_MSG}</h1>
                )}
                {calendly ? (
                    <Button
                        className="normal-component"
                        type="primary"
                        size="large"
                        style={{ borderRadius: 50, height: 'auto' }}
                        onClick={() => setShowCalendly(true)}>
                        {translation.CALENDLY_BUTTON_TEXT}
                    </Button>
                ) : null}
                <PopupModal
                    url={calendly}
                    onModalClose={() => setShowCalendly(false)}
                    open={showCalendly}
                    rootElement={document.getElementById('root')}
                />
            </div>
        </>
    );
}
