import { Link, useParams } from 'react-router-dom';
import { Layout, Card, Alert } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { verifyUser, getUserByToken } from '../../services/authServices';
import useTranslation from '../../locales/translations';

const { Content } = Layout;

export default function Confirm() {
    const translation = useTranslation().auth;
    const { token } = useParams();
    const [user, setUser] = useState([]);

    const fetchUser = useCallback(async () => {
        try {
            const response = await getUserByToken(token);
            setUser(response.data);
        } catch (err) {
            throw err;
        }
    }, [token]);

    const verify = useCallback(async () => {
        try {
            await verifyUser(token).then(() => {
                fetchUser();
            });
        } catch (err) {
            throw err;
        }
    }, [token, fetchUser]);

    useEffect(() => {
        verify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Layout className="layout-default">
                <Content className="">
                    <Card
                        className="card-signin"
                        title={
                            <h2>
                                🎉{translation.WELCOME}
                                {user.name}!!!🎉
                            </h2>
                        }
                        bordered="false"
                        style={{ display: `${user.isVerified ? 'block' : 'none'}` }}>
                        <Alert
                            message={translation.alert.CONFIRM_MESSAGE}
                            description={
                                <div>
                                    {translation.alert.CONFIRM_DES_HEADER}
                                    <Link to="/signin" className="font-bold text-dark">
                                        {translation.alert.CONFIRM_DES_BODY}
                                    </Link>
                                    .
                                </div>
                            }
                            type="success"
                            showIcon
                        />
                    </Card>
                </Content>
            </Layout>
        </>
    );
}
