import { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, InputNumber, notification, Popconfirm, Form, Checkbox, Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { createLevel, getLevels, updateLevel, deleteLevel } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function Levels() {
    const translation = useTranslation().admin.levels;
    const [levels, setLevels] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [addForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [addDataSource, setAddDataSource] = useState({
        enableFiles: false,
        enableText: true,
        enableWebsite: true,
        enableVideos: false,
        branding: false,
    });

    const [updateDataSource, setUpdateDataSource] = useState({
        enableFiles: false,
        enableText: true,
        enableWebsite: true,
        enableVideos: false,
        branding: false,
    });

    const [adding, setAdding] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchLevels = async () => {
        try {
            setLoading(true);
            const res = await getLevels();
            setLevels(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchLevels();
    }, []);

    const handleAdd = () => {
        setShowAdd(true);
    };

    const handleEdit = (record) => {
        updateForm.setFieldsValue(record);
        setUpdateDataSource({
            enableFiles: record.enableFiles,
            enableText: record.enableText,
            enableWebsite: record.enableWebsite,
            enableVideos: record.enableVideos,
            branding: record.branding,
        });
        setShowUpdate(true);
    };

    const handleAddLevel = async () => {
        setAdding(true);
        try {
            const values = await addForm.validateFields();
            values.enableFiles = addDataSource.enableFiles;
            values.enableText = addDataSource.enableText;
            values.enableWebsite = addDataSource.enableWebsite;
            values.enableVideos = addDataSource.enableVideos;
            values.branding = addDataSource.branding;
            await createLevel(values);
            notification.success({
                message: translation.notification.ADD_SUCCESS,
                placement: 'topRight',
            });
            setAddDataSource({
                enableFiles: false,
                enableText: true,
                enableWebsite: true,
                enableVideos: false,
                branding: false,
            });
            setShowAdd(false);
            fetchLevels();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setAdding(false);
        }
    };

    const handleUpdateLevel = async () => {
        setUpdating(true);
        try {
            const values = await updateForm.validateFields();
            values.enableFiles = updateDataSource.enableFiles;
            values.enableText = updateDataSource.enableText;
            values.enableWebsite = updateDataSource.enableWebsite;
            values.enableVideos = updateDataSource.enableVideos;
            values.branding = updateDataSource.branding;
            await updateLevel(values);
            notification.success({
                message: translation.notification.UPDATE_SUCCESS,
                placement: 'topRight',
            });
            setShowUpdate(false);
            fetchLevels();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setUpdating(false);
        }
    };

    const handleDelete = async (name) => {
        try {
            setDeleting(true);
            await deleteLevel(name);
            fetchLevels();
            notification.success({
                message: translation.notification.DELETE_SUCCESS,
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setDeleting(false);
        }
    };

    const changeAddDataSource = (name, value) => {
        setAddDataSource((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const changeUpdateDataSource = (name, value) => {
        setUpdateDataSource((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.NAME,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translation.BOTLIMIT,
            dataIndex: 'numberOfBots',
            key: 'numberOfBots',
        },
        {
            title: translation.QUERYLIMIT,
            dataIndex: 'numberOfQueries',
            key: 'numberOfQueries',
        },
        {
            title: translation.CHARACTERLIMIT,
            dataIndex: 'numberOfCharacters',
            key: 'numberOfCharacters',
        },
        {
            title: translation.DATASOURCE,
            dataIndex: 'dataSource',
            key: 'dataSource',
            render: (text, record) => (
                <span>
                    {record.enableFiles && translation.FILES}
                    {record.enableFiles && (record.enableText || record.enableWebsite || record.enableVideos) && ', '}
                    {record.enableText && translation.TEXT}
                    {record.enableText && (record.enableWebsite || record.enableVideos) && ', '}
                    {record.enableWebsite && translation.WEBSITE}
                    {record.enableWebsite && record.enableVideos && ', '}
                    {record.enableVideos && translation.VIDEOS}
                </span>
            ),
        },
        {
            title: translation.GPT4,
            dataIndex: 'gpt4',
            key: 'gpt4',
            render: (text) => (text === 'yes' ? translation.YES : translation.NO),
        },
        {
            title: translation.REMOVE_BRANDING,
            dataIndex: 'branding',
            key: 'branding',
            render: (text) => <span>{text ? translation.TRUE : translation.FALSE}</span>,
        },
        {
            title: translation.ACTION,
            dataIndex: 'action',
            key: 'action',
            width: 110,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        style={{ border: 'none', marginRight: 8 }}
                        onClick={() => handleEdit(record)}
                        type="primary"
                        ghost
                    />
                    {record.name !== 'Free' ? (
                        <Popconfirm
                            title={translation.DELETE_TITLE}
                            description={translation.DELETE_DESCRIPTION}
                            onConfirm={() => handleDelete(record.name)}
                            okText={translation.OK}
                            cancelText={translation.CANCEL}
                            okButtonProps={{
                                loading: deleting,
                            }}>
                            <Button danger icon={<DeleteOutlined />} type="text" />
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div
                    style={{
                        fontSize: 32,
                        fontWeight: 700,
                        margin: 12,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span>{translation.TITLE}</span>
                    <Button type="primary" onClick={handleAdd} loading={adding}>
                        {translation.ADD_LEVEL}
                    </Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={levels}
                    bordered
                    scroll={{
                        x: 700,
                    }}
                />

                <Modal
                    title={translation.ADD_LEVEL}
                    closable={false}
                    open={showAdd}
                    onCancel={() => setShowAdd(false)}
                    footer={null}>
                    <Form form={addForm} layout="vertical" onFinish={handleAddLevel}>
                        <Form.Item
                            label={translation.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.NAME} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.BOTLIMIT}
                            name="numberOfBots"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.BOTLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={2} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.QUERYLIMIT}
                            name="numberOfQueries"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.QUERYLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={2500} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.CHARACTERLIMIT}
                            name="numberOfCharacters"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.CHARACTERLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={500000} autoComplete="off" />
                        </Form.Item>
                        <Form.Item label={translation.DATASOURCE}>
                            <Checkbox
                                checked={addDataSource.enableFiles}
                                onChange={(event) => changeAddDataSource('enableFiles', event.target.checked)}>
                                {translation.FILES}
                            </Checkbox>
                            <Checkbox
                                checked={addDataSource.enableText}
                                onChange={(event) => changeAddDataSource('enableText', event.target.checked)}>
                                {translation.TEXT}
                            </Checkbox>
                            <Checkbox
                                checked={addDataSource.enableWebsite}
                                onChange={(event) => changeAddDataSource('enableWebsite', event.target.checked)}>
                                {translation.WEBSITE}
                            </Checkbox>
                            <Checkbox
                                checked={addDataSource.enableVideos}
                                onChange={(event) => changeAddDataSource('enableVideos', event.target.checked)}>
                                {translation.VIDEOS}
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="GPT-4"
                            name="gpt4"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the GPT-4 option.',
                                },
                            ]}>
                            <Select
                                className="width-100"
                                options={[
                                    {
                                        value: 'yes',
                                        label: translation.YES,
                                    },
                                    {
                                        value: 'no',
                                        label: translation.NO,
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name="branding">
                            <Checkbox
                                checked={addDataSource.branding}
                                onChange={(event) => changeAddDataSource('branding', event.target.checked)}>
                                {translation.REMOVE}"Powered by Mr Smith"
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={adding}>
                                {translation.ADD_LEVEL}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowAdd(false)}>
                                {translation.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={translation.EDIT_LEVEL}
                    closable={false}
                    open={showUpdate}
                    onCancel={() => setShowUpdate(false)}
                    footer={null}>
                    <Form form={updateForm} layout="vertical" onFinish={handleUpdateLevel}>
                        <Form.Item
                            label={translation.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.NAME} disabled autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.BOTLIMIT}
                            name="numberOfBots"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.BOTLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={2} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.QUERYLIMIT}
                            name="numberOfQueries"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.QUERYLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={2500} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.CHARACTERLIMIT}
                            name="numberOfCharacters"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.CHARACTERLIMIT,
                                },
                            ]}>
                            <InputNumber className="width-100" placeholder={500000} autoComplete="off" />
                        </Form.Item>
                        <Form.Item label={translation.DATASOURCE}>
                            <Checkbox
                                checked={updateDataSource.enableFiles}
                                onChange={(event) => changeUpdateDataSource('enableFiles', event.target.checked)}>
                                {translation.FILES}
                            </Checkbox>
                            <Checkbox
                                checked={updateDataSource.enableText}
                                onChange={(event) => changeUpdateDataSource('enableText', event.target.checked)}>
                                {translation.TEXT}
                            </Checkbox>
                            <Checkbox
                                checked={updateDataSource.enableWebsite}
                                onChange={(event) => changeUpdateDataSource('enableWebsite', event.target.checked)}>
                                {translation.WEBSITE}
                            </Checkbox>
                            <Checkbox
                                checked={updateDataSource.enableVideos}
                                onChange={(event) => changeUpdateDataSource('enableVideos', event.target.checked)}>
                                {translation.VIDEOS}
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="GPT-4"
                            name="gpt4"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the GPT-4 option.',
                                },
                            ]}>
                            <Select
                                className="width-100"
                                options={[
                                    {
                                        value: 'yes',
                                        label: translation.YES,
                                    },
                                    {
                                        value: 'no',
                                        label: translation.NO,
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name="branding">
                            <Checkbox
                                checked={updateDataSource.branding}
                                onChange={(event) => changeUpdateDataSource('branding', event.target.checked)}>
                                Remove "Powered by Mr Smith"
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={updating}>
                                {translation.EDIT_LEVEL}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowUpdate(false)}>
                                {translation.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    );
}
