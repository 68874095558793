import { useState, useEffect, useRef } from 'react';
import {
    Input,
    Button,
    Slider,
    Select,
    InputNumber,
    notification,
    Row,
    Col,
    ColorPicker,
    Checkbox,
    Divider,
} from 'antd';
import { SyncOutlined, SendOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import {
    getDefaultSetting,
    updateBotSettings,
    getDefaultInterface,
    updateDefaultInterface,
    getBasePrompt,
    getContext,
} from '../../services/adminServices';
import { pictureuploads } from '../../services/chatbotServices';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function DefaultSettings(props) {
    const translation = useTranslation().admin.defaultsettings;
    const [baseprompt, setBaseprompt] = useState('');
    const [baseprompts, setBaseprompts] = useState([]);
    const [selectedBasePrompt, setSelectedBasePrompt] = useState('');
    const [temperature, setTemperature] = useState(0.7);
    const [visibility, setVisibility] = useState('');
    const [domains, setDomains] = useState('');
    const [limit, setLimit] = useState(20);
    const [limittime, setLimittime] = useState(240);
    const [limitmsg, setLimitmsg] = useState('');
    const [addContext, setAddContext] = useState('');
    const [context, setContext] = useState('');
    const [contexts, setContexts] = useState([]);
    const [selectedContext, setSelectedContext] = useState('');
    const [rememberconv, setRememberconv] = useState('');
    const [numberconv, setNumberconv] = useState(20);
    const [addlogs, setAddlogs] = useState('');
    const [addsource, setAddsource] = useState('');
    const [summarize, setSummarize] = useState('');
    const [model, setModel] = useState('');
    const [maxTokens, setMaxTokens] = useState(1000);
    const [topP, setTopP] = useState(0.01);
    const [bestOf, setBestOf] = useState(1);
    const [frequency, setFrequency] = useState(0.01);
    const [presence, setPresence] = useState(0.01);
    const [language, setLanguage] = useState('french');
    const [tone, setTone] = useState('friendly');
    const [profession, setProfession] = useState('none');

    const [initialMsg, setInitialMsg] = useState('');
    const [suggestMsg, setSuggestMsg] = useState('');
    const [theme, setTheme] = useState('');
    const [pictureFile, setPictureFile] = useState(null);
    const [picture, setPicture] = useState('');
    const [removePicture, setRemovePicture] = useState(false);
    const [msgColor, setMsgColor] = useState('rgb(33, 219, 205)');
    const [iconFile, setIconFile] = useState(null);
    const [icon, setIcon] = useState('');
    const [removeIcon, setRemoveIcon] = useState(false);
    const [buttonColor, setButtonColor] = useState('rgb(33, 219, 205)');
    const [buttonAlign, setButtonAlign] = useState('');
    const [formatRgb, setFormatRgb] = useState('rgb');

    const chatContentRef = useRef(null);
    const suggestMsgRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(60);

    const [isSettingSaving, setIsSettingSaving] = useState(false);
    const [isInterfaceSaving, setIsInterfaceSaving] = useState(false);

    const [loading, setLoading] = useState(false);

    const fetchSetting = async () => {
        try {
            setLoading(true);
            await getDefaultSetting().then((res) => {
                if (res.data) {
                    const bot = res.data;

                    if (bot.baseprompt) {
                        setBaseprompt(bot.baseprompt);
                    }
                    if (bot.temperature) {
                        setTemperature(bot.temperature);
                    }
                    if (bot.visibility) {
                        setVisibility(bot.visibility);
                    }
                    if (bot.domains) {
                        setDomains(bot.domains);
                    }
                    if (bot.limit) {
                        setLimit(bot.limit);
                    }
                    if (bot.limittime) {
                        setLimittime(bot.limittime);
                    }
                    if (bot.limitmsg) {
                        setLimitmsg(bot.limitmsg);
                    }
                    if (bot.addContext) {
                        setAddContext(bot.addContext);
                    }
                    if (bot.rememberconv) {
                        setRememberconv(bot.rememberconv);
                    }
                    if (bot.numberconv) {
                        setNumberconv(bot.numberconv);
                    }
                    if (bot.addlogs) {
                        setAddlogs(bot.addlogs);
                    }
                    if (bot.addsource) {
                        setAddsource(bot.addsource);
                    }
                    if (bot.summarize) {
                        setSummarize(bot.summarize);
                    }
                    if (bot.model) {
                        setModel(bot.model);
                    }
                    if (bot.maxTokens) {
                        setMaxTokens(bot.maxTokens);
                    }
                    if (bot.topP) {
                        setTopP(bot.topP);
                    }
                    if (bot.bestOf) {
                        setBestOf(bot.bestOf);
                    }
                    if (bot.frequency) {
                        setFrequency(bot.frequency);
                    }
                    if (bot.presence) {
                        setPresence(bot.presence);
                    }
                    if (bot.language) {
                        setLanguage(bot.language);
                    }
                    if (bot.tone) {
                        setTone(bot.tone);
                    }
                    if (bot.profession) {
                        setProfession(bot.profession);
                    }
                }
            });
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchInterface = async () => {
        try {
            setLoading(true);
            await getDefaultInterface().then((res) => {
                if (res.data) {
                    const bot = res.data[0];
                    if (bot.initialMsg && bot.initialMsg.length) {
                        setInitialMsg(bot.initialMsg);
                    }
                    if (bot.suggestMsg && bot.suggestMsg.length) {
                        setSuggestMsg(bot.suggestMsg);
                    }
                    if (bot.theme && bot.theme.length) {
                        setTheme(bot.theme);
                    }
                    if (bot.picture && bot.picture.length) {
                        setPicture(bot.picture);
                    }
                    if (bot.removePicture) {
                        setRemovePicture(bot.removePicture);
                    }
                    if (bot.msgColor && bot.msgColor.length) {
                        setMsgColor(bot.msgColor);
                    }
                    if (bot.icon && bot.icon.length) {
                        setIcon(bot.icon);
                    }
                    if (bot.removeIcon) {
                        setRemoveIcon(bot.removeIcon);
                    }
                    if (bot.buttonColor && bot.buttonColor.length) {
                        setButtonColor(bot.buttonColor);
                    }
                    if (bot.buttonAlign && bot.buttonAlign.length) {
                        setButtonAlign(bot.buttonAlign);
                    }
                }
            });
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchBasePrompt = async () => {
        try {
            setLoading(true);
            await getBasePrompt().then((res) => {
                setBaseprompts(res.data.map((val, index) => ({ key: index, value: val })));
            });
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchContext = async () => {
        try {
            setLoading(true);
            await getContext().then((res) => {
                setContexts(res.data.map((val, index) => ({ key: index, value: val })));
            });
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchSetting();
        fetchInterface();
        fetchBasePrompt();
        fetchContext();
    }, []);

    useEffect(() => {
        const suggestMsgHeight = suggestMsgRef.current ? suggestMsgRef.current.offsetHeight : 0;
        setMarginBottom(60 + suggestMsgHeight);
    }, [suggestMsg]);

    const handleBaseprompt = (e) => {
        setBaseprompt(e.target.value);
    };

    const handleContext = (e) => {
        setContext(e.target.value);
    };

    const handleTemperature = (e) => {
        setTemperature(e);
    };

    const handleVisibility = (e) => {
        setVisibility(e);
    };

    const handleDomains = (e) => {
        setDomains(e.target.value);
    };

    const handleLimit = (e) => {
        setLimit(e);
    };

    const handleLimittime = (e) => {
        setLimittime(e);
    };

    const handleLimitmsg = (e) => {
        setLimitmsg(e.target.value);
    };

    const handleAddContext = (e) => {
        setAddContext(e);
        if (e === 'no') setContext('');
    };

    const handleRememberconv = (e) => {
        setRememberconv(e);
    };

    const handleNumberconv = (e) => {
        setNumberconv(e);
    };

    const handleAddlogs = (e) => {
        setAddlogs(e);
    };

    const handleAddsource = (e) => {
        setAddsource(e);
    };

    const handleSummarize = (e) => {
        setSummarize(e);
    };

    const handleModel = (e) => {
        setModel(e);
    };

    const handleMaxTokens = (e) => {
        setMaxTokens(e);
    };

    const handleTopP = (e) => {
        setTopP(e);
    };

    const handleBestOf = (e) => {
        setBestOf(e);
    };

    const handleFrequency = (e) => {
        setFrequency(e);
    };

    const handlePresence = (e) => {
        setPresence(e);
    };

    const handleLanguage = (e) => {
        setLanguage(e);
    };

    const handleTone = (e) => {
        setTone(e);
    };

    const handleProfession = (e) => {
        setProfession(e);
    };

    const resetBaseprompt = () => {
        const baseprompt = translation.settings.RESET_BASE_PROMPT;
        setBaseprompt(baseprompt);
    };

    const resetRateLimit = () => {
        setLimit(20);
        setLimittime(240);
        setLimitmsg(translation.settings.RESET_LIMIT_MSG);
    };

    const handleSelectBasePrompt = (value) => {
        setSelectedBasePrompt(value);
        setBaseprompt(value);
    };

    const handleSelectContext = (value) => {
        setSelectedContext(value);
        setContext(value);
    };

    const handleSettingSave = async (event) => {
        event.preventDefault();
        try {
            setIsSettingSaving(true);
            await updateBotSettings(
                baseprompt,
                temperature,
                visibility,
                domains,
                limit,
                limittime,
                limitmsg,
                addContext,
                context,
                rememberconv,
                numberconv,
                addlogs,
                addsource,
                summarize,
                model,
                maxTokens,
                topP,
                bestOf,
                frequency,
                presence,
                language,
                tone,
                profession,
            );
            notification.success({
                message: translation.settings.notification.SUCCESS,
                placement: 'topRight',
            });
            setIsSettingSaving(false);
        } catch (err) {
            notification.error({
                message: translation.settings.notification.ERROR,
                placement: 'topRight',
            });
            setIsSettingSaving(false);
        }
    };

    const handleInitialMsg = (e) => {
        setInitialMsg(e.target.value);
    };

    const resetInitialMsg = () => {
        setInitialMsg(translation.settings.RESET_INITIAL_MSG);
    };

    const handleSuggestMsg = (e) => {
        setSuggestMsg(e.target.value);
    };

    const handleChangeTheme = (e) => {
        setTheme(e);
    };

    const handleProfileClick = () => {
        document.getElementById('profile').click();
    };

    const handleChangeProfile = (event) => {
        const file = event.target.files[0];
        setPictureFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            setPicture(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemovePicture = (e) => {
        setRemovePicture(e.target.checked);
        if (e.target.checked) {
            setPicture('');
            setPictureFile(null);
        }
    };

    const handleMsgColor = (e) => {
        setMsgColor(e.toRgbString());
    };

    const resetMsgColor = () => {
        setMsgColor('rgb(33, 219, 205)');
    };

    const handleIconClick = () => {
        document.getElementById('icon').click();
    };

    const handleChangeIcon = (event) => {
        const file = event.target.files[0];
        setIconFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            setIcon(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = (e) => {
        setRemoveIcon(e.target.checked);
        if (e.target.checked) {
            setIcon('');
            setIconFile(null);
        }
    };

    const handleButtonColor = (e) => {
        setButtonColor(e.toRgbString());
    };

    const resetButtonColor = () => {
        setButtonColor('rgb(33, 219, 205)');
    };

    const handleButtonAlign = (e) => {
        setButtonAlign(e);
    };

    const getLuminance = (color) => {
        const rgb = color.match(/\d+/g);

        const r = rgb[0];
        const g = rgb[1];
        const b = rgb[2];

        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luminance;
    };

    const handleInterfaceSave = async (event) => {
        event.preventDefault();
        try {
            setIsInterfaceSaving(true);
            let pictureImg = '';
            let iconImg = '';
            if (pictureFile) {
                const formData = new FormData();
                formData.append('image', pictureFile);
                pictureImg = await pictureuploads(formData);
            } else {
                pictureImg = picture;
            }

            if (iconFile) {
                const formData = new FormData();
                formData.append('image', iconFile);
                iconImg = await pictureuploads(formData);
            } else {
                iconImg = icon;
            }

            await updateDefaultInterface(
                initialMsg,
                suggestMsg,
                theme,
                pictureImg,
                removePicture,
                msgColor,
                iconImg,
                removeIcon,
                buttonColor,
                buttonAlign,
            );
            notification.success({
                message: translation.inferface.notification.SUCCESS,
                placement: 'topRight',
            });

            setIsInterfaceSaving(false);
        } catch (err) {
            notification.error({
                message: translation.inferface.notification.ERROR,
                placement: 'topRight',
            });
            setIsInterfaceSaving(false);
        }
    };

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12 default-setting-padding">
                <div className="text-left margin-32">
                    <div style={{ fontSize: 32, fontWeight: 700 }}>{translation.TITLE}</div>
                    <div id="botSettings">
                        <div className="settings-title">{translation.settings.TITLE}</div>
                        <br />
                        <Row className="settings-subtitle" gutter={[16, 16]}>
                            <Col>
                                <div className="settings-subtitle">{translation.settings.language.TITLE}</div>
                                <Select
                                    value={language}
                                    onChange={handleLanguage}
                                    style={{ width: 250 }}
                                    options={[
                                        {
                                            value: 'french',
                                            label: 'Français',
                                        },
                                        {
                                            value: 'english',
                                            label: 'English',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col>
                                <div className="settings-subtitle">{translation.settings.tone.TITLE}</div>
                                <Select
                                    value={tone}
                                    onChange={handleTone}
                                    style={{ width: 250 }}
                                    options={[
                                        {
                                            value: 'friendly',
                                            label: translation.settings.tone.label.FRIENDLY,
                                        },
                                        {
                                            value: 'professional',
                                            label: translation.settings.tone.label.PROFESSIONAL,
                                        },
                                        {
                                            value: 'sarcastic',
                                            label: translation.settings.tone.label.SARCASTIC,
                                        },
                                        {
                                            value: 'humorous',
                                            label: translation.settings.tone.label.HUMOROUS,
                                        },
                                        {
                                            value: 'cheerful',
                                            label: translation.settings.tone.label.CHEERFUL,
                                        },
                                        {
                                            value: 'anecdotal',
                                            label: translation.settings.tone.label.ANECDOTAL,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col>
                                <div className="settings-subtitle">{translation.settings.profession.TITLE}</div>
                                <Select
                                    value={profession}
                                    onChange={handleProfession}
                                    style={{ width: 250 }}
                                    options={[
                                        {
                                            value: 'none',
                                            label: translation.settings.profession.label.NONE,
                                        },
                                        {
                                            value: 'accountant',
                                            label: translation.settings.profession.label.ACCOUNTANT,
                                        },
                                        {
                                            value: 'advertising specialist',
                                            label: translation.settings.profession.label.ADVERTISING_SPECIALIST,
                                        },
                                        {
                                            value: 'architect',
                                            label: translation.settings.profession.label.ARCHITECT,
                                        },
                                        {
                                            value: 'artist',
                                            label: translation.settings.profession.label.ARTIST,
                                        },
                                        {
                                            value: 'blogger',
                                            label: translation.settings.profession.label.BLOGGER,
                                        },
                                        {
                                            value: 'business analyst',
                                            label: translation.settings.profession.label.BUSINESS_ANALYST,
                                        },
                                        {
                                            value: 'business owner',
                                            label: translation.settings.profession.label.BUSINESS_OWNER,
                                        },
                                        {
                                            value: 'car expert',
                                            label: translation.settings.profession.label.CAR_EXPERT,
                                        },
                                        {
                                            value: 'consultant',
                                            label: translation.settings.profession.label.CONSULTANT,
                                        },
                                        {
                                            value: 'counselor',
                                            label: translation.settings.profession.label.COUNSELOR,
                                        },
                                        {
                                            value: 'cryptocurrency trader',
                                            label: translation.settings.profession.label.CRYPTOCURRENCY_TRADER,
                                        },
                                        {
                                            value: 'cryptocurrency expert',
                                            label: translation.settings.profession.label.CRYPTOCURRENCY_EXPERT,
                                        },
                                        {
                                            value: 'customer support',
                                            label: translation.settings.profession.label.CUSTOMER_SUPPORT,
                                        },
                                        {
                                            value: 'designer',
                                            label: translation.settings.profession.label.DESIGNER,
                                        },
                                        {
                                            value: 'digital marketing agency',
                                            label: translation.settings.profession.label.DIGITAL_MARKETING_AGENCY,
                                        },
                                        {
                                            value: 'editor',
                                            label: translation.settings.profession.label.EDITOR,
                                        },
                                        {
                                            value: 'event planner',
                                            label: translation.settings.profession.label.EVENT_PLANNER,
                                        },
                                        {
                                            value: 'freelancer',
                                            label: translation.settings.profession.label.FREELANCER,
                                        },
                                        {
                                            value: 'insurance agent',
                                            label: translation.settings.profession.label.INSURANCE_AGENT,
                                        },
                                        {
                                            value: 'insurance broker',
                                            label: translation.settings.profession.label.INSURANCE_BROKER,
                                        },
                                        {
                                            value: 'interior designer',
                                            label: translation.settings.profession.label.INTERIOR_DESIGNER,
                                        },
                                        {
                                            value: 'journalist',
                                            label: translation.settings.profession.label.JOURNALIST,
                                        },
                                        {
                                            value: 'marketing agency',
                                            label: translation.settings.profession.label.MARKETING_AGENCY,
                                        },
                                        {
                                            value: 'marketing expert',
                                            label: translation.settings.profession.label.MARKETING_EXPERT,
                                        },
                                        {
                                            value: 'marketing specialist',
                                            label: translation.settings.profession.label.MARKETING_SPECIALIST,
                                        },
                                        {
                                            value: 'photographer',
                                            label: translation.settings.profession.label.PHOTOGRAPHER,
                                        },
                                        {
                                            value: 'programmer',
                                            label: translation.settings.profession.label.PROGRAMMER,
                                        },
                                        {
                                            value: 'public relations agency',
                                            label: translation.settings.profession.label.PUBLIC_RELATIONS_AGENCY,
                                        },
                                        {
                                            value: 'publisher',
                                            label: translation.settings.profession.label.PUBLISHER,
                                        },
                                        {
                                            value: 'real estate agent',
                                            label: translation.settings.profession.label.REAL_ESTATE_AGENT,
                                        },
                                        {
                                            value: 'recruiter',
                                            label: translation.settings.profession.label.RECRUITER,
                                        },
                                        {
                                            value: 'reporter',
                                            label: translation.settings.profession.label.REPORTER,
                                        },
                                        {
                                            value: 'salesperson',
                                            label: translation.settings.profession.label.SALES_PERSON,
                                        },
                                        {
                                            value: 'sales representative',
                                            label: translation.settings.profession.label.SALES_REPRESENTATIVE,
                                        },
                                        {
                                            value: 'seo agency',
                                            label: translation.settings.profession.label.SEO_AGENCY,
                                        },
                                        {
                                            value: 'seo expert',
                                            label: translation.settings.profession.label.SEO_EXPERT,
                                        },
                                        {
                                            value: 'social media agency',
                                            label: translation.settings.profession.label.SOCIAL_MEDIA_AGENCY,
                                        },
                                        {
                                            value: 'student',
                                            label: translation.settings.profession.label.STUDENT,
                                        },
                                        {
                                            value: 'teacher',
                                            label: translation.settings.profession.label.TEACHER,
                                        },
                                        {
                                            value: 'technical support',
                                            label: translation.settings.profession.label.TECHNICAL_SUPPORT,
                                        },
                                        {
                                            value: 'trainer',
                                            label: translation.settings.profession.label.TRAINER,
                                        },
                                        {
                                            value: 'travel agency',
                                            label: translation.settings.profession.label.TRAVEL_AGENCY,
                                        },
                                        {
                                            value: 'videographer',
                                            label: translation.settings.profession.label.VIDEOGRAPHER,
                                        },
                                        {
                                            value: 'web design agency',
                                            label: translation.settings.profession.label.WEB_DESIGN_AGENCY,
                                        },
                                        {
                                            value: 'web design expert',
                                            label: translation.settings.profession.label.WEB_DESIGN_EXPERT,
                                        },
                                        {
                                            value: 'web development agency',
                                            label: translation.settings.profession.label.WEB_DEVELOPMENT_AGENCY,
                                        },
                                        {
                                            value: 'web development expert',
                                            label: translation.settings.profession.label.WEB_DEVELOPMENT_EXPERT,
                                        },
                                        {
                                            value: 'web designer',
                                            label: translation.settings.profession.label.WEB_DESIGNER,
                                        },
                                        {
                                            value: 'web developer',
                                            label: translation.settings.profession.label.WEB_DEVELOPER,
                                        },
                                        {
                                            value: 'writer',
                                            label: translation.settings.profession.label.WRITER,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <br />
                        <div className="display-flex">
                            <div className="settings-subtitle">{translation.settings.BASE_PROMPT}</div>
                            <Button size="small" onClick={resetBaseprompt}>
                                {translation.settings.RESET}
                            </Button>
                        </div>
                        <TextArea rows={5} value={baseprompt} onChange={handleBaseprompt} />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.SELECT_BASE_PROMPT}</div>
                        <Select
                            className="width-100"
                            options={baseprompts}
                            value={selectedBasePrompt}
                            onChange={handleSelectBasePrompt}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.TEMPERATURE}</div>
                        <Slider
                            min={0}
                            max={1}
                            step={0.1}
                            marks={{
                                0: translation.settings.RESERVED,
                                1: translation.settings.CREATIVE,
                            }}
                            value={temperature}
                            onChange={handleTemperature}
                        />
                        <br />
                        <div className="settings-subtitle">{translation.settings.visibility.TITLE}</div>
                        <Select
                            className="width-100"
                            value={visibility}
                            options={[
                                {
                                    value: 'private',
                                    label: translation.settings.visibility.PRIVATE,
                                },
                                {
                                    value: 'private-embed',
                                    label: translation.settings.visibility.PRIVATE_EMBED,
                                },
                                {
                                    value: 'public',
                                    label: translation.settings.visibility.PUBLIC,
                                },
                            ]}
                            onChange={handleVisibility}
                        />
                        <div className="settings-content">
                            <p>{translation.settings.visibility.PRIVATE_DES}</p>
                            <p>{translation.settings.visibility.PRIVATE_EMBED_DES}</p>
                            <p>{translation.settings.visibility.PUBLIC_DES}</p>
                            <p>{translation.settings.visibility.LAST_DES}</p>
                        </div>
                        <br />
                        <div className="settings-subtitle">{translation.settings.domains.TITLE}</div>
                        <TextArea rows={2} placeholder="example.com" value={domains} onChange={handleDomains} />
                        <div className="settings-content">
                            <p>{translation.settings.domains.DES_FIRST}</p>
                            <p>{translation.settings.domains.DES_LAST}</p>
                        </div>
                        <br />
                        <div className="display-flex">
                            <div className="settings-subtitle">{translation.settings.limit.TITLE}</div>
                            <Button size="small" onClick={resetRateLimit}>
                                {translation.settings.RESET}
                            </Button>
                        </div>
                        <div className="settings-content">
                            <p>{translation.settings.limit.DESCRIPTION}</p>
                            <div style={{ color: 'black' }}>
                                {translation.settings.limit.DES_FISRT}
                                <InputNumber value={limit} onChange={handleLimit} autoComplete="off" />
                                {translation.settings.limit.DES_MIDDLE}
                                <InputNumber value={limittime} onChange={handleLimittime} autoComplete="off" />
                                {translation.settings.limit.DES_LAST}
                                <br />
                                <br />
                                {translation.settings.limit.LIMIT_MESSAGE}
                                <Input value={limitmsg} onChange={handleLimitmsg} autoComplete="off" />
                            </div>
                        </div>
                        <br />
                        <div className="settings-subtitle">{translation.settings.ADD_CONTEXT}</div>
                        <Select
                            className="width-100"
                            value={addContext}
                            options={[
                                {
                                    value: 'no',
                                    label: translation.settings.NO,
                                },
                                {
                                    value: 'yes',
                                    label: translation.settings.YES,
                                },
                            ]}
                            onChange={handleAddContext}
                        />
                        <br />
                        <br />
                        {addContext === 'yes' ? (
                            <>
                                <div className="display-flex">
                                    <div className="settings-subtitle">{translation.settings.CONTEXT}</div>
                                </div>
                                <TextArea rows={5} value={context} onChange={handleContext} />
                                <br />
                                <br />
                                <div className="settings-subtitle">{translation.settings.SELECT_CONTEXT}</div>
                                <Select
                                    className="width-100"
                                    options={contexts}
                                    value={selectedContext}
                                    onChange={handleSelectContext}
                                />
                                <br />
                                <br />
                            </>
                        ) : null}
                        <div className="settings-subtitle">{translation.settings.REMEMBER_CONVERSATION}</div>
                        <Select
                            className="width-100"
                            value={rememberconv}
                            options={[
                                {
                                    value: 'no',
                                    label: translation.settings.NO,
                                },
                                {
                                    value: 'yes',
                                    label: translation.settings.YES,
                                },
                            ]}
                            onChange={handleRememberconv}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.CONVERSATION_NUMBER}</div>
                        <InputNumber value={numberconv} onChange={handleNumberconv} autoComplete="off" />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.ADD_LOGS}</div>
                        <Select
                            className="width-100"
                            value={addlogs}
                            options={[
                                {
                                    value: 'no',
                                    label: translation.settings.NO,
                                },
                                {
                                    value: 'yes',
                                    label: translation.settings.YES,
                                },
                            ]}
                            onChange={handleAddlogs}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.ADD_SOURCE}</div>
                        <Select
                            className="width-100"
                            value={addsource}
                            options={[
                                {
                                    value: 'no',
                                    label: translation.settings.NO,
                                },
                                {
                                    value: 'yes',
                                    label: translation.settings.YES,
                                },
                            ]}
                            onChange={handleAddsource}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.SUMMARIZE}</div>
                        <Select
                            className="width-100"
                            value={summarize}
                            options={[
                                {
                                    value: 'no',
                                    label: translation.settings.NO,
                                },
                                {
                                    value: 'yes',
                                    label: translation.settings.YES,
                                },
                            ]}
                            onChange={handleSummarize}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.settings.MODEL}</div>
                        <Select
                            className="width-100"
                            value={model}
                            options={[
                                {
                                    value: 'gpt-4o-mini',
                                    label: 'GPT-4o-mini',
                                },
                                {
                                    value: 'gpt-4',
                                    label: 'GPT-4',
                                },
                            ]}
                            onChange={handleModel}
                        />
                        <br />
                        <br />
                        <div className="settings-subtitle">{translation.OTHRE_PARAMETERS}</div>
                        <Row className="settings-subtitle" gutter={[16, 16]}>
                            <Col>
                                <InputNumber
                                    value={maxTokens}
                                    onChange={handleMaxTokens}
                                    addonBefore={translation.settings.MAX_TOKENS}
                                    style={{ width: 250 }}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col>
                                <InputNumber
                                    value={bestOf}
                                    onChange={handleBestOf}
                                    addonBefore={translation.settings.BEST_OF}
                                    style={{ width: 250 }}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row className="settings-subtitle" gutter={[16, 16]}>
                            <Col>
                                <InputNumber
                                    value={topP}
                                    onChange={handleTopP}
                                    addonBefore={translation.settings.TOP_P}
                                    style={{ width: 250 }}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col>
                                <InputNumber
                                    value={frequency}
                                    onChange={handleFrequency}
                                    addonBefore={translation.settings.FREQUENCY_PENALTY}
                                    style={{ width: 250 }}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col>
                                <InputNumber
                                    value={presence}
                                    onChange={handlePresence}
                                    addonBefore={translation.settings.PRESENCE_PENALTY}
                                    style={{ width: 250 }}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <br />
                        <Button
                            size="large"
                            type="primary"
                            className="float-right normal-component"
                            style={{ height: 'auto' }}
                            onClick={handleSettingSave}
                            loading={isSettingSaving}>
                            {isSettingSaving ? translation.settings.SAVE_LOADING : translation.settings.SAVE_CHANGES}
                        </Button>
                    </div>
                    <div id="interfaceSettings">
                        <div className="settings-title">{translation.inferface.TITLE}</div>
                        <p className="settings-content" style={{ color: 'black' }}>
                            {translation.inferface.DESCRIPTION}
                        </p>
                        <br />
                        <Row gutter={32}>
                            <Col className="interface-col">
                                <div className="display-flex">
                                    <div className="settings-subtitle">{translation.inferface.INITIAL_MESSAGES}</div>
                                    <Button size="small" onClick={resetInitialMsg}>
                                        {translation.inferface.RESET}
                                    </Button>
                                </div>
                                <TextArea rows={2} value={initialMsg} onChange={handleInitialMsg} />
                                <div className="settings-content">
                                    <p>{translation.inferface.INITIAL_DES}</p>
                                </div>
                                <div className="settings-subtitle">{translation.inferface.SUGGESTED_MESSAGES}</div>
                                <TextArea
                                    rows={2}
                                    placeholder={translation.inferface.SUGGEST_PLACEHOLDER}
                                    value={suggestMsg}
                                    onChange={handleSuggestMsg}
                                />
                                <div className="settings-content">
                                    <p>{translation.inferface.SUGGEST_DES}</p>
                                </div>
                                <div className="settings-subtitle">{translation.inferface.theme.TITLE}</div>
                                <Select
                                    className="width-100"
                                    value={theme}
                                    options={[
                                        {
                                            value: 'light',
                                            label: translation.inferface.theme.LIGHT,
                                        },
                                        {
                                            value: 'dark',
                                            label: translation.inferface.theme.DARK,
                                        },
                                    ]}
                                    onChange={handleChangeTheme}
                                />
                                <br />
                                <br />

                                {removePicture ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="settings-subtitle">{translation.inferface.PICTURE}</div>
                                        <Input
                                            type="file"
                                            id="profile"
                                            accept=".png,.jpg,.bmp,.jpeg,.ico"
                                            multiple
                                            onChange={handleChangeProfile}
                                            style={{ display: 'none' }}
                                            autoComplete="off"
                                        />
                                        <Button icon={<UploadOutlined />} onClick={handleProfileClick}>
                                            {translation.inferface.PICTURE_UPLOAD}
                                        </Button>
                                        <br />
                                    </>
                                )}
                                <br />
                                <div className="settings-subtitle">{translation.inferface.REMOVE_PICTURE}</div>
                                <Checkbox checked={removePicture} onChange={handleRemovePicture} />
                                <br />
                                <br />
                                <div className="display-flex">
                                    <div className="settings-subtitle">{translation.inferface.PICTURE_COLOR}</div>
                                    <Button size="small" onClick={resetMsgColor}>
                                        {translation.inferface.RESET}
                                    </Button>
                                </div>
                                <ColorPicker
                                    value={msgColor}
                                    onChange={handleMsgColor}
                                    format={formatRgb}
                                    onFormatChange={setFormatRgb}
                                />
                                <p className="settings-content" style={{ color: 'black' }}>
                                    {translation.inferface.PICTURE_DES}
                                </p>
                                {removeIcon ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="settings-subtitle">{translation.inferface.ICON}</div>
                                        <Input
                                            type="file"
                                            id="icon"
                                            accept=".png,.jpg,.bmp,.jpeg,.ico"
                                            multiple
                                            onChange={handleChangeIcon}
                                            style={{ display: 'none' }}
                                            autoComplete="off"
                                        />
                                        <Button icon={<UploadOutlined />} onClick={handleIconClick}>
                                            {translation.inferface.ICON_UPLOAD}
                                        </Button>
                                        <br />
                                    </>
                                )}
                                <br />
                                <div className="settings-subtitle">{translation.inferface.REMOVE_ICON}</div>
                                <Checkbox checked={removeIcon} onChange={handleRemoveIcon} />
                                <br />
                                <br />
                                <div className="display-flex">
                                    <div className="settings-subtitle">{translation.inferface.ICON_COLOR}</div>
                                    <Button size="small" onClick={resetButtonColor}>
                                        {translation.inferface.RESET}
                                    </Button>
                                </div>
                                <ColorPicker
                                    value={buttonColor}
                                    onChange={handleButtonColor}
                                    format={formatRgb}
                                    onFormatChange={setFormatRgb}
                                />
                                <br />
                                <br />
                                <div className="settings-subtitle">{translation.inferface.ICON_ALIGN}</div>
                                <Select
                                    className="width-100"
                                    value={buttonAlign}
                                    options={[
                                        {
                                            value: 'right',
                                            label: translation.inferface.ALIGN_RIGHT,
                                        },
                                        {
                                            value: 'left',
                                            label: translation.inferface.ALIGN_LEFT,
                                        },
                                    ]}
                                    onChange={handleButtonAlign}
                                />
                            </Col>
                            <Col className="interface-col">
                                <div
                                    className="history"
                                    style={{
                                        background: `${theme === 'light' ? 'white' : '#2e2d2c'}`,
                                    }}>
                                    <div className="display-flex margin-12 align-center">
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    alignItems: 'center',
                                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                                }}>
                                                {picture ? (
                                                    <img
                                                        src={
                                                            pictureFile
                                                                ? picture
                                                                : '/' + picture
                                                        }
                                                        alt="profile"
                                                        className="profile-image"
                                                        crossOrigin="anonymous"
                                                    />
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 283.46 283.46"
                                                        className="profile-image">
                                                        <g id="Calque_2" data-name="Calque 2">
                                                            <g id="Calque_1-2" data-name="Calque 1">
                                                                <circle
                                                                    cx="141.73"
                                                                    cy="141.73"
                                                                    r="141.73"
                                                                    style={{ fill: '#21dbcd' }}
                                                                />
                                                                <circle
                                                                    cx="108.6"
                                                                    cy="142.2"
                                                                    r="22.09"
                                                                    style={{
                                                                        fill: '#fff',
                                                                    }}
                                                                />
                                                                <circle
                                                                    cx="174.88"
                                                                    cy="142.2"
                                                                    r="22.09"
                                                                    style={{
                                                                        fill: '#fff',
                                                                    }}
                                                                />
                                                                <path
                                                                    style={{
                                                                        fill: '#fff',
                                                                    }}
                                                                    d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                )}
                                                &nbsp;{}
                                            </div>
                                        </div>
                                        <div>
                                            <Button
                                                icon={<SyncOutlined style={{ fontSize: 20 }} />}
                                                style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                                }}
                                            />
                                            <Button
                                                icon={<CloseOutlined style={{ fontSize: 20 }} />}
                                                style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Divider
                                        style={{
                                            background: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                            margin: 0,
                                        }}
                                    />
                                    <div
                                        className="chat-content"
                                        ref={chatContentRef}
                                        style={{ marginBottom: marginBottom }}>
                                        {initialMsg.split('\n').map((msg, index) => (
                                            <div
                                                key={index}
                                                className="bot-text"
                                                style={{
                                                    display: `${msg.length ? 'flex' : 'none'}`,
                                                    padding: 12,
                                                }}>
                                                {msg}
                                            </div>
                                        ))}

                                        <div
                                            className="customer-text"
                                            style={{
                                                backgroundColor: `${msgColor}`,
                                                color: `${getLuminance(msgColor) > 180 ? '#2e2d2c' : 'white'}`,
                                                padding: 12,
                                            }}>
                                            {translation.inferface.HI}
                                        </div>
                                    </div>
                                    <div className="suggestmsg-container" ref={suggestMsgRef}>
                                        {suggestMsg.split('\n').map((msg, index) => (
                                            <Button
                                                key={index}
                                                className="suggestmsg"
                                                style={{ display: `${msg.length ? '' : 'none'}` }}>
                                                {msg}
                                            </Button>
                                        ))}
                                    </div>
                                    <div className="question-container">
                                        <Input
                                            placeholder={translation.inferface.INPUT_PLACEHOLDER}
                                            size="large"
                                            style={{
                                                backgroundColor: 'transparent',
                                            }}
                                            className={`${theme === 'light' ? 'input-black' : 'input-white'}`}
                                            suffix={
                                                <Button
                                                    icon={<SendOutlined style={{ fontSize: 20 }} />}
                                                    style={{
                                                        border: 'none',
                                                        background: 'transparent',
                                                        color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                                    }}
                                                />
                                            }
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <br />
                                {icon ? (
                                    <img
                                        src={iconFile ? icon : '/' + icon}
                                        alt="icon"
                                        crossOrigin="anonymous"
                                        className={`button-image ${
                                            buttonAlign === 'right' ? 'float-right' : 'float-left'
                                        }`}
                                    />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 283.46 283.46"
                                        className={`button-image ${
                                            buttonAlign === 'right' ? 'float-right' : 'float-left'
                                        }`}>
                                        <g id="Calque_2" data-name="Calque 2">
                                            <g id="Calque_1-2" data-name="Calque 1">
                                                <circle
                                                    cx="141.73"
                                                    cy="141.73"
                                                    r="141.73"
                                                    style={{ fill: `${buttonColor}` }}
                                                />
                                                <circle
                                                    cx="108.6"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: `${
                                                            getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'
                                                        }`,
                                                    }}
                                                />
                                                <circle
                                                    cx="174.88"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: `${
                                                            getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'
                                                        }`,
                                                    }}
                                                />
                                                <path
                                                    style={{
                                                        fill: `${
                                                            getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'
                                                        }`,
                                                    }}
                                                    d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                )}
                            </Col>
                        </Row>
                        <Button
                            size="large"
                            type="primary"
                            className="float-right normal-component"
                            style={{ height: 'auto' }}
                            onClick={handleInterfaceSave}
                            loading={isInterfaceSaving}>
                            {isInterfaceSaving
                                ? translation.inferface.SAVE_LOADING
                                : translation.inferface.SAVE_CHANGES}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
