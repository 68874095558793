import { useEffect, useState } from 'react';
import { Table, Button, Switch, notification, Popconfirm, Input, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getBulkBots, updateBulkBot, deleteBulkBot, deleteBulkBots } from '../../services/adminServices';
import { setCalendlyUrl, getCalendlyUrl } from '../../services/authServices';
import useTranslation from '../../locales/translations';

export default function BotsInBulk() {
    const translation = useTranslation().admin.botsinbulk;
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [calendly, setCalendly] = useState('');
    const [loading, setLoading] = useState(false);

    const openURLInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const fetchList = async () => {
        try {
            setLoading(true);
            const res = await getBulkBots();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            setData(temp);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUserInfo = async () => {
        try {
            setLoading(true);
            await getCalendlyUrl().then((res) => {
                setCalendly(res.data);
            });
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUserInfo();
        fetchList();
    }, []);

    const handleSwitchChange = async (checked, record) => {
        try {
            await updateBulkBot(record._id, checked);
            fetchList();
        } catch (error) {
            console.error(error);
            notification.error({
                message: translation.notification.UPDATE_ERROR_MSG,
                description: translation.notification.UPDATE_ERROR_DES,
            });
        }
    };

    const handleDelete = async (record) => {
        try {
            setDeleting(true);
            await deleteBulkBot(record._id);
            setData(data.filter((item) => item._id !== record._id));
            notification.success({
                message: translation.notification.DELETE_SUCCESS_MSG,
                description: translation.notification.DELETE_SUCCESS_DES,
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: translation.notification.DELETE_ERROR_MSG,
                description: translation.notification.DELETE_ERROR_DES,
            });
        } finally {
            setDeleting(false);
        }
    };

    const handleDeleteAll = async () => {
        try {
            setDeleting(true);
            await deleteBulkBots(selectedRowKeys);
            setData(data.filter((item) => !selectedRowKeys.includes(item._id)));
            notification.success({
                message: translation.notification.DELETE_SUCCESS_MSG,
                description: translation.notification.DELETE_SUCCESS_DES,
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: translation.notification.DELETE_ERROR_MSG,
                description: translation.notification.DELETE_ERROR_DES,
            });
        } finally {
            setDeleting(false);
            setSelectedRowKeys([]);
        }
    };

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'ID',
            key: 'ID',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.BOTID,
            dataIndex: 'BOTID',
            key: 'BOTID',
            width: 220,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => openURLInNewTab(`${window.location.origin}/assistant/${record._id}`)}
                    disabled={deleting}>
                    {record._id}
                </Button>
            ),
        },
        {
            title: translation.WEBSITE,
            dataIndex: 'website',
            key: 'website',
        },
        {
            title: translation.FREE_ACCESS,
            dataIndex: 'freeaccess',
            key: 'freeaccess',
            width: 150,
            render: (text, record) => (
                <Switch checked={record.freeaccess} onChange={(checked) => handleSwitchChange(checked, record)} />
            ),
        },
        {
            title: translation.ACTION,
            dataIndex: 'ACTION',
            key: 'ACTION',
            width: 70,
            render: (text, record) => (
                <Popconfirm
                    title={translation.DELETE_TITLE}
                    description={translation.DELETE_DESCRIPTION}
                    onConfirm={() => handleDelete(record)}
                    okText={translation.DELETE_OKTEXT}
                    cancelText={translation.DELETE_CANCELTEXT}
                    okButtonProps={{
                        loading: deleting,
                    }}>
                    <Button danger icon={<DeleteOutlined />} type="text" disabled={deleting} />
                </Popconfirm>
            ),
        },
    ];

    const handleCalendly = (e) => {
        setCalendly(e.target.value);
    };

    const handleSetCalendly = async () => {
        try {
            await setCalendlyUrl(calendly).then(() => {
                notification.success({
                    message: translation.notification.CALENDLY_SUCCESS_MSG,
                });
            });
        } catch (err) {
            notification.error({
                message: translation.notification.CALENDLY_ERROR_MSG,
            });
        }
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <span>{translation.TITLE}</span>
                        <Button
                            danger
                            type="primary"
                            onClick={handleDeleteAll}
                            disabled={!hasSelected}
                            loading={hasSelected && deleting}>
                            {translation.DELETE_ALL}
                        </Button>
                    </div>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'normal',
                            wordBreak: 'break-all',
                        }}>
                        {translation.DESCRIPTION}
                        https://app.mr-smith.ai/assistant/botId)
                    </p>
                </div>
                <Space.Compact
                    style={{
                        width: '100%',
                        marginBottom: 20,
                    }}>
                    <Input
                        addonBefore={translation.CALENDLY}
                        value={calendly}
                        onChange={handleCalendly}
                        autoComplete="off"
                    />
                    <Button type="primary" onClick={handleSetCalendly}>
                        {translation.SET}
                    </Button>
                </Space.Compact>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowSelection={rowSelection}
                    bordered
                    scroll={{
                        x: 700,
                    }}
                />
            </div>
        </>
    );
}
