import { Layout, Button, Card, Form, Input, Checkbox, notification, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { signup } from '../../services/authServices';
import useTranslation from '../../locales/translations';
const { Content } = Layout;

export default function SignUp() {
    const translation = useTranslation().auth;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAndConditionsAgreed: false,
    });

    const [showAlert, setShowAlert] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        const { confirm, termsAndConditionsAgreed, ...rest } = formData;
        try {
            const browserLanguage = navigator.language.split('-')[0];
            rest.language = ['en', 'fr'].includes(browserLanguage) ? browserLanguage : 'fr';
            await signup(rest);
            notification.success({
                message: translation.notification.SIGNUP_SUCCESS,
                placement: 'topRight',
            });
            setShowAlert(true);
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        }
        setIsSaving(false);
    };

    return (
        <>
            <Layout className="layout-default">
                <Content className="">
                    <Card className="card-signin" title={<h5>{translation.REGISTER}</h5>} bordered="false">
                        <Alert
                            message={translation.alert.SIGNUP_MESSAGE}
                            description={translation.alert.SIGNUP_DES}
                            type="success"
                            style={{
                                marginBottom: 12,
                                display: `${showAlert ? 'block' : 'none'}`,
                            }}
                        />
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Form.Item
                                label={translation.NAME}
                                name="name"
                                rules={[{ required: true, message: translation.validation.NAME }]}>
                                <Input
                                    placeholder={translation.NAME}
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                label={translation.EMAIL}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: translation.validation.EMAIL,
                                    },
                                ]}>
                                <Input
                                    placeholder={translation.EMAIL}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                label={translation.PASSWORD}
                                name="password"
                                rules={[{ required: true, message: translation.validation.PASSWORD }]}
                                hasFeedback>
                                <Input.Password
                                    placeholder={translation.PASSWORD}
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                label={translation.CONFIRM_PASSWORD}
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: translation.validation.CONFIRM_PASSWORD,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(translation.notification.PASSWORD_ERROR));
                                        },
                                    }),
                                ]}
                                hasFeedback>
                                <Input.Password
                                    placeholder={translation.PASSWORD}
                                    name="confirmPassword"
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                name="termsAndConditions"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(new Error(translation.validation.AGREE_TERM)),
                                    },
                                ]}>
                                <Checkbox
                                    name="termsAndConditionsAgreed"
                                    checked={formData.termsAndConditionsAgreed}
                                    onChange={handleChange}>
                                    {translation.AGREE_TERM_HEAD}
                                    <a href="#pablo" className="font-bold text-dark">
                                        {translation.AGREE_TERM_BODY}
                                    </a>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button className="width-100" type="primary" htmlType="submit" loading={isSaving}>
                                    {translation.SIGNUP_UP}
                                </Button>
                            </Form.Item>
                        </Form>
                        <p className="text-muted text-center">
                            {translation.SIGN_IN_QUESTION}
                            <Link to="/signin" className="font-bold text-dark">
                                {translation.SIGNIN_LO}
                            </Link>
                        </p>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}
