import { Button, Progress, Card, Badge} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getChatbots } from '../../services/chatbotServices';
import { getUserInfo, getCurrentUser } from '../../services/authServices';
import { getLevel } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function Dashboard() {
    const translation = useTranslation().dashboard;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/createnewbot');
    };
    const [level, setLevel] = useState();
    const [bots, setBots] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const user = getUserInfo();

    const free = (
        <ul className="text-left">
            <li>{translation.plan.free.FIRST}</li>
            <li>{translation.plan.free.SECOND}</li>
            <li>{translation.plan.free.THIRD}</li>
            <li>{translation.plan.free.FOURTH}</li>
            <li>{translation.plan.free.FIFTH}</li>
            <li>{translation.plan.free.SIXTH}</li>
        </ul>
    );

    const basic = (
        <ul className="text-left">
            <li>{translation.plan.basic.FIRST}</li>
            <li>{translation.plan.basic.SECOND}</li>
            <li>{translation.plan.basic.THIRD}</li>
            <li>{translation.plan.basic.FOURTH}</li>
            <li>{translation.plan.basic.FIFTH}</li>
            <li>{translation.plan.basic.SIXTH}</li>
            <li>{translation.plan.basic.SEVENTH}</li>
        </ul>
    );

    const premium = (
        <ul className="text-left">
            <li>{translation.plan.premium.FIRST}</li>
            <li>{translation.plan.premium.SECOND}</li>
            <li>{translation.plan.premium.THIRD}</li>
            <li>{translation.plan.premium.FOURTH}</li>
            <li>{translation.plan.premium.FIFTH}</li>
            <li>{translation.plan.premium.SIXTH}</li>
            <li>{translation.plan.premium.SEVENTH}</li>
            <li>{translation.plan.premium.EIGHTH}</li>
        </ul>
    );

    const expert = (
        <ul className="text-left">
            <li>{translation.plan.expert.FIRST}</li>
            <li>{translation.plan.expert.SECOND}</li>
            <li>{translation.plan.expert.THIRD}</li>
            <li>{translation.plan.expert.FOURTH}</li>
            <li>{translation.plan.expert.FIFTH}</li>
            <li>{translation.plan.expert.SIXTH}</li>
            <li>{translation.plan.expert.SEVENTH}</li>
            <li>{translation.plan.expert.EIGHTH}</li>
            <li>{translation.plan.expert.NINETH}</li>
            <li>{translation.plan.expert.TENTH}</li>
        </ul>
    );

    const fetchBots = async () => {
        try {
            const res = await getChatbots();
            setBots(res.data);
        } catch (err) {
            throw err;
        }
    };

    const fetchLevel = async () => {
        try {
            const res = await getLevel(user.level);
            setLevel(res.data);
        } catch (err) {
            throw err;
        }
    };

    const fetchUser = async () => {
        try {
            const res = await getCurrentUser(user._id);
            setCurrentUser(res.data);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        user && fetchLevel();
        user && fetchBots();
        user && fetchUser();
    }, []);

    return (
        <>
            <div className="dashboard text-center main-layout">
                <div>
                    <h1 className="dashboard-title normal-text">{translation.TITLE}</h1>
                    <span className="dashboard-des normal-text">{translation.DESCRIPTION}</span>
                </div>
                <div style={{ textAlign: 'center', paddingTop: 40 }}>
                    <Button
                        className="normal-component"
                        type="primary"
                        size="large"
                        style={{ fontSize: 16, height: 'auto' }}
                        onClick={handleClick}>
                        {translation.BUILD_ASSISTANT}
                    </Button>
                </div>
                {user && user.role === 'user' ? (
                    <>
                        <div className="limits">
                            <p className="limit-title">{translation.USAGE}</p>
                            <span className="limit-subtitle">{translation.ASSISTANTS}</span>
                            <Progress
                                percent={level && bots.length ? (bots.length / level.numberOfBots) * 100 : 0}
                                format={(percent) => `${bots.length} / ${level && level.numberOfBots} (${percent}%)`}
                            />
                            <span className="limit-subtitle">{translation.MESSAGES}</span>
                            <Progress
                                percent={level && currentUser ? (currentUser.queries / level.numberOfQueries) * 100 : 0}
                                format={(percent) =>
                                    `${currentUser && currentUser.queries} / ${
                                        level && level.numberOfQueries
                                    } (${percent.toFixed(3)}%)`
                                }
                            />
                        </div>
                        <div className="subscriptions">
                            <Card title={<h3>{translation.subscriptions.TITLE}</h3>}>
                                <div className="sub-container">
                                    <div className="sub-detail" style={{ fontWeight: 'bold' }}>
                                        <p>
                                            <Badge
                                                status="default"
                                                text={
                                                    user.level === 'Free'
                                                        ? translation.subscriptions.FREE_PLAN
                                                        : user.level === 'Basic'
                                                        ? translation.subscriptions.BASIC_PLAN
                                                        : user.level === 'Premium'
                                                        ? translation.subscriptions.PREMIUM_PLAN
                                                        : user.level === 'Expert'
                                                        ? translation.subscriptions.EXPERT_PLAN
                                                        : null
                                                }
                                            />
                                        </p>
                                    </div>
                                    <div className="sub-detail">
                                        {user.level === 'Free'
                                            ? free
                                            : user.level === 'Basic'
                                            ? basic
                                            : user.level === 'Premium'
                                            ? premium
                                            : user.level === 'Expert'
                                            ? expert
                                            : null}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
