import { useEffect, useState } from 'react';
import { Row, Col, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { getHistory } from '../../services/chatbotServices';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import TimeAgo from 'timeago-react';
import useTranslation from '../../locales/translations';
const { RangePicker } = DatePicker;

export default function Conversations(props) {
    const translation = useTranslation().chatbot.conversation;
    const botId = props.botId;
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
    const [histories, setHistories] = useState([]);
    const [conversation, setConversation] = useState(<></>);
    const [chatSource, setChatSource] = useState('');

    const handleChange = (e) => {
        setDateRange(e);
        setConversation(
            <>
                {histories.length &&
                    histories[0].messages.map((message, index) => {
                        return (
                            <div key={index} className={message.role === 'user' ? 'customer-text' : 'bot-text'}>
                                <ReactMarkdown
                                    remarkPlugins={[remarkMath, rehypeKatex]}
                                    linkTarget="_blank"
                                    rel="noopener noreferrer">
                                    {message.content}
                                </ReactMarkdown>
                            </div>
                        );
                    })}
            </>,
        );
    };

    const getConversation = (messages) => {
        let index = 0;
        messages.map((message, i) => {
            if (!index) {
                if (message.role === 'user') index = i;
            }
            return null;
        });
        return messages.slice(-(messages.length - index));
    };

    const handleHistoryClick = (index) => {
        const messages = histories[index].messages;
        setChatSource(histories[index].chatSource);
        setConversation(
            <>
                {messages.map((message, index) => {
                    return (
                        <div key={index} className={message.role === 'user' ? 'customer-text' : 'bot-text'}>
                            {message.role !== 'user' && message.score && (
                                <span className="score-span">
                                    {translation.ACCURACY_SCORE}
                                    {(message.score * 100).toFixed(3)} %
                                </span>
                            )}
                            <ReactMarkdown
                                remarkPlugins={[remarkMath, rehypeKatex]}
                                linkTarget="_blank"
                                rel="noopener noreferrer">
                                {message.content}
                            </ReactMarkdown>
                        </div>
                    );
                })}
            </>,
        );
    };

    const fetchHistory = async () => {
        try {
            let startDate = dateRange[0];
            let endDate = dateRange[1];
            startDate = new Date(startDate);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(endDate);
            endDate.setHours(23, 59, 59, 999);
            await getHistory(botId, startDate.toISOString(), endDate.toISOString()).then((res) => {
                if (res.data) {
                    setHistories(res.data);
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchHistory();
    }, [botId, dateRange]);

    useEffect(() => {
        fetchHistory();
        props.setIsGetAnswer(false);
    }, [props.isGetAnswer]);

    useEffect(() => {
        setConversation(
            <>
                {histories.length ? (
                    histories[0].messages.map((message, index) => {
                        setChatSource(histories[0].chatSource);
                        return (
                            <div key={index} className={message.role === 'user' ? 'customer-text' : 'bot-text'}>
                                {message.role !== 'user' && message.score && (
                                    <span className="score-span">
                                        {translation.ACCURACY_SCORE}
                                        {(message.score * 100).toFixed(3)} %
                                    </span>
                                )}

                                <ReactMarkdown
                                    remarkPlugins={[remarkMath, rehypeKatex]}
                                    linkTarget="_blank"
                                    rel="noopener noreferrer">
                                    {message.content}
                                </ReactMarkdown>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
            </>,
        );
    }, [histories]);

    return (
        <>
            <div className="text-left">
                <Row gutter={32}>
                    <Col className="conversation-col-1">
                        <Space direction="vertical" className="width-100">
                            <RangePicker className="width-100" size="large" value={dateRange} onChange={handleChange} />
                        </Space>
                        <div className="margin-32">
                            {histories &&
                                histories.map((history, index) => {
                                    const messages = getConversation(history.messages);
                                    return (
                                        <div
                                            className="history-block"
                                            key={index}
                                            onClick={() => handleHistoryClick(index)}>
                                            <Row gutter={32}>
                                                <Col span={15}>
                                                    <div className="history-title">
                                                        {translation.CUSTOMER}
                                                        {messages[0].content}
                                                    </div>
                                                </Col>
                                                <Col span={9}>
                                                    <div className="history-time">
                                                        <TimeAgo datetime={new Date(history.updatedAt)}></TimeAgo>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="history-content">
                                                    {translation.BOT}
                                                    {messages[1].content}
                                                </div>
                                            </Row>
                                        </div>
                                    );
                                })}
                        </div>
                    </Col>
                    <Col className="conversation-col-2">
                        <p>
                            {translation.CHATSOURCE}
                            {chatSource}
                        </p>
                        <div className="history">{conversation}</div>
                    </Col>
                </Row>
            </div>
        </>
    );
}
