import { Layout, Button, Form, Input, Card, Alert, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { signin, resendEmail, requestPasswordReset } from '../../services/authServices';
import { getSignUpSetting } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

const { Content } = Layout;

export default function SignIn() {
    const translation = useTranslation().auth;
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [rememberMe] = useState(false);
    const [showResendAlert, setShowResendAlert] = useState(false);
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [user, setUser] = useState([]);
    const [signup, setSignup] = useState(false);
    const navigate = useNavigate();

    const fetchSignUpSetting = async () => {
        try {
            await getSignUpSetting().then((res) => {
                const status = res.data[0];
                status === 'true' ? setSignup(true) : setSignup(false);
            });
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchSignUpSetting();
    }, []);

    const handleChange = (event) => {
        setFormData((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const handleResend = async () => {
        try {
            await resendEmail(user);
            notification.success({
                message: translation.notification.RESEND_EMAIL_SUCCESS,
                placement: 'topRight',
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        }
    };

    const handleReset = async () => {
        try {
            await requestPasswordReset(formData.email);
            setShowResetAlert(true);
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        }
    };

    const handleSubmit = async () => {
        try {
            const data = await signin(formData, rememberMe);
            if (data.isVerified) {
                sessionStorage.setItem('user', JSON.stringify(data));

                notification.success({
                    message: `${translation.WELCOME}${data.name}!`,
                    placement: 'topRight',
                });
                navigate('/');
            } else {
                setShowResendAlert(true);
                setUser(data);
            }
        } catch (err) {
            notification.error({
                message: err.response.data.message,
                placement: 'topRight',
            });
        }
    };

    return (
        <>
            <Layout className="layout-default">
                <Content>
                    <Card className="card-signin" title={<h5>{translation.LOGIN}</h5>} bordered="false">
                        <Alert
                            message={translation.alert.SIGNIN_MESSAGE}
                            description={
                                <div>
                                    {translation.alert.SIGNIN_DES_HEADER}
                                    <Link onClick={handleResend} className="font-bold text-dark">
                                        {translation.alert.SIGNIN_DES_BODY}
                                    </Link>
                                    .
                                </div>
                            }
                            type="warning"
                            style={{
                                marginBottom: 12,
                                display: `${showResendAlert ? 'block' : 'none'}`,
                            }}
                        />
                        <Alert
                            message={translation.alert.RESET_PASSWORD_MESSAGE}
                            description={translation.alert.RESET_PASSWORD_DES}
                            type="success"
                            style={{
                                marginBottom: 12,
                                display: `${showResetAlert ? 'block' : 'none'}`,
                            }}
                        />
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Form.Item
                                label={translation.EMAIL}
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: translation.validation.EMAIL,
                                    },
                                ]}>
                                <Input
                                    placeholder={translation.EMAIL}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                label={translation.PASSWORD}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: translation.validation.PASSWORD,
                                    },
                                ]}
                                hasFeedback>
                                <Input.Password
                                    type="password"
                                    placeholder={translation.PASSWORD}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Link onClick={handleReset} className="text-dark font-bold float-right">
                                    {translation.FORGOT_PASSWORD}
                                </Link>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="width-100" htmlType="submit">
                                    {translation.SIGNIN_UP}
                                </Button>
                            </Form.Item>
                        </Form>
                        <p className="text-muted text-center" style={{ display: signup ? 'block' : 'none' }}>
                            {translation.SIGN_UP_QUESTION}
                            <Link to="/signup" className="text-dark font-bold">
                                {translation.SIGNUP_LO}
                            </Link>
                        </p>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}
