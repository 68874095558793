const en = {
    ERROR: 'Internal server error',
    auth: {
        LOGIN: 'Log In',
        REGISTER: 'Register',
        RESET_PASSWORD: 'Update Your Password',
        WELCOME: 'Welcome, ',
        NAME: 'Name',
        EMAIL: 'Email',
        role: {
            USER_ROLE: 'User Role',
            ADMIN: 'Admin',
            USER: 'User',
        },
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        NEW_PASSWORD: 'New Password',
        CONFIRM_NEW_PASSWORD: 'Confirm New Password',
        AGREE_TERM_HEAD: 'I agree the ',
        AGREE_TERM_BODY: 'Terms and Conditions',
        REMEMBER_ME: 'Remember me',
        FORGOT_PASSWORD: 'Forgot your password?',
        SIGNIN_UP: 'SIGN IN',
        SIGNIN_LO: 'Sign In',
        SIGNUP_UP: 'SIGN UP',
        SIGNUP_LO: 'Sign Up',
        SIGN_UP_QUESTION: "Don't have an account? ",
        SIGN_IN_QUESTION: 'Already have an account? ',
        UPDATE_PASSWORD: 'Update Password',
        validation: {
            NAME: 'Please input your username!',
            EMAIL: 'Please input a valid email!',
            PASSWORD: 'Please input your password!',
            CONFIRM_PASSWORD: 'Please confirm your password!',
            AGREE_TERM: 'Please agree the Terms and Conditions!',
        },
        alert: {
            SIGNIN_MESSAGE: 'Please verify your account!',
            SIGNIN_DES_HEADER:
                "Your account is not verified. Please check your email to veriry your account. If you didn't receive an email, please ",
            SIGNIN_DES_BODY: 'resend',
            SIGNUP_MESSAGE: 'Registration Success!',
            SIGNUP_DES: 'You successfully registered your account. Please check your email to veriry your account.',
            RESET_PASSWORD_MESSAGE: 'Request email to reset your password sent!',
            RESET_PASSWORD_DES:
                "We've sent an email to your email address. Follow the steps provided in the email to update your password.",
            CONFIRM_MESSAGE: 'Verification Success!',
            CONFIRM_DES_HEADER: 'Your account is successfully verified. Please ',
            CONFIRM_DES_BODY: 'Sign In',
        },
        notification: {
            AGREE_TERM_ERROR: 'Please agree to the terms and conditions.',
            PASSWORD_ERROR: 'Passwords do not match! Please re-enter them.',
            SIGNUP_SUCCESS: 'Signed up successfully! Please check your email to veriry.',
            UPDATE_SUCCESS: 'Your password is updated successfully! Please log in.',
            RESEND_EMAIL_SUCCESS: 'Success! Please verify your email.',
            ERROR: 'Internal server error',
        },
    },
    general: {
        DASHBOARD: 'Dashboard',
        AI_ASSISTANTS: 'AI Assistants',
        ADMIN_PAGE: 'Admin Page',
        PINECONE: 'Pinecone',
        CREATE_BOT_IN_BULK: 'Create bot in bulk',
        BOTS_IN_BULK: 'Bots in bulk',
        DEFAULT_SETTINGS: 'Default Settings',
        MANAGE_PROMPTS: 'Manage Prompts',
        PROMPT_LOGS: 'Prompt Logs',
        RESPONSES: 'Responses',
        ERROR_LOGS: 'Error Logs',
        USERS: 'Users',
        USER_LEVEL: 'User Level',
        LOG_OUT: 'Log Out',
        FRENCH: 'French',
        ENGLISH: 'English',
    },
    dashboard: {
        TITLE: 'Custom AI Assistant for your data',
        DESCRIPTION:
            'Just upload your documents or add a link to your website and get a ChatGPT-like AI Assistant for your data.',
        BUILD_ASSISTANT: 'Build Your Custom AI Assistant',
        USAGE: 'Usage',
        ASSISTANTS: 'Assistants',
        MESSAGES: 'Messages',
        subscriptions: {
            TITLE: 'Your Subscriptions',
            ACTIVE: 'Active',
            INCOMPLETE: 'Incomplete',
            FREE_PLAN: 'Free Plan',
            BASIC_PLAN: 'Basic Plan',
            PREMIUM_PLAN: 'Premium Plan',
            EXPERT_PLAN: 'Expert Plan',
            EXPIRE: 'Expires on: ',
        },
        plan: {
            free: {
                FIRST: '25 messages / month',
                SECOND: '1 AI Assistant',
                THIRD: '400 000 characters / AI Assistant',
                FOURTH: 'Embed on unlimited websites',
                FIFTH: 'Use plain text, website for embedding',
                SIXTH: 'View conversation history',
            },
            basic: {
                FIRST: '2500 messages / month',
                SECOND: '2 AI Assistants',
                THIRD: '10 000 000 characters / AI Assistant',
                FOURTH: 'Embed on unlimited websites',
                FIFTH: 'Use file, plain text, website for embedding',
                SIXTH: 'Upload multiple files',
                SEVENTH: 'View conversation history',
            },
            premium: {
                FIRST: '12000 messages / month',
                SECOND: '5 AI Assistants',
                THIRD: '10 000 000 characters / AI Assistant',
                FOURTH: 'Embed on unlimited websites',
                FIFTH: 'Use file, plain text, website, youtube url for embedding',
                SIXTH: 'Upload multiple files',
                SEVENTH: 'View conversation history',
                EIGHTH: 'GPT-4 option',
            },
            expert: {
                FIRST: '50000 messages / month',
                SECOND: '5 AI Assistants',
                THIRD: '15 000 000 characters / AI Assistant',
                FOURTH: 'Embed on unlimited websites',
                FIFTH: 'Use file, plain text, website, youtube url for embedding',
                SIXTH: 'Upload multiple files',
                SEVENTH: 'View conversation history',
                EIGHTH: 'GPT-4 option',
                NINETH: `Remove branding "Powered by Mr Smith"`,
                TENTH: 'Use your own custom domains',
            },
        },
        subscription_alert: {
            ALERT_A_TITLE: 'Please create a subscription!',
            ALERT_A_DESCRIPTION:
                'Please subscribe to our plans to unlock the full potential of this app and enjoy an enhanced user experience.',
            ALERT_B_TITLE: 'Your subscription is incompleted!',
            ALERT_B_DESCRIPTION:
                'Please subscribe to our plans to unlock the full potential of this app and enjoy an enhanced user experience.',
        },
        CREATE_SUBSCRIPTION: 'Create Subscription',
        update: {
            TITLE: 'Upgrade & Downgrade ',
            FREE: 'Free',
            BASIC: 'Basic',
            PREMIUM: 'Premium',
            EXPERT: 'Expert',
        },
        create: {
            TITLE: 'Data Sources',
            INCLUDED_SOURCES: 'Included Sources:',
            AI_ASSISTANT_NAME: 'AI Assistant Name',
            CREATE_AI_ASSISTANT: 'Create AI Assistant',
            CREATE_LOADING: 'Creating...',
            FILES: ' Files',
            FILE_CHARS: ' chars',
            TEXT_CHARS: ' text input chars',
            LINKS: ' Links',
            VIDEOS: ' Videos',
            ALLOCATED_USERS: 'Allocated Users',
            files: {
                TITLE: 'Files',
                UPLOAD_FILES: 'Upload Files',
                UPLOAD_DESCRIPTION: 'Attach a file to see how many characters are in it',
                FILE_CHARS: ' characters',
                CLICK_TO_UPLOAD: 'Click to Upload',
                NO_FILE_SELECT: 'No file Selected!',
            },
            text: {
                TITLE: 'Text',
                PLACEHOLDER: 'Data',
                TEXT_CHARS: ' characters',
            },
            website: {
                TITLE: 'Website',
                CRAWL: 'Crawl',
                FETCH_LINKS: 'Fetch more links',
                FETCH_LOADING: 'Fetching...',
                CRAWL_DESCRIPTION:
                    'This will crawl all the links starting with the URL (not including files on the website).',
                SUBMIT_SITEMAP: 'Submit Sitemap',
                PARSE_SITEMAP: 'Load Sitemap',
                PARSE_LOADING: 'Loading...',
                INCLUDED_LINKS: 'Included Links',
                ADD: 'Add',
                DELETE_ALL: 'Delete All',
                OR: 'OR',
            },
            videos: {
                TITLE: 'Videos',
                INCLUDED_LINKS: 'Included Links (Youtube URLs)',
                PLACEHOLDER: 'Please input video urls.',
                ADD: 'Add Videos',
                DELETE_ALL: 'Delete All',
            },
            notification: {
                NAME_ERROR: 'Please input an AI Assistant name.',
                CREATE_SUCCESS: 'The AI Assistant is successfully created.',
                SOURCE_ERROR: 'Please add your sources.',
                LIMIT_ERROR_FIRST: 'You can only use ',
                LIMIT_ERROR_SECOND: ' characters for your assistant.',
            },
        },
        notification: {
            ERROR: 'Internal Server Error',
        },
    },
    chatbot: {
        TITLE: 'AI Assistants',
        CREATE_AI_ASSISTANT: 'New AI Assistant',
        EMPTY_DESCRIPTION: "Let's create your AI Assistant!!!",
        MY_AI_ASSISTANT: 'My AI Assistants',
        OTHER_AI_ASSISTANT: 'Other AI Assistants',
        mainwindows: {
            TITLE: 'AI Assistant',
            CHATSOURCE_SITE: 'Site MrSmithAI',
            CHATSOURCE_FRAME: 'Widget or IFrame',
            INPUT_PLACEHOLDER: 'Type a message.',
            MESSAGE_LEFT: ' messages left',
            REFRESH: 'Refresh',
            SEND: 'Send',
            notification: {
                FETCH_ANSWER_ERROR: 'An error occurred while fetching the data. Please try again.',
                ERROR: 'Internal server error',
            },
        },
        settings: {
            TITLE: 'Settings',
            AI_ASSISTANT_ID: 'AI Assistant ID',
            NAME: 'Name',
            BASE_PROMPT: 'Base Prompt (system message)',
            RESET: 'Reset',
            SELECT_BASE_PROMPT: 'Select Baseprompt Templates',
            TEMPERATURE: 'Temperature',
            RESERVED: 'Reserved',
            CREATIVE: 'Creative',
            visibility: {
                TITLE: 'Visibility',
                PRIVATE: 'Private',
                PRIVATE_EMBED: 'Private but can be embedded on website',
                PUBLIC: 'Public',
                PRIVATE_DES: "'Private': No one can access your AI Assistant except you (your account)",
                PRIVATE_EMBED_DES:
                    "'Private but can be embedded on website': Other people can't access your AI Assistant if you send them the link, but you can still embed it on your website and your website visitors will be able to use it. (make sure to set your domains)",
                PUBLIC_DES:
                    "'Public': Anyone with the link can access it on app.mrsmith.ai and can be embedded on your website.",
                LAST_DES:
                    'Set to public if you want to be able to send a link of your AI Assistant to someone to try it.',
            },
            domains: {
                TITLE: 'Domains',
                DES_FIRST: 'Enter each domain in a new line',
                DES_LAST:
                    "Domains you want to embed your AI Assistant on. Your AI Assistant visibility has to be 'Public' or 'Private but can be embedded on website' for this to work.",
            },
            limit: {
                TITLE: 'Rate Limiting',
                DESCRIPTION:
                    'Limit the number of messages sent from one device on the iframe and chat bubble (this limit will not be applied to you on app.mrsmith.ai, only on your website for your users to prevent abuse).',
                DES_FISRT: 'Limit to only ',
                DES_MIDDLE: ' messages every ',
                DES_LAST: ' seconds.',
                LIMIT_MESSAGE: 'Show this message to show when limit is hit',
            },
            YES: 'Yes',
            NO: 'No',
            ADD_CONTEXT: 'Add Context',
            CONTEXT: 'Context',
            SELECT_CONTEXT: 'Select Context Templates',
            REMEMBER_CONVERSATION: 'Remember Conversation',
            CONVERSATION_NUMBER: 'Number of conversation to remember',
            ADD_LOGS: 'Add logs',
            ADD_SOURCE: 'Add Source',
            SUMMARIZE: 'Content Summary',
            MODEL: 'Model',
            OTHRE_PARAMETERS: 'Other parameters',
            MAX_TOKENS: 'Max Tokens',
            BEST_OF: 'Best of ',
            TOP_P: 'Top P',
            FREQUENCY_PENALTY: 'Frequency Penalty ',
            PRESENCE_PENALTY: 'Presence Penalty ',
            language: {
                TITLE: 'Language',
                label: {
                    FRENCH: 'French',
                    ENGLISH: 'English',
                },
            },
            tone: {
                TITLE: 'Tone ',
                label: {
                    FRIENDLY: 'Friendly',
                    PROFESSIONAL: 'Professional',
                    SARCASTIC: 'Sarcastic',
                    HUMOROUS: 'Humorous',
                    CHEERFUL: 'Cheerful',
                    ANECDOTAL: 'Anecdotal',
                },
            },
            profession: {
                TITLE: 'Profession ',
                label: {
                    NONE: 'None',
                    ACCOUNTANT: 'Accountant',
                    ADVERTISING_SPECIALIST: 'Advertising Specialist',
                    ARCHITECT: 'Architect',
                    ARTIST: 'Artist',
                    BLOGGER: 'Blogger',
                    BUSINESS_ANALYST: 'Business Analyst',
                    BUSINESS_OWNER: 'Business Owner',
                    CAR_EXPERT: 'Car Expert',
                    CONSULTANT: 'Consultant',
                    COUNSELOR: 'Counselor',
                    CRYPTOCURRENCY_TRADER: 'Cryptocurrency Trader',
                    CRYPTOCURRENCY_EXPERT: 'Cryptocurrency Expert',
                    CUSTOMER_SUPPORT: 'Customer Support',
                    DESIGNER: 'Designer',
                    DIGITAL_MARKETING_AGENCY: 'Digital Marketing Agency',
                    EDITOR: 'Editor',
                    EVENT_PLANNER: 'Event Planner',
                    FREELANCER: 'Freelancer',
                    INSURANCE_AGENT: 'Insurance Agent',
                    INSURANCE_BROKER: 'Insurance Broker',
                    INTERIOR_DESIGNER: 'Interior Designer',
                    JOURNALIST: 'Journalist',
                    MARKETING_AGENCY: 'Marketing Agency',
                    MARKETING_EXPERT: 'Marketing Expert',
                    MARKETING_SPECIALIST: 'Marketing Specialist',
                    PHOTOGRAPHER: 'Photographer',
                    PROGRAMMER: 'Programmer',
                    PUBLIC_RELATIONS_AGENCY: 'Public Relations Agency',
                    PUBLISHER: 'Publisher',
                    REAL_ESTATE_AGENT: 'Real Estate Agent',
                    RECRUITER: 'Recruiter',
                    REPORTER: 'Reporter',
                    SALES_PERSON: 'Sales Person',
                    SALES_REPRESENTATIVE: 'Sales Representative',
                    SEO_AGENCY: 'SEO Agency',
                    SEO_EXPERT: 'SEO Expert',
                    SOCIAL_MEDIA_AGENCY: 'Social Media Agency',
                    STUDENT: 'Student',
                    TEACHER: 'Teacher',
                    TECHNICAL_SUPPORT: 'Technical Support',
                    TRAINER: 'Trainer',
                    TRAVEL_AGENCY: 'Travel Agency',
                    VIDEOGRAPHER: 'Videographer',
                    WEB_DESIGN_AGENCY: 'Web Design Agency',
                    WEB_DESIGN_EXPERT: 'Web Design Expert',
                    WEB_DEVELOPMENT_AGENCY: 'Web Development Agency',
                    WEB_DEVELOPMENT_EXPERT: 'Web Development Expert',
                    WEB_DESIGNER: 'Web Designer',
                    WEB_DEVELOPER: 'Web Developer',
                    WRITER: 'Writer',
                },
            },
            SAVE_CHANGES: 'Save Changes',
            SAVE_LOADING: 'Saving...',
            notification: {
                SUCCESS: 'Bot setting is successfully changed.',
                ERROR: 'Internal server error',
            },
        },
        inferface: {
            TITLE: 'Interface',
            MAIN_TITLE: 'Chat Interface',
            DESCRIPTION: 'applies when embedded on a website',
            INITIAL_MESSAGES: 'Initial Messages',
            RESET: 'Reset',
            INITIAL_DES: 'Enter each message in a new line.',
            SUGGESTED_MESSAGES: 'Suggested Messages',
            SUGGEST_DES: 'Enter each message in a new line.',
            theme: {
                TITLE: 'Theme',
                LIGHT: 'Light',
                DARK: 'Dark',
            },
            PICTURE: 'Update AI Assistant profile picture',
            PICTURE_UPLOAD: 'Click to Upload',
            REMOVE_PICTURE: 'Remove AI Assistant Profile Picture',
            DISPLAY_NAME: 'Display name',
            PICTURE_COLOR: 'User Message Color',
            PICTURE_DES:
                "**If the changes here don't show up immediately on your website try clearing your browser cache or use incognito. (New users will see the changes immediately)**",
            ICON: 'Update chat icon',
            ICON_UPLOAD: 'Click to Upload',
            REMOVE_ICON: 'Remove chat icon',
            ICON_COLOR: 'Chat Bubble Button Color',
            ICON_ALIGN: 'Align Chat Bubble Button',
            ALIGN_LEFT: 'Left',
            ALIGN_RIGHT: 'Right',
            HI: 'Hi!',
            INPUT_PLACEHOLDER: 'Type a message.',
            SAVE_CHANGES: 'Save Changes',
            SAVE_LOADING: 'Saving...',
            notification: {
                SUCCESS: 'Interface setting is successfully changed.',
                ERROR: 'Internal server error',
            },
        },
        conversation: {
            TITLE: 'Conversations',
            CUSTOMER: 'Client: ',
            BOT: 'Assistant AI: ',
            CHATSOURCE: 'Chat Source: ',
            ACCURACY_SCORE: 'Accuracy Score: ',
        },
        managesource: {
            TITLE: 'Manage Sources',
            INCLUDED_SOURCES: 'Included Sources:',
            ALREADY_INCLUDED_SOURCES: 'Already Included Files:',
            AI_ASSISTANT_NAME: 'AI Assistant Name',
            RETRAIN_AI_ASSISTANT: 'Retrain AI Assistant',
            RETRAIN_LOADING: 'Training...',
            FILES: ' Files',
            FILE_CHARS: ' chars',
            TEXT_CHARS: ' text input chars',
            LINKS: ' Links',
            VIDEOS: ' Videos',
            ALLOCATED_USERS: 'Allocated Users',
            files: {
                TITLE: 'Files',
                UPLOAD_FILES: 'Upload Files',
                UPLOAD_DESCRIPTION: 'Attach a file to see how many characters are in it',
                FILE_CHARS: ' characters',
                CLICK_TO_UPLOAD: 'Click to Upload',
                NO_FILE_SELECT: 'No file Selected!',
            },
            text: {
                TITLE: 'Text',
                PLACEHOLDER: 'Data',
                TEXT_CHARS: ' characters',
            },
            website: {
                TITLE: 'Website',
                CRAWL: 'Crawl',
                FETCH_LINKS: 'Fetch more links',
                FETCH_LOADING: 'Fetching...',
                CRAWL_DESCRIPTION:
                    'This will crawl all the links starting with the URL (not including files on the website).',
                SUBMIT_SITEMAP: 'Submit Sitemap',
                PARSE_SITEMAP: 'Load Sitemap',
                PARSE_LOADING: 'Loading...',
                INCLUDED_LINKS: 'Included Links',
                ADD: 'Add',
                DELETE_ALL: 'Delete All',
                OR: 'OR',
            },
            videos: {
                TITLE: 'Videos',
                INCLUDED_LINKS: 'Included Links (Youtube URLs)',
                ADD: 'Add',
                DELETE_ALL: 'Delete All',
            },
            notification: {
                NAME_ERROR: 'Please input an AI Assistant name.',
                CREATE_SUCCESS: 'The AI Assistant is successfully retrained.',
                SOURCE_ERROR: 'Please add your sources.',
                LIMIT_ERROR_FIRST: 'You can only use ',
                LIMIT_ERROR_SECOND: ' characters for your assistant.',
                ERROR: 'Internal server error',
            },
        },
        embed: {
            TITLE: 'Embed on website',
            firstmodal: {
                TITLE: 'Add Domains',
                DESCRIPTION:
                    'To embed this AI assistant, please add the website domain(e.x: www.example.com). Please enter each domain in a new line.',
                ADD: 'Add',
            },
            secondmodal: {
                TITLE: 'Embed on website',
                FIRST_DES: 'To add the AI assistant any where on your website, add this script to your html code.',
                SECOND_DES:
                    'To add a chat bubble to the bottom right of your website add this script tag to your html.',
            },
        },
        delete: {
            TITLE: 'Delete AI Assistant',
            CONTENT: 'Are you sure you want to delete your AI Assistant? This action cannot be undone.',
            OKTEXT: 'Delete',
            CANCELTEXT: 'Cancel',
            notification: {
                SUCCESS: 'Successfully deleted.',
                ERROR: 'Internal server error',
            },
        },
    },
    admin: {
        pinecone: {
            TITLE: 'Pinecone',
            ID: 'ID',
            NAMESPACE: 'Namespace',
            USER: 'User',
            SOURCES: 'Sources',
            NAME: 'Name',
            ACTION: 'Action',
            FILES: 'Files',
            TEXT: 'Plain Text',
            CHARACTERS: 'Characters',
            WEBSITE: 'Website',
            LINKS: 'Links',
            VIDEOS: 'Videos',
            detail: {
                ID: 'ID',
                TYPE: 'Type',
                SOURCE: 'Source',
                DATA: 'Data',
                LAST_UPDATED: 'Last Updated',
                ACTION: 'Action',
            },
            EDIT_TITLE: 'Edit Data',
            EDIT_OKTEXT: 'Save changes',
            EDIT_CANCELTEXT: 'Cancel',
            DELETE_TITLE: 'Delete',
            DESCRIPTION: 'Are you sure you want to delete?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Cancel',
            notification: {
                EDIT_SUCCESS: 'Data is successfully changed.',
                DELETE_SUCCESS: 'Successfully deleted.',
                ERROR: 'Internal server error',
            },
        },
        createbotinbulk: {
            TITLE: 'Please add websites to create bots.',
            UPLOAD_CSV: 'Upload CSV',
            DELETE_ALL: 'Delete All',
            CREATE_BOT: 'Create bot in bulk',
            CREATE_LOADING: 'Creating...',
            SYSTEM_MSG: 'System Message',
            notification: {
                SUCCESS: 'AI Assistants are successfully created.',
                ERROR: 'Internal server error',
            },
        },
        botsinbulk: {
            TITLE: 'Bots in Bulk',
            DESCRIPTION: ' (Users can access these AI assistants using this url: ',
            SET: 'Set',
            CALENDLY: 'Your Calendly URL',
            ID: 'ID',
            BOTID: 'BotID',
            WEBSITE: 'Website',
            FREE_ACCESS: 'Free Access',
            ACTION: 'Action',
            DELETE_TITLE: 'Delete',
            DELETE_ALL: 'Delete All',
            DELETE_DESCRIPTION: 'Are you sure you want to delete?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Cancel',
            notification: {
                UPDATE_SUCCESS_MSG: 'Bot Updated',
                UPDATE_SUCCESS_DES: "The bot's free access status has been successfully updated.",
                UPDATE_ERROR_MSG: 'Error',
                UPDATE_ERROR_DES: "An error occurred while updating the bot's free access status.",
                DELETE_SUCCESS_MSG: 'Bot Deleted',
                DELETE_SUCCESS_DES: 'The bot has been successfully deleted.',
                DELETE_ERROR_MSG: 'Error',
                DELETE_ERROR_DES: 'An error occurred while deleting the bot.',
                CALENDLY_SUCCESS_MSG: 'Successfully set your calendly url.',
                CALENDLY_ERROR_MSG: 'Error while setting calendly.',
            },
        },
        defaultsettings: {
            TITLE: 'Set All Default Settings',
            settings: {
                TITLE: 'Bot Settings',
                AI_ASSISTANT_ID: 'AI Assistant ID',
                NAME: 'Name',
                BASE_PROMPT: 'Base Prompt (system message)',
                RESET: 'Reset',
                RESET_BASE_PROMPT:
                    "I want you to act as a document that I am having a conversation with. Your name is 'AI Assistant'. You will provide me with answers from the given info. If the answer is not included, say exactly 'Hmm, I am not sure.' and stop after that. Refuse to answer any question not about the info. Never break character.",
                SELECT_BASE_PROMPT: 'Select Baseprompt Templates',
                RESET_LIMIT_MSG: 'Too many messages in a row.',
                RESET_INITIAL_MSG: 'Hi! What can I help you with?',
                TEMPERATURE: 'Temperature',
                RESERVED: 'Reserved',
                CREATIVE: 'Creative',
                visibility: {
                    TITLE: 'Visibility',
                    PRIVATE: 'Private',
                    PRIVATE_EMBED: 'Private but can be embedded on website',
                    PUBLIC: 'Public',
                    PRIVATE_DES: "'Private': No one can access your AI Assistant except you (your account)",
                    PRIVATE_EMBED_DES:
                        "'Private but can be embedded on website': Other people can't access your AI Assistant if you send them the link, but you can still embed it on your website and your website visitors will be able to use it. (make sure to set your domains)",
                    PUBLIC_DES:
                        "'Public': Anyone with the link can access it on app.mrsmith.ai and can be embedded on your website.",
                    LAST_DES:
                        'Set to public if you want to be able to send a link of your AI Assistant to someone to try it.',
                },
                domains: {
                    TITLE: 'Domains',
                    DES_FIRST: 'Enter each domain in a new line',
                    DES_LAST:
                        "Domains you want to embed your AI Assistant on. Your AI Assistant visibility has to be 'Public' or 'Private but can be embedded on website' for this to work.",
                },
                limit: {
                    TITLE: 'Rate Limiting',
                    DESCRIPTION:
                        'Limit the number of messages sent from one device on the iframe and chat bubble (this limit will not be applied to you on app.mrsmith.ai, only on your website for your users to prevent abuse).',
                    DES_FISRT: 'Limit to only ',
                    DES_MIDDLE: ' messages every ',
                    DES_LAST: ' seconds.',
                    LIMIT_MESSAGE: 'Show this message to show when limit is hit',
                },
                YES: 'Yes',
                NO: 'No',
                ADD_CONTEXT: 'Add Context',
                CONTEXT: 'Context',
                SELECT_CONTEXT: 'Select Context Templates',
                REMEMBER_CONVERSATION: 'Remember Conversation',
                CONVERSATION_NUMBER: 'Number of conversation to remember',
                ADD_LOGS: 'Add logs',
                ADD_SOURCE: 'Add Source',
                SUMMARIZE: 'Content Summary',
                MODEL: 'Model',
                OTHRE_PARAMETERS: 'Other parameters',
                MAX_TOKENS: 'Max Tokens',
                BEST_OF: 'Best of ',
                TOP_P: 'Top P',
                FREQUENCY_PENALTY: 'Frequency Penalty ',
                PRESENCE_PENALTY: 'Presence Penalty ',
                language: {
                    TITLE: 'Language',
                    label: {
                        FRENCH: 'French',
                        ENGLISH: 'English',
                    },
                },
                tone: {
                    TITLE: 'Tone ',
                    label: {
                        FRIENDLY: 'Friendly',
                        PROFESSIONAL: 'Professional',
                        SARCASTIC: 'Sarcastic',
                        HUMOROUS: 'Humorous',
                        CHEERFUL: 'Cheerful',
                        ANECDOTAL: 'Anecdotal',
                    },
                },
                profession: {
                    TITLE: 'Profession ',
                    label: {
                        NONE: 'None',
                        ACCOUNTANT: 'Accountant',
                        ADVERTISING_SPECIALIST: 'Advertising Specialist',
                        ARCHITECT: 'Architect',
                        ARTIST: 'Artist',
                        BLOGGER: 'Blogger',
                        BUSINESS_ANALYST: 'Business Analyst',
                        BUSINESS_OWNER: 'Business Owner',
                        CAR_EXPERT: 'Car Expert',
                        CONSULTANT: 'Consultant',
                        COUNSELOR: 'Counselor',
                        CRYPTOCURRENCY_TRADER: 'Cryptocurrency Trader',
                        CRYPTOCURRENCY_EXPERT: 'Cryptocurrency Expert',
                        CUSTOMER_SUPPORT: 'Customer Support',
                        DESIGNER: 'Designer',
                        DIGITAL_MARKETING_AGENCY: 'Digital Marketing Agency',
                        EDITOR: 'Editor',
                        EVENT_PLANNER: 'Event Planner',
                        FREELANCER: 'Freelancer',
                        INSURANCE_AGENT: 'Insurance Agent',
                        INSURANCE_BROKER: 'Insurance Broker',
                        INTERIOR_DESIGNER: 'Interior Designer',
                        JOURNALIST: 'Journalist',
                        MARKETING_AGENCY: 'Marketing Agency',
                        MARKETING_EXPERT: 'Marketing Expert',
                        MARKETING_SPECIALIST: 'Marketing Specialist',
                        PHOTOGRAPHER: 'Photographer',
                        PROGRAMMER: 'Programmer',
                        PUBLIC_RELATIONS_AGENCY: 'Public Relations Agency',
                        PUBLISHER: 'Publisher',
                        REAL_ESTATE_AGENT: 'Real Estate Agent',
                        RECRUITER: 'Recruiter',
                        REPORTER: 'Reporter',
                        SALES_PERSON: 'Sales Person',
                        SALES_REPRESENTATIVE: 'Sales Representative',
                        SEO_AGENCY: 'SEO Agency',
                        SEO_EXPERT: 'SEO Expert',
                        SOCIAL_MEDIA_AGENCY: 'Social Media Agency',
                        STUDENT: 'Student',
                        TEACHER: 'Teacher',
                        TECHNICAL_SUPPORT: 'Technical Support',
                        TRAINER: 'Trainer',
                        TRAVEL_AGENCY: 'Travel Agency',
                        VIDEOGRAPHER: 'Videographer',
                        WEB_DESIGN_AGENCY: 'Web Design Agency',
                        WEB_DESIGN_EXPERT: 'Web Design Expert',
                        WEB_DEVELOPMENT_AGENCY: 'Web Development Agency',
                        WEB_DEVELOPMENT_EXPERT: 'Web Development Expert',
                        WEB_DESIGNER: 'Web Designer',
                        WEB_DEVELOPER: 'Web Developer',
                        WRITER: 'Writer',
                    },
                },
                SAVE_CHANGES: 'Save BotSetting Changes',
                SAVE_LOADING: 'Saving...',
                notification: {
                    SUCCESS: 'Bot setting is successfully changed.',
                    ERROR: 'Internal server error',
                },
            },
            inferface: {
                TITLE: 'Chat Interface',
                DESCRIPTION: 'applies when embedded on a website',
                INITIAL_MESSAGES: 'Initial Messages',
                RESET: 'Reset',
                INITIAL_DES: 'Enter each message in a new line.',
                SUGGESTED_MESSAGES: 'Suggested Messages',
                SUGGEST_DES: 'Enter each message in a new line.',
                SUGGEST_PLACEHOLDER: 'What is example.com? How does it work?',
                theme: {
                    TITLE: 'Theme',
                    LIGHT: 'Light',
                    DARK: 'Dark',
                },
                PICTURE: 'Update AI Assistant profile picture',
                PICTURE_UPLOAD: 'Click to Upload',
                REMOVE_PICTURE: 'Remove AI Assistant Profile Picture',
                DISPLAY_NAME: 'Display name',
                PICTURE_COLOR: 'User Message Color',
                PICTURE_DES:
                    "**If the changes here don't show up immediately on your website try clearing your browser cache or use incognito. (New users will see the changes immediately)**",
                ICON: 'Update chat icon',
                ICON_UPLOAD: 'Click to Upload',
                REMOVE_ICON: 'Remove chat icon',
                ICON_COLOR: 'Chat Bubble Button Color',
                ICON_ALIGN: 'Align Chat Bubble Button',
                ALIGN_LEFT: 'Left',
                ALIGN_RIGHT: 'Right',
                HI: 'Hi!',
                INPUT_PLACEHOLDER: 'Type a message.',
                SAVE_CHANGES: 'Save Interface Changes',
                SAVE_LOADING: 'Saving...',
                notification: {
                    SUCCESS: 'Interface setting is successfully changed.',
                    ERROR: 'Internal server error',
                },
            },
        },
        manageprompts: {
            TITLE: 'Manage Prompts',
            baseprompt: {
                TITLE: 'Base Prompts',
                ADD_TEMPLATE: 'Add template',
                SAVE_TEMPLATES: 'Save Templates',
                TEMPLATES_SAVING: 'Saving...',
                notification: {
                    SUCCESS: 'Baseprompt templates are successfully saved.',
                    ERROR: 'Internal server error',
                },
            },
            context: {
                TITLE: 'Contexts',
                ADD_TEMPLATE: 'Add template',
                SAVE_TEMPLATES: 'Save Templates',
                TEMPLATES_SAVING: 'Saving...',
                notification: {
                    SUCCESS: 'Context templates are successfully saved.',
                    ERROR: 'Internal server error',
                },
            },
        },
        errors: {
            TITLE: 'Error Logs',
            ID: 'ID',
            URL: 'Error Url',
            CODE: 'Error Code',
            MESSAGE: 'Error Message',
            STATUS: 'Error Status',
            STATUSTEXT: 'Error StatusText',
            CREATEDAT: 'CreatedAt',
            ACTION: 'Action',
            ERROR_INFO: 'Error Info',
            DELETE_TITLE: 'Resolve',
            DELETE_ALL: 'Delete All',
            DELETE_DESCRIPTION: 'Are you sure you want to resolve?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Cancel',
            notification: {
                SUCCESS_MSG: 'Error Resolved',
                SUCCESS_DES: 'The error has been successfully resolved.',
                ERROR_MSG: 'Error',
                ERROR_DES: 'An error occurred while resolving the error.',
            },
        },
        users: {
            TITLE: 'Users',
            SHOW_SIGNUP: 'Show SignUp',
            ADMIN_TITLE: 'Admins',
            USER_TITLE: 'Users',
            ID: 'ID',
            NAME: 'Name',
            EMAIL: 'Email',
            VERIFIABLE: 'Verifiable',
            BOTLIMIT: 'BotLimit',
            QUERYLIMIT: 'QueryLimit',
            CHARACTERLIMIT: 'CharacterLimit',
            ASSISTANT_LIMIT: ' assistant limit',
            QUERY_LIMIT: ' query limit',
            ACTIVE: 'Active',
            INCOMPLETE: 'Incomplete',
            EXPIRE: 'Expires on: ',
            level: {
                TITLE: 'Level',
                BASIC: 'Basic',
                PREMIUM: 'Premium',
                EXPERT: 'Expert',
            },
            ACTION: 'Action',
            VERIFIED: 'Verified',
            NOT_VERIFIED: 'Not Verified',
            FILES_DES: 'Files, ',
            TEXT_DES: 'Text',
            WEBSITE_DES: ', Website',
            VIDEO_DES: ', Videos',
            NEW_ADMIN: 'New Admin',
            new_admin: {
                TITLE: 'Add New Admin',
                NAME: 'Name',
                EMAIL: 'Email',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                ADD_ADMIN: 'Add Admin',
                CANCEL: 'Cancel',
            },
            update_admin: {
                TITLE: 'Update Admin',
                NAME: 'Name',
                EMAIL: 'Email',
                UPDATE_ADMIN: 'Update Admin',
                CANCEL: 'Cancel',
            },
            NEW_USER: 'New User',
            new_user: {
                TITLE: 'Add New User',
                NAME: 'Name',
                EMAIL: 'Email',
                VALIDATE: 'Email Validation',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                LEVEL: 'Level',
                BOTLIMIT: 'BotLimit',
                QUERYLIMIT: 'QueryLimit',
                CHARACTERLIMIT: 'CharacterLimit',
                DATA_FORMAT: 'Data Format',
                FILES: 'Files',
                WEBSITE: 'Website',
                VIDEOS: 'Videos',
                ADD_USER: 'Add User',
                CANCEL: 'Cancel',
            },
            update_user: {
                TITLE: 'Update User',
                NAME: 'Name',
                EMAIL: 'Email',
                LEVEL: 'Level',
                BOTLIMIT: 'BotLimit',
                QUERYLIMIT: 'QueryLimit',
                CHARACTERLIMIT: 'CharacterLimit',
                DATA_FORMAT: 'Data Format',
                FILES: 'Files',
                WEBSITE: 'Website',
                VIDEOS: 'Videos',
                UPDATE_USER: 'Update User',
                CANCEL: 'Cancel',
            },
            delete: {
                TITLE: 'Delete',
                DESCRIPTION: 'Are you sure you want to delete?',
                OK: 'OK',
                CANCEL: 'Cancel',
            },
            validation: {
                NAME: 'Please input your username!',
                EMAIL: 'Please input a valid email!',
                PASSWORD: 'Please input your password!',
                CONFIRM_PASSWORD: 'Please confirm your password!',
                LEVEL: 'Please select the level!',
                BOTLIMIT: 'Please input botlimit!',
                QUERYLIMIT: 'Please input querylimit!',
                CHARACTERLIMIT: 'Please input characterlimit!',
            },
            notification: {
                ADD_ADMIN_SUCCESS: 'New Admin is successfully created.',
                UPDATE_ADMIN_SUCCESS: 'Admin is successfully updated.',
                ADD_USER_SUCCESS: 'New User is successfully created.',
                UPDATE_USER_SUCCESS: 'User is successfully updated.',
                DELETE_SUCCESS: 'Successfully deleted.',
                ERROR: 'Internal server error',
            },
        },
        levels: {
            TITLE: 'User Level',
            ID: 'ID',
            NAME: 'Name',
            BOTLIMIT: 'BotLimit',
            QUERYLIMIT: 'QueryLimit',
            CHARACTERLIMIT: 'CharacterLimit',
            DATASOURCE: 'Data Source',
            ACTION: 'Action',
            FILES: 'Files',
            TEXT: 'Plain Text',
            WEBSITE: 'Website',
            VIDEOS: 'Videos',
            ADD_LEVEL: 'Add Level',
            EDIT_LEVEL: 'Edit Level',
            DELETE_TITLE: 'Delete',
            DELETE_DESCRIPTION: 'Are you sure you want to delete?',
            OK: 'OK',
            CANCEL: 'Cancel',
            GPT4: 'GPT-4',
            REMOVE_BRANDING: 'Remove Branding',
            REMOVE: 'Remove ',
            TRUE: 'True',
            FALSE: 'False',
            YES: 'Yes',
            NO: 'No',
            validation: {
                NAME: 'Please input level name!',
                BOTLIMIT: 'Please input botlimit!',
                QUERYLIMIT: 'Please input querylimit!',
                CHARACTERLIMIT: 'Please input characterlimit!',
            },
            notification: {
                ADD_SUCCESS: 'New Level is successfully created.',
                UPDATE_SUCCESS: 'The Level is successfully updated.',
                DELETE_SUCCESS: 'Successfully deleted.',
                ERROR: 'Internal server error',
            },
        },
        prompt_logs: {
            TITLE: 'Prompt Logs',
            ID: 'ID',
            CHATID: 'Chat ID',
            BOTNAME: 'Bot Name',
            USER: 'User',
            CHATSOURCE: 'Chat Source',
            UPDATEDAT: 'Log Date',
            LOG_INFO: 'Log Info',
            CUSTOMER: 'User: ',
            AI_ASSISTANT: 'AI Assistant: ',
            DURATION_TEXT01: 'Initial Setting: ',
            DURATION_TEXT02: 'Create vectors to search: ',
            DURATION_TEXT03: 'Get a relevant answer: ',
            DURATION_TEXT04: 'Get the summary: ',
            DURATION_TEXT05: 'Get the final answer: ',
            TOTAL_DURATION: 'Total duration: ',
        },
        responses: {
            TITLE: 'Response Analytics',
            ID: 'ID',
            BOTID: 'BotID',
            NAME: 'Name',
            USER: 'User',
            UPDATEDAT: 'UpdatedAt',
            CREATEDAT: 'CreatedAt',
            NODATA: 'No Data',
            AVERAGE_RESPONSE: 'Average Response: ',
            EXPORT_TO_CSV: 'Export To CSV',
            PERFORMANCE: 'Performance(seconds)',
            RESPONSE_DURATION: 'Response Duration',
            DATETIME: 'Date / Time',
            PROMPT: 'Prompt',
            RESPONSE: 'Response',
            DURATION: 'Duration',
            DURATION_TEXT01: 'Initial Setting: ',
            DURATION_TEXT02: 'Create vectors to search: ',
            DURATION_TEXT03: 'Get a relevant answer: ',
            DURATION_TEXT04: 'Get the summary: ',
            DURATION_TEXT05: 'Get the final answer: ',
            TOTAL_DURATION: 'Total duration: ',
        },
        assistant: {
            CHATSOURCE_ASSIST: 'Bots in Bulk',
            ERROR_MSG: 'You can send only 10 messages freely.',
            AI_ASSISTANT: 'My AI assistant trained on my company',
            INPUT_PLACEHOLDER: 'Ask a question about your business!',
            ACCESS_ERROR_MSG: "You can't access to this AI assistant freely.",
            FIRST_TEXT: '🤖 Discover the future of AI with Mr Smith: Test your virtual assistant for free! 🚀',
            SECOND_TEXT: 'Your own AI assistant is ready to revolutionize the way you interact with your customers.',
            THIRD_TEXT:
                "(This page is just a glimpse of the extraordinary potential that Mr. Smith's AI can offer in a demo version trained on ",
            FOURTH_TEXT: "Test the effectiveness of Mr Smith's AI trained on 10 pages of your own website today!",
            FIFTH_TEXT: 'The future of your business starts here, with Mr Smith!',
            SIXTH_TEXT: 'Request your AI assistant now',
            HELLO_HEAD: "👋Hello! I'm an AI trained on ",
            HELLO_END: ', you can ask me your questions!',
            CALENDLY_BUTTON_TEXT:
                'Book a call right away with our Artificial Intelligence experts to train your new assistant!',
            SEND: 'Send the message',
        },
    },
    loadingtext: {
        first: [
            'AI Assistant is learning and adapting to provide you with the best experience.',
            'Please wait while the AI Assistant analyzes data to improve its knowledge.',
            'In the process of training, the AI Assistant is working hard to enhance its capabilities.',
            'Training in progress: the AI Assistant is acquiring new skills and expanding its knowledge.',
            'Sit tight! The AI Assistant is being trained to better understand and assist you.',
        ],
        second: 'AI Assistant is Learning ...',
    },
};

export default en;
