import axios from 'axios';
import { addError } from './adminServices';

const instance = axios.create();

export async function signin(formData, rememberMe) {
    try {
        const response = await instance.post('/api/auth/signin', formData);
        const { token, user } = response.data;
        setAuthToken(token, rememberMe);
        return user;
    } catch (err) {
        console.log(err);
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function verifyUser(token) {
    try {
        const sendData = { token: token };
        const response = await instance.post('/api/auth/verifyUser', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function signup(formData) {
    try {
        const response = await instance.post('/api/auth/signup', formData);
        const { token, user } = response.data;
        setAuthToken(token, false);
        return user;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function logout() {
    try {
        await instance.post('/api/auth/logout');
        removeAuthToken();
        sessionStorage.removeItem('user');
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export function setAuthToken(token, rememberMe) {
    sessionStorage.setItem('authToken', token);
}

export function removeAuthToken() {
    sessionStorage.removeItem('authToken');
}

export function getAuthToken() {
    return sessionStorage.getItem('authToken');
}

export function getUserRole() {
    const user = getUserInfo();
    if (user) {
        return user.role;
    } else {
        return null;
    }
}

export function getUserInfo() {
    return JSON.parse(sessionStorage.getItem('user'));
}

export async function setCalendlyUrl(URL) {
    const user = getUserInfo();
    const sendData = {
        email: user.email,
        calendlyUrl: URL,
    };
    try {
        await csrf();
        const response = await instance.post('/api/admin/setCalendlyUrl', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getCalendlyUrl() {
    try {
        const response = await instance.get('/api/admin/getCalendlyUrl');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getUserByToken(token) {
    const sendData = {
        token: token,
    };
    try {
        const response = await instance.post('/api/auth/getUserByToken', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function resendEmail(user) {
    const sendData = {
        userID: user._id,
    };
    try {
        const response = await instance.post('/api/auth/resendEmail', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function requestPasswordReset(email) {
    const sendData = {
        email: email,
    };
    try {
        const response = await instance.post('/api/auth/requestPasswordReset', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function resetPassword(resetToken, password) {
    const sendData = {
        resetToken: resetToken,
        password: password,
    };
    try {
        const response = await instance.post('/api/auth/resetPassword', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateLanguage(id, language) {
    try {
        await csrf();
        const sendData = {
            id: id,
            language: language,
        };
        const response = await instance.post('/api/auth/updateLanguage', sendData);
        const data = response.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getCurrentUser() {
    try {
        const response = await instance.get('/api/auth/getCurrentUser');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function csrf() {
    try {
        const response = await instance.get('/api/auth/csrf');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}
