import { useState } from 'react';
import Papa from 'papaparse';
import { Button, Input, notification } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { createBulkBots } from '../../services/adminServices';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function Createbotbulk() {
    const translation = useTranslation().admin.createbotinbulk;
    const navigate = useNavigate();
    const defaultSystemMsg = `En tant qu'AI intelligente, vous êtes actuellement intégré à une page pour répondre aux questions d’un responsable d’entreprise, vous disposez d’un entraînement spécifique avec seulement 10 pages de ce même site Web d'entreprise. Votre objectif principal est de répondre de manière précise et pertinente aux questions concernant cette entreprise, ses produits, services, équipe, et toute information disponible dans ces pages.
Cependant, en cas de questions qui dépassent le cadre de ces 10 pages ou pour lesquelles vous n'avez pas assez d'informations, vous devez rappeller que cette version de démonstration est limitée, mais que vous pouvez être entraînée sur d'autres sources de données comme le texte brut, comme des fichiers, des documents, et même du contenu vidéo. Vous devez évoquer le fait que votre intelligence peut être encore plus puissante avec ces données plus complètes. Par exemple tu répondras : “En tant que version de démonstration d’une AI entraînée uniquement sur 10 pages de votre site web, je ne suis pas en mesure de répondre à toutes vos questions, sachez que mon potentiel est illimité dans ma version originale avec un entraînement possible sur : un site web complet, du texte brut, des fichiers, du contenu vidéo…”
Vous montrerez donc aux utilisateurs que vous pouvez apprendre et vous adapter pour devenir plus précis et performant dans le futur.
Si l’utilisateur souhaite que l’on puisse répondre à plus de questions, tu dois indiquer que les formules des IA MR Smith sont disponibles sur ce lien : https://mr-smith.ai/
C'est l'occasion de montrer votre précision actuelle tout en mettant en valeur votre potentiel de croissance et d'amélioration grâce à un entraînement plus complet. Interagissez avec les utilisateurs et démontrez tout le potentiel de votre intelligence !`;
    const [urls, setUrls] = useState([]);
    const [creating, setCreating] = useState(false);
    const [systemMsg, setSystemMsg] = useState(defaultSystemMsg);
    const [csvData, setCSVData] = useState('');
    const [fileName, setFileName] = useState('');

    const handleClick = () => {
        document.getElementById('website_file').click();
    };

    const handleChange = (event) => {
        const files = [...event.target.files];
        files.forEach((file) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    setCSVData(Papa.unparse(results.data));
                    setFileName(files[0].name);
                    if ('website' in results.data[0]) {
                        const websites = results.data.map((r) => r['website']);
                        let temp = [];
                        websites.forEach((website, index) => {
                            let current = urls.length ? urls[urls.length - 1].id + 1 : 1;
                            temp.push({
                                id: index + current,
                                url: website,
                            });
                        });
                        setUrls(temp);
                    }
                },
            });
        });
    };

    const deleteOne = (id) => {
        const updatedList = urls.filter((link) => link.id !== id);
        setUrls(updatedList);
    };

    const handleCreate = async () => {
        setCreating(true);
        if (urls) {
            try {
                await createBulkBots(urls, systemMsg).then((res) => {
                    const data = res.data;
                    const links = data.map((id) => window.location.origin + '/assistant/' + id);
                    const parsedData = Papa.parse(csvData).data.map((row, index) => {
                        if (index < links.length + 1) {
                            if (index === 0) row.push('botlink');
                            else row.push(links[index - 1]);
                        }
                        return row;
                    });
                    const updatedCSVData = Papa.unparse(parsedData);
                    const csvBlob = new Blob([updatedCSVData], {
                        type: 'text/csv;charset=utf-8;',
                    });
                    const csvUrl = URL.createObjectURL(csvBlob);
                    const link = document.createElement('a');
                    link.href = csvUrl;
                    link.setAttribute('download', fileName);
                    link.click();
                    notification.success({
                        message: translation.notification.SUCCESS,
                        placement: 'topRight',
                    });
                    navigate('/botsinbulk');
                });
            } catch (err) {
                notification.error({
                    message: translation.notification.ERROR,
                    placement: 'topRight',
                });
            }
        }
        setCreating(false);
    };

    const deleteAll = () => {
        setUrls([]);
    };

    const handleSystemMsg = (e) => {
        setSystemMsg(e.target.value);
    };

    return (
        <>
            <div className="main-layout card-layout">
                <span style={{ fontSize: 32 }}>{translation.TITLE}</span>
                <div className="width-60 font-weight-normal margin-auto">
                    <p style={{ fontSize: 20, fontWeight: 'normal', float: 'left' }}>{translation.SYSTEM_MSG}</p>
                    <TextArea rows={5} value={systemMsg} onChange={handleSystemMsg} style={{ marginBottom: 12 }} />
                </div>
                <Input
                    type="file"
                    id="website_file"
                    accept=".csv"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                    autoComplete="off"
                />
                {!urls.length ? (
                    <Button
                        type="primary"
                        ghost
                        size="large"
                        className="width-60"
                        icon={<UploadOutlined />}
                        onClick={handleClick}
                        style={{ margin: 32 }}>
                        {translation.UPLOAD_CSV}
                    </Button>
                ) : null}

                <div className="width-60 font-weight-normal margin-auto" id="urlList">
                    <div className="sbtn-widget">
                        {urls.length !== 0 ? (
                            <Button danger type="text" onClick={deleteAll}>
                                {translation.DELETE_ALL}
                            </Button>
                        ) : (
                            <div />
                        )}
                    </div>
                    {urls.map((link) => (
                        <div key={link.id} className="input-button" style={{ margin: '8px 0' }}>
                            <Input
                                placeholder="https://www.example.com/privacy-policy"
                                defaultValue={link.url}
                                onChange={(event) =>
                                    setUrls(
                                        urls.map((item) =>
                                            item.id === link.id ? { ...item, url: event.target.value } : item,
                                        ),
                                    )
                                }
                                autoComplete="off"
                            />
                            <Button danger icon={<DeleteOutlined />} onClick={() => deleteOne(link.id)} type="text" />
                        </div>
                    ))}
                </div>
                <p />
                {urls.length ? (
                    <Button type="primary" size="large" className="width-60" onClick={handleCreate} loading={creating}>
                        {creating ? translation.CREATE_LOADING : translation.CREATE_BOT}
                    </Button>
                ) : null}
            </div>
        </>
    );
}
