import { Layout, Button, Card, Form, Input, notification } from 'antd';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/authServices';
import useTranslation from '../../locales/translations';
const { Content } = Layout;

export default function ResetPassword() {
    const translation = useTranslation().auth;
    const navigate = useNavigate();
    const { resetToken } = useParams();
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true);
        if (formData.password !== formData.confirmPassword) {
            notification.error({
                message: translation.notification.PASSWORD_ERROR,
                placement: 'topRight',
            });
            return;
        }

        try {
            await resetPassword(resetToken, formData.password);
            notification.success({
                message: translation.notification.UPDATE_SUCCESS,
                placement: 'topRight',
            });
            navigate('/signin');
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        }
        setIsSaving(false);
    };

    return (
        <>
            <Layout className="layout-default">
                <Content className="">
                    <Card className="card-signin" title={<h5>{translation.RESET_PASSWORD}</h5>} bordered="false">
                        <Form layout="vertical">
                            <Form.Item
                                label={translation.NEW_PASSWORD}
                                name="password"
                                rules={[{ required: true, message: translation.validation.PASSWORD }]}>
                                <Input
                                    placeholder={translation.PASSWORD}
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                label={translation.CONFIRM_NEW_PASSWORD}
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: translation.validation.CONFIRM_PASSWORD,
                                    },
                                ]}>
                                <Input
                                    placeholder={translation.PASSWORD}
                                    name="confirmPassword"
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button className="width-100" type="primary" onClick={handleSubmit} loading={isSaving}>
                                    {translation.UPDATE_PASSWORD}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}
