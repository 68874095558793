const fr = {
    ERROR: 'Erreur interne du serveur',
    auth: {
        LOGIN: 'Se connecter',
        REGISTER: "S'inscrire",
        RESET_PASSWORD: 'Mettre à jour votre mot de passe',
        WELCOME: 'Bienvenue, ',
        NAME: 'Nom',
        EMAIL: 'Email',
        role: {
            USER_ROLE: "Rôle de l'utilisateur",
            ADMIN: 'Administrateur',
            USER: 'Utilisateur',
        },
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmer le mot de passe',
        NEW_PASSWORD: 'Nouveau mot de passe',
        CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
        AGREE_TERM_HEAD: "J'accepte les ",
        AGREE_TERM_BODY: 'Conditions générales',
        REMEMBER_ME: 'Se souvenir de moi',
        FORGOT_PASSWORD: 'Mot de passe oublié?',
        SIGNIN_UP: 'CONNEXION',
        SIGNIN_LO: 'Se connecter',
        SIGNUP_UP: "S'INSCRIRE",
        SIGNUP_LO: "S'inscrire",
        SIGN_UP_QUESTION: "Vous n'avez pas de compte? ",
        SIGN_IN_QUESTION: 'Vous avez déjà un compte? ',
        UPDATE_PASSWORD: 'Mettre à jour le mot de passe',
        validation: {
            NAME: "Veuillez entrer votre nom d'utilisateur!",
            EMAIL: 'Veuillez entrer une adresse email valide!',
            PASSWORD: 'Veuillez entrer votre mot de passe!',
            CONFIRM_PASSWORD: 'Veuillez confirmer votre mot de passe!',
            AGREE_TERM: 'Veuillez accepter les Conditions générales!',
        },
        alert: {
            SIGNIN_MESSAGE: 'Veuillez vérifier votre compte!',
            SIGNIN_DES_HEADER:
                "Votre compte n'est pas vérifié. Veuillez vérifier votre courrier électronique pour confirmer votre compte. Si vous n'avez pas reçu d'email, veuillez ",
            SIGNIN_DES_BODY: 'renvoyer',
            SIGNUP_MESSAGE: 'Inscription réussie!',
            SIGNUP_DES:
                'Vous avez créé votre compte avec succès. Veuillez vérifier votre courrier électronique pour confirmer votre compte.',
            RESET_PASSWORD_MESSAGE: "Demande d'e-mail pour réinitialiser votre mot de passe envoyé !",
            RESET_PASSWORD_DES:
                "Nous avons envoyé un e-mail à votre adresse e-mail. Suivez les étapes fournies dans l'e-mail pour mettre à jour votre mot de passe.",
            CONFIRM_MESSAGE: 'Vérification réussie!',
            CONFIRM_DES_HEADER: 'Votre compte a été vérifié avec succès. Veuillez ',
            CONFIRM_DES_BODY: 'se connecter',
        },
        notification: {
            AGREE_TERM_ERROR: 'Veuillez accepter les conditions générales.',
            PASSWORD_ERROR: 'Les mots de passe ne correspondent pas! Veuillez les saisir à nouveau.',
            SIGNUP_SUCCESS: 'Inscription réussie! Veuillez vérifier votre courrier électronique.',
            UPDATE_SUCCESS: 'Votre mot de passe a été mis à jour avec succès ! Veuillez vous connecter.',
            RESEND_EMAIL_SUCCESS: 'Succès! Veuillez vérifier votre courrier électronique.',
            ERROR: 'Erreur interne du serveur',
        },
    },
    general: {
        DASHBOARD: 'Tableau de bord',
        AI_ASSISTANTS: 'Assistants AI',
        ADMIN_PAGE: 'Page administrateur',
        PINECONE: 'Pinecone',
        CREATE_BOT_IN_BULK: 'Créer des bots en masse',
        BOTS_IN_BULK: 'Bots en masse',
        DEFAULT_SETTINGS: 'Paramètres par défaut',
        MANAGE_PROMPTS: 'Gérer les prompts',
        PROMPT_LOGS: "Rapports d'invite",
        RESPONSES: 'Réponses',
        ERROR_LOGS: "Rapports d'erreurs",
        USERS: 'Utilisateurs',
        USER_LEVEL: "Niveau de l'utilisateur",
        LOG_OUT: 'Déconnexion',
        FRENCH: 'Français',
        ENGLISH: 'Anglais',
    },
    dashboard: {
        TITLE: 'Assistant AI personnalisé sur vos données',
        DESCRIPTION:
            "Il vous suffit de télécharger vos documents ou d'ajouter un lien vers votre site Web pour obtenir un assistant AI de type ChatGPT entraîné sur vos données.",
        BUILD_ASSISTANT: 'Construisez votre assistant AI personnalisé',
        USAGE: 'Usage',
        ASSISTANTS: 'Assistants',
        MESSAGES: 'Messages',
        subscriptions: {
            TITLE: 'Vos abonnements',
            ACTIVE: 'Actif',
            INCOMPLETE: 'Incomplet',
            FREE_PLAN: 'Forfait gratuit',
            BASIC_PLAN: 'Forfait de base',
            PREMIUM_PLAN: 'Forfait Premiu',
            EXPERT_PLAN: 'Plan expert',
            EXPIRE: 'Expire le: ',
        },
        plan: {
            free: {
                FIRST: '25 messages / mois',
                SECOND: '1 Assistant IA',
                THIRD: '400 000 caractères / Assistant IA',
                FOURTH: 'Intégrer des sites Web illimités',
                FIFTH: "Utiliser du texte brut, un site Web pour l'intégration",
                SIXTH: "Afficher l'historique des conversations",
            },
            basic: {
                FIRST: '2500 messages / mois',
                SECOND: '2 Assistants IA',
                THIRD: '10 000 000 caractères / Assistant IA',
                FOURTH: 'Intégrer des sites Web illimités',
                FIFTH: "Utiliser un fichier, du texte brut, un site Web pour l'intégration",
                SIXTH: 'Télécharger plusieurs fichiers',
                SEVENTH: "Afficher l'historique des conversations",
            },
            premium: {
                FIRST: '12000 messages / mois',
                SECOND: '5 Assistants IA',
                THIRD: '10 000 000 caractères / Assistant IA',
                FOURTH: 'Intégrer des sites Web illimités',
                FIFTH: "Utiliser un fichier, du texte brut, un site Web, une URL YouTube pour l'intégration",
                SIXTH: 'Télécharger plusieurs fichiers',
                SEVENTH: "Afficher l'historique des conversations",
                EIGHTH: 'Option GPT-4',
            },
            expert: {
                FIRST: '50000 messages / mois',
                SECOND: '5 Assistants IA',
                THIRD: '15 000 000 caractères / Assistant IA',
                FOURTH: 'Intégrer des sites Web illimités',
                FIFTH: "Utiliser un fichier, du texte brut, un site Web, une URL YouTube pour l'intégration",
                SIXTH: 'Télécharger plusieurs fichiers',
                SEVENTH: "Afficher l'historique des conversations",
                EIGHTH: 'Option GPT-4',
                NINETH: `Supprimer la marque "Powered by Mr Smith"`,
                TENTH: 'Utilisez vos propres domaines personnalisés',
            },
        },
        subscription_alert: {
            ALERT_A_TITLE: 'Veuillez créer un abonnement!',
            ALERT_A_DESCRIPTION:
                "Veuillez vous abonner à nos plans pour libérer tout le potentiel de cette application et profiter d'une expérience utilisateur améliorée.",
            ALERT_B_TITLE: 'Votre abonnement est incomplet!',
            ALERT_B_DESCRIPTION:
                "Veuillez vous abonner à nos plans pour libérer tout le potentiel de cette application et profiter d'une expérience utilisateur améliorée.",
        },
        CREATE_SUBSCRIPTION: 'Créer un abonnement',
        update: {
            TITLE: 'Mise à niveau et rétrogradation ',
            FREE: 'Gratuit',
            BASIC: 'Basique',
            PREMIUM: 'Premium',
            EXPERT: 'Expert',
        },
        create: {
            TITLE: 'Sources de données',
            INCLUDED_SOURCES: 'Sources incluses :',
            AI_ASSISTANT_NAME: "Nom de l'assistant AI",
            CREATE_AI_ASSISTANT: 'Créer un assistant AI',
            CREATE_LOADING: 'Création en cours...',
            FILES: ' Fichiers',
            FILE_CHARS: ' caractères',
            TEXT_CHARS: " caractères d'entrée de texte",
            LINKS: ' Liens',
            VIDEOS: ' Vidéos',
            ALLOCATED_USERS: 'Utilisateurs alloués',
            files: {
                TITLE: 'Fichiers',
                UPLOAD_FILES: 'Télécharger des fichiers',
                UPLOAD_DESCRIPTION: 'Attachez un fichier pour voir combien de caractères y sont présents',
                FILE_CHARS: ' caractères',
                CLICK_TO_UPLOAD: 'Cliquez pour télécharger',
                NO_FILE_SELECT: 'Aucun fichier sélectionné !',
            },
            text: {
                TITLE: 'Texte brut',
                PLACEHOLDER: 'Données',
                TEXT_CHARS: ' caractères',
            },
            website: {
                TITLE: 'Site Web',
                CRAWL: 'Explorer',
                FETCH_LINKS: 'Obtenir tous les liens',
                FETCH_LOADING: 'Récupération en cours...',
                CRAWL_DESCRIPTION:
                    "Cela explorera tous les liens commençant par l'URL (à l'exception des fichiers sur le site Web).",
                SUBMIT_SITEMAP: 'Soumettre le plan du site',
                PARSE_SITEMAP: 'Charger le plan du site',
                PARSE_LOADING: 'Chargement en cours...',
                INCLUDED_LINKS: 'Liens inclus',
                ADD: 'Ajouter',
                DELETE_ALL: 'Tout supprimer',
                OR: 'OU',
            },
            videos: {
                TITLE: 'Vidéos',
                INCLUDED_LINKS: 'Liens inclus (URL Youtube)',
                PLACEHOLDER: 'Veuillez saisir les URL des vidéos.',
                ADD: 'Ajouter des vidéos',
                DELETE_ALL: 'Tout supprimer',
            },
            notification: {
                NAME_ERROR: 'Veuillez entrer le nom de votre assistant AI.',
                CREATE_SUCCESS: 'Votre assistant AI a été créé avec succès.',
                SOURCE_ERROR: 'Veuillez ajouter vos sources.',
                LIMIT_ERROR_FIRST: 'Vous ne pouvez utiliser que ',
                LIMIT_ERROR_SECOND: ' personnages pour votre assistant.',
            },
        },
        notification: {
            ERROR: 'Erreur interne du serveur',
        },
    },
    chatbot: {
        TITLE: 'Assistants AI',
        CREATE_AI_ASSISTANT: 'Nouvel assistant AI',
        EMPTY_DESCRIPTION: 'Créons dès maintenant votre assistant AI !',
        MY_AI_ASSISTANT: 'Mes assistants AI',
        OTHER_AI_ASSISTANT: 'Autres assistants AI',
        mainwindows: {
            TITLE: 'Assistant AI',
            CHATSOURCE_SITE: 'Site MrSmithAI',
            CHATSOURCE_FRAME: 'Widget ou IFrame',
            INPUT_PLACEHOLDER: 'Saisissez un message.',
            MESSAGE_LEFT: ' messages laissés',
            REFRESH: 'Rafraichir',
            SEND: 'Envoyer',
            notification: {
                FETCH_ANSWER_ERROR:
                    "Une erreur s'est produite lors de la récupération des données. Veuillez réessayer.",
                ERROR: 'Erreur interne du serveur',
            },
        },
        settings: {
            TITLE: 'Paramètres',
            AI_ASSISTANT_ID: "ID de l'assistant AI",
            NAME: 'Nom',
            BASE_PROMPT: 'Message de base (message système)',
            RESET: 'Réinitialiser',
            SELECT_BASE_PROMPT: 'Sélectionner des modèles de message de base',
            TEMPERATURE: 'Température',
            RESERVED: 'Réservé',
            CREATIVE: 'Créatif',
            visibility: {
                TITLE: 'Visibilité',
                PRIVATE: 'Privé',
                PRIVATE_EMBED: 'Privé mais peut être intégré sur un site Web',
                PUBLIC: 'Publique',
                PRIVATE_DES: "'Privé' : Personne d'autre que vous (votre compte) ne peut accéder à votre assistant AI",
                PRIVATE_EMBED_DES:
                    "'Privé, mais peut être intégré sur un site Web' : Les autres personnes ne peuvent pas accéder à votre assistant AI si vous leur envoyez le lien, mais vous pouvez toujours l'intégrer sur votre site Web et les visiteurs de votre site pourront l'utiliser. (assurez-vous de définir vos domaines)",
                PUBLIC_DES:
                    "'Publique' : Toute personne disposant du lien peut y accéder sur app.mrsmith.ai et peut être intégré sur votre site Web.",
                LAST_DES:
                    "Définissez sur public si vous souhaitez pouvoir envoyer un lien de votre assistant AI à quelqu'un pour qu'il l'essaie.",
            },
            domains: {
                TITLE: 'Domaines',
                DES_FIRST: 'Saisissez chaque domaine sur une nouvelle ligne',
                DES_LAST:
                    "Domaines sur lesquels vous souhaitez intégrer votre assistant AI. La visibilité de votre assistant AI doit être 'Publique' ou 'Privé, mais peut être intégré sur un site Web' pour que cela fonctionne.",
            },
            limit: {
                TITLE: 'Limitation de fréquence',
                DESCRIPTION:
                    "Limitez le nombre de messages envoyés à partir d'un seul appareil sur l'iframe et la bulle de discussion (cette limite ne s'appliquera pas à vous sur app.mrsmith.ai, uniquement sur votre site Web pour vos utilisateurs afin d'éviter les abus).",
                DES_FISRT: 'Limite à seulement ',
                DES_MIDDLE: ' messages toutes les ',
                DES_LAST: ' secondes.',
                LIMIT_MESSAGE: 'Afficher ce message lorsque la limite est atteinte',
            },
            YES: 'Oui',
            NO: 'Non',
            ADD_CONTEXT: 'Ajouter un contexte',
            CONTEXT: 'Contexte',
            SELECT_CONTEXT: 'Sélectionner des modèles de contexte',
            REMEMBER_CONVERSATION: 'Mémoriser la conversation',
            CONVERSATION_NUMBER: 'Nombre de conversations à mémoriser',
            ADD_LOGS: 'Ajouter des journaux',
            ADD_SOURCE: 'Ajouter une source',
            SUMMARIZE: 'Résumé du contenu',
            MODEL: 'Modèle',
            OTHRE_PARAMETERS: 'Autres paramètres',
            MAX_TOKENS: 'Nombre maximal de jetons',
            BEST_OF: 'Le meilleur de ',
            TOP_P: 'Top P',
            FREQUENCY_PENALTY: 'Pénalité de fréquence ',
            PRESENCE_PENALTY: 'Pénalité de présence ',
            language: {
                TITLE: 'Langue',
                label: {
                    FRENCH: 'Français',
                    ENGLISH: 'Anglais',
                },
            },
            tone: {
                TITLE: 'Ton ',
                label: {
                    FRIENDLY: 'Amical',
                    PROFESSIONAL: 'Professionnel',
                    SARCASTIC: 'Sarcastique',
                    HUMOROUS: 'Humoristique',
                    CHEERFUL: 'Joyeux',
                    ANECDOTAL: 'Anecdotique',
                },
            },
            profession: {
                TITLE: 'Profession ',
                label: {
                    NONE: 'Aucun',
                    ACCOUNTANT: 'Comptable',
                    ADVERTISING_SPECIALIST: 'Spécialiste de la publicité',
                    ARCHITECT: 'Architecte',
                    ARTIST: 'Artiste',
                    BLOGGER: 'Blogueur',
                    BUSINESS_ANALYST: "Analyste d'affaires",
                    BUSINESS_OWNER: "Propriétaire d'entreprise",
                    CAR_EXPERT: 'Expert en voitures',
                    CONSULTANT: 'Consultant',
                    COUNSELOR: 'Conseiller',
                    CRYPTOCURRENCY_TRADER: 'Trader de cryptomonnaies',
                    CRYPTOCURRENCY_EXPERT: 'Expert en cryptomonnaies',
                    CUSTOMER_SUPPORT: 'Support client',
                    DESIGNER: 'Designer',
                    DIGITAL_MARKETING_AGENCY: 'Agence de marketing numérique',
                    EDITOR: 'Éditeur',
                    EVENT_PLANNER: "Planificateur d'événements",
                    FREELANCER: 'Freelance',
                    INSURANCE_AGENT: "Agent d'assurance",
                    INSURANCE_BROKER: 'Courtier en assurance',
                    INTERIOR_DESIGNER: "Designer d'intérieur",
                    JOURNALIST: 'Journaliste',
                    MARKETING_AGENCY: 'Agence de marketing',
                    MARKETING_EXPERT: 'Expert en marketing',
                    MARKETING_SPECIALIST: 'Spécialiste du marketing',
                    PHOTOGRAPHER: 'Photographe',
                    PROGRAMMER: 'Programmeur',
                    PUBLIC_RELATIONS_AGENCY: 'Agence de relations publiques',
                    PUBLISHER: 'Éditeur',
                    REAL_ESTATE_AGENT: 'Agent immobilier',
                    RECRUITER: 'Recruteur',
                    REPORTER: 'Reporter',
                    SALES_PERSON: 'Commercial',
                    SALES_REPRESENTATIVE: 'Représentant des ventes',
                    SEO_AGENCY: 'Agence de référencement',
                    SEO_EXPERT: 'Expert en référencement',
                    SOCIAL_MEDIA_AGENCY: 'Agence de médias sociaux',
                    STUDENT: 'Étudiant',
                    TEACHER: 'Enseignant',
                    TECHNICAL_SUPPORT: 'Support technique',
                    TRAINER: 'Formateur',
                    TRAVEL_AGENCY: 'Agence de voyage',
                    VIDEOGRAPHER: 'Vidéographe',
                    WEB_DESIGN_AGENCY: 'Agence de conception Web',
                    WEB_DESIGN_EXPERT: 'Expert en conception Web',
                    WEB_DEVELOPMENT_AGENCY: 'Agence de développement Web',
                    WEB_DEVELOPMENT_EXPERT: 'Expert en développement Web',
                    WEB_DESIGNER: 'Designer Web',
                    WEB_DEVELOPER: 'Développeur Web',
                    WRITER: 'Écrivain',
                },
            },
            SAVE_CHANGES: 'Enregistrer les modifications',
            SAVE_LOADING: 'Enregistrement en cours...',
            notification: {
                SUCCESS: 'Les paramètres de votre assistant ont été modifiés avec succès.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        inferface: {
            TITLE: 'Interface',
            MAIN_TITLE: 'Interface de discussion',
            DESCRIPTION: "s'applique lorsqu'il est intégré sur un site Web",
            INITIAL_MESSAGES: 'Message initial',
            RESET: 'Réinitialiser',
            INITIAL_DES: 'Saisissez chaque message sur une nouvelle ligne.',
            SUGGESTED_MESSAGES: 'Messages suggérés',
            SUGGEST_DES: 'Saisissez chaque message sur une nouvelle ligne.',
            SUGGEST_PLACEHOLDER: "Qu'est-ce que example.com ? Comment ça marche ?",
            theme: {
                TITLE: 'Thème',
                LIGHT: 'Clair',
                DARK: 'Sombre',
            },
            PICTURE: "Changer l'image de profil de l'assistant AI (format suggéré : 160x160)",
            PICTURE_UPLOAD: 'Cliquez pour importer votre image',
            REMOVE_PICTURE: "Supprimer l'image de profil de l'assistant AI",
            DISPLAY_NAME: "Nom d'affichage",
            PICTURE_COLOR: "Couleur du message de l'utilisateur",
            PICTURE_DES:
                "**Si les modifications effectuées ici n'apparaissent pas immédiatement sur votre site Web, essayez de vider le cache de votre navigateur ou utilisez le mode incognito. (Les nouveaux utilisateurs verront les modifications immédiatement)**",
            ICON: "Mettre à jour l'icône de discussion (format suggéré : 160x160)",
            ICON_UPLOAD: 'Cliquez pour importer votre image',
            REMOVE_ICON: "Supprimer l'icône de discussion",
            ICON_COLOR: 'Couleur du bouton de bulle de discussion',
            ICON_ALIGN: 'Aligner le bouton de bulle de discussion',
            ALIGN_LEFT: 'Gauche',
            ALIGN_RIGHT: 'Droite',
            HI: 'Salut!',
            INPUT_PLACEHOLDER: 'Saisissez un message.',
            SAVE_CHANGES: 'Enregistrer les modifications',
            SAVE_LOADING: 'Enregistrement en cours...',
            notification: {
                SUCCESS: "Les paramètres de l'interface ont été modifiés avec succès.",
                ERROR: 'Erreur interne du serveur',
            },
        },
        conversation: {
            TITLE: 'Historique',
            CUSTOMER: 'Client : ',
            BOT: 'Assistant AI : ',
            CHATSOURCE: 'Source de discussion : ',
            ACCURACY_SCORE: 'Score de précision : ',
        },
        managesource: {
            TITLE: 'Gérer les sources',
            INCLUDED_SOURCES: 'Sources incluses :',
            ALREADY_INCLUDED_SOURCES: 'Fichiers déjà inclus :',
            AI_ASSISTANT_NAME: "Nom de l'assistant AI",
            RETRAIN_AI_ASSISTANT: "Réentraîner l'assistant AI",
            RETRAIN_LOADING: 'Réentraînement en cours...',
            FILES: ' Fichiers',
            FILE_CHARS: ' caractères',
            TEXT_CHARS: ' caractères de texte',
            LINKS: ' Liens',
            VIDEOS: ' Vidéos',
            ALLOCATED_USERS: 'Utilisateurs alloués',
            files: {
                TITLE: 'Fichiers',
                UPLOAD_FILES: 'Uploader des fichiers',
                UPLOAD_DESCRIPTION: "Importer un fichier pour voir le nombre de caractères qu'il contient",
                FILE_CHARS: ' caractères',
                CLICK_TO_UPLOAD: 'Cliquez pour uploader',
                NO_FILE_SELECT: 'Aucun fichier sélectionné !',
            },
            text: {
                TITLE: 'Texte brut',
                PLACEHOLDER: 'Données',
                TEXT_CHARS: ' caractères',
            },
            website: {
                TITLE: 'Site Web',
                CRAWL: 'Crawler',
                FETCH_LINKS: 'Obtenir tous les liens',
                FETCH_LOADING: 'Récupération en cours...',
                CRAWL_DESCRIPTION:
                    "Cela permettra de crawler tous les liens commençant par l'URL (sans inclure les fichiers du site Web).",
                SUBMIT_SITEMAP: 'Soumettre un sitemap',
                PARSE_SITEMAP: 'Charger le sitemap',
                PARSE_LOADING: 'Chargement en cours...',
                INCLUDED_LINKS: 'Liens inclus',
                ADD: 'Ajouter',
                DELETE_ALL: 'Tout supprimer',
                OR: 'OU',
            },
            videos: {
                TITLE: 'Vidéos',
                INCLUDED_LINKS: 'Liens inclus (URLs Youtube)',
                ADD: 'Ajouter',
                DELETE_ALL: 'Tout supprimer',
            },
            notification: {
                NAME_ERROR: "Veuillez saisir un nom d'assistant AI.",
                CREATE_SUCCESS: "L'assistant AI a été réentraîné avec succès.",
                SOURCE_ERROR: 'Veuillez ajouter vos sources.',
                LIMIT_ERROR_FIRST: 'Vous ne pouvez utiliser que ',
                LIMIT_ERROR_SECOND: ' personnages pour votre assistant.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        embed: {
            TITLE: 'Intégration',
            firstmodal: {
                TITLE: 'Ajouter des domaines',
                DESCRIPTION:
                    'Pour intégrer cet assistant IA, veuillez ajouter le domaine du site Web (par exemple : www.example.com). Veuillez saisir chaque domaine sur une nouvelle ligne.',
                ADD: 'Ajouter',
            },
            secondmodal: {
                TITLE: 'Intégration',
                FIRST_DES:
                    "Pour ajouter l'assistant AI n'importe où sur votre site Web, ajoutez ce script à votre code HTML.",
                SECOND_DES:
                    'Pour ajouter une bulle de discussion en bas à droite de votre site Web, ajoutez cette balise de script à votre code HTML.',
            },
        },
        delete: {
            TITLE: "Supprimer l'assistant AI",
            CONTENT: 'Êtes-vous sûr de vouloir supprimer votre assistant AI ? Cette action est irréversible.',
            OKTEXT: 'Supprimer',
            CANCELTEXT: 'Annuler',
            notification: {
                SUCCESS: 'Suppression réussie.',
                ERROR: 'Erreur interne du serveur',
            },
        },
    },
    admin: {
        pinecone: {
            TITLE: 'Pinecone',
            ID: 'ID',
            NAMESPACE: 'Espace de noms',
            USER: 'Utilisateur',
            SOURCES: 'Sources',
            NAME: 'Nom',
            ACTION: 'Action',
            FILES: 'Fichiers',
            TEXT: 'Texte brut',
            CHARACTERS: 'Caractères',
            WEBSITE: 'site Web',
            LINKS: 'Liens',
            VIDEOS: 'Vidéos',
            detail: {
                ID: 'ID',
                TYPE: 'Taper',
                SOURCE: 'Source',
                DATA: 'Données',
                LAST_UPDATED: 'Dernière mise à jour',
                ACTION: 'Action',
            },
            EDIT_TITLE: 'Modifier les données',
            EDIT_OKTEXT: 'Enregistrer les modifications',
            EDIT_CANCELTEXT: 'Annuler',
            DELETE_TITLE: 'Supprimer',
            DESCRIPTION: 'Êtes-vous sûr de vouloir supprimer ?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Annuler',
            notification: {
                EDIT_SUCCESS: 'Les données ont été modifiées avec succès.',
                DELETE_SUCCESS: 'Suppression réussie.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        createbotinbulk: {
            TITLE: 'Veuillez ajouter des sites Web pour créer des assistants AI en masse.',
            UPLOAD_CSV: 'Importer CSV',
            DELETE_ALL: 'Tout supprimer',
            CREATE_BOT: 'Créer des assistants AI en masse',
            CREATE_LOADING: 'Création en cours...',
            SYSTEM_MSG: 'Message système',
            notification: {
                SUCCESS: 'Les assistants AI ont été créés avec succès.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        botsinbulk: {
            TITLE: 'Bots en masse',
            DESCRIPTION: ' (Les utilisateurs peuvent accéder à ces assistants AI en utilisant cette URL: ',
            SET: 'Définir',
            CALENDLY: 'Votre URL Calendly',
            ID: 'ID',
            BOTID: "ID de l'assistant AI",
            WEBSITE: 'Site Web',
            FREE_ACCESS: 'Accès gratuit',
            ACTION: 'Action',
            DELETE_TITLE: 'Supprimer',
            DELETE_ALL: 'Tout supprimer',
            DELETE_DESCRIPTION: 'Êtes-vous sûr de vouloir supprimer ?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Annuler',
            notification: {
                UPDATE_SUCCESS_MSG: 'Assistant AI mis à jour',
                UPDATE_SUCCESS_DES: "Le statut d'accès gratuit de l'assistant AI a été mis à jour avec succès.",
                UPDATE_ERROR_MSG: 'Erreur',
                UPDATE_ERROR_DES:
                    "Une erreur s'est produite lors de la mise à jour du statut d'accès gratuit de l'assistant AI.",
                DELETE_SUCCESS_MSG: 'Assistant AI supprimé',
                DELETE_SUCCESS_DES: "L'assistant AI a été supprimé avec succès.",
                DELETE_ERROR_MSG: 'Erreur',
                DELETE_ERROR_DES: "Une erreur s'est produite lors de la suppression de l'assistant AI.",
                CALENDLY_SUCCESS_MSG: 'Configuration réussie de votre URL calendly.',
                CALENDLY_ERROR_MSG: 'Erreur lors de la configuration de calendly.',
            },
        },
        defaultsettings: {
            TITLE: 'Définir tous les paramètres par défaut',
            settings: {
                TITLE: "Paramètres de l'assistant AI",
                AI_ASSISTANT_ID: "ID de l'assistant AI",
                NAME: 'Nom',
                BASE_PROMPT: 'Message de base (message système)',
                RESET: 'Réinitialiser',
                RESET_BASE_PROMPT:
                    "Je veux que vous agissiez comme un document avec lequel j'ai une conversation. Votre nom est 'AI Assistant'. Vous me fournirez des réponses à partir des informations fournies. Si la réponse n'est pas incluse, dites exactement 'Hmm, je suis pas certain.' et arrêtez après cela. Refuser de répondre à toute question ne concernant pas l'information. Ne jamais casser le caractère.",
                SELECT_BASE_PROMPT: 'Sélectionner des modèles de message de base',
                RESET_LIMIT_MSG: 'Trop de messages à la suite.',
                RESET_INITIAL_MSG: 'Salut ! En quoi puis-je vous aider ?',
                TEMPERATURE: 'Température',
                RESERVED: 'Réservé',
                CREATIVE: 'Créatif',
                visibility: {
                    TITLE: 'Visibilité',
                    PRIVATE: 'Privé',
                    PRIVATE_EMBED: 'Privé mais peut être intégré sur un site Web',
                    PUBLIC: 'Publique',
                    PRIVATE_DES:
                        "'Privé' : Personne d'autre que vous (votre compte) ne peut accéder à votre assistant AI",
                    PRIVATE_EMBED_DES:
                        "'Privé, mais peut être intégré sur un site Web' : Les autres personnes ne peuvent pas accéder à votre assistant AI si vous leur envoyez le lien, mais vous pouvez toujours l'intégrer sur votre site Web et les visiteurs de votre site pourront l'utiliser. (assurez-vous de définir vos domaines)",
                    PUBLIC_DES:
                        "'Publique' : Toute personne disposant du lien peut y accéder sur app.mrsmith.ai et peut être intégré sur votre site Web.",
                    LAST_DES:
                        "Définissez sur public si vous souhaitez pouvoir envoyer un lien de votre assistant AI à quelqu'un pour qu'il l'essaie.",
                },
                domains: {
                    TITLE: 'Domaines',
                    DES_FIRST: 'Saisissez chaque domaine sur une nouvelle ligne',
                    DES_LAST:
                        "Domaines sur lesquels vous souhaitez intégrer votre assistant AI. La visibilité de votre assistant AI doit être 'Publique' ou 'Privé, mais peut être intégré sur un site Web' pour que cela fonctionne.",
                },
                limit: {
                    TITLE: 'Limitation de fréquence',
                    DESCRIPTION:
                        "Limitez le nombre de messages envoyés à partir d'un seul appareil sur l'iframe et la bulle de discussion (cette limite ne s'appliquera pas à vous sur app.mrsmith.ai, uniquement sur votre site Web pour vos utilisateurs afin d'éviter les abus).",
                    DES_FISRT: 'Limite à seulement ',
                    DES_MIDDLE: ' messages toutes les ',
                    DES_LAST: ' secondes.',
                    LIMIT_MESSAGE: 'Afficher ce message lorsque la limite est atteinte',
                },
                YES: 'Oui',
                NO: 'Non',
                ADD_CONTEXT: 'Ajouter un contexte',
                CONTEXT: 'Contexte',
                SELECT_CONTEXT: 'Sélectionner des modèles de contexte',
                REMEMBER_CONVERSATION: 'Mémoriser la conversation',
                CONVERSATION_NUMBER: 'Nombre de conversations à mémoriser',
                ADD_LOGS: 'Ajouter des journaux',
                ADD_SOURCE: 'Ajouter une source',
                SUMMARIZE: 'Résumé du contenu',
                MODEL: 'Modèle',
                OTHRE_PARAMETERS: 'Autres paramètres',
                MAX_TOKENS: 'Nombre maximal de jetons',
                BEST_OF: 'Le meilleur de ',
                TOP_P: 'Top P',
                FREQUENCY_PENALTY: 'Pénalité de fréquence ',
                PRESENCE_PENALTY: 'Pénalité de présence ',
                language: {
                    TITLE: 'Langue',
                    label: {
                        FRENCH: 'Français',
                        ENGLISH: 'Anglais',
                    },
                },
                tone: {
                    TITLE: 'Ton ',
                    label: {
                        FRIENDLY: 'Amical',
                        PROFESSIONAL: 'Professionnel',
                        SARCASTIC: 'Sarcastique',
                        HUMOROUS: 'Humoristique',
                        CHEERFUL: 'Joyeux',
                        ANECDOTAL: 'Anecdotique',
                    },
                },
                profession: {
                    TITLE: 'Profession ',
                    label: {
                        NONE: 'Aucun',
                        ACCOUNTANT: 'Comptable',
                        ADVERTISING_SPECIALIST: 'Spécialiste de la publicité',
                        ARCHITECT: 'Architecte',
                        ARTIST: 'Artiste',
                        BLOGGER: 'Blogueur',
                        BUSINESS_ANALYST: "Analyste d'affaires",
                        BUSINESS_OWNER: "Propriétaire d'entreprise",
                        CAR_EXPERT: 'Expert en voitures',
                        CONSULTANT: 'Consultant',
                        COUNSELOR: 'Conseiller',
                        CRYPTOCURRENCY_TRADER: 'Trader de cryptomonnaies',
                        CRYPTOCURRENCY_EXPERT: 'Expert en cryptomonnaies',
                        CUSTOMER_SUPPORT: 'Support client',
                        DESIGNER: 'Designer',
                        DIGITAL_MARKETING_AGENCY: 'Agence de marketing numérique',
                        EDITOR: 'Éditeur',
                        EVENT_PLANNER: "Planificateur d'événements",
                        FREELANCER: 'Freelance',
                        INSURANCE_AGENT: "Agent d'assurance",
                        INSURANCE_BROKER: 'Courtier en assurance',
                        INTERIOR_DESIGNER: "Designer d'intérieur",
                        JOURNALIST: 'Journaliste',
                        MARKETING_AGENCY: 'Agence de marketing',
                        MARKETING_EXPERT: 'Expert en marketing',
                        MARKETING_SPECIALIST: 'Spécialiste du marketing',
                        PHOTOGRAPHER: 'Photographe',
                        PROGRAMMER: 'Programmeur',
                        PUBLIC_RELATIONS_AGENCY: 'Agence de relations publiques',
                        PUBLISHER: 'Éditeur',
                        REAL_ESTATE_AGENT: 'Agent immobilier',
                        RECRUITER: 'Recruteur',
                        REPORTER: 'Reporter',
                        SALES_PERSON: 'Commercial',
                        SALES_REPRESENTATIVE: 'Représentant des ventes',
                        SEO_AGENCY: 'Agence de référencement',
                        SEO_EXPERT: 'Expert en référencement',
                        SOCIAL_MEDIA_AGENCY: 'Agence de médias sociaux',
                        STUDENT: 'Étudiant',
                        TEACHER: 'Enseignant',
                        TECHNICAL_SUPPORT: 'Support technique',
                        TRAINER: 'Formateur',
                        TRAVEL_AGENCY: 'Agence de voyage',
                        VIDEOGRAPHER: 'Vidéographe',
                        WEB_DESIGN_AGENCY: 'Agence de conception Web',
                        WEB_DESIGN_EXPERT: 'Expert en conception Web',
                        WEB_DEVELOPMENT_AGENCY: 'Agence de développement Web',
                        WEB_DEVELOPMENT_EXPERT: 'Expert en développement Web',
                        WEB_DESIGNER: 'Designer Web',
                        WEB_DEVELOPER: 'Développeur Web',
                        WRITER: 'Écrivain',
                    },
                },
                SAVE_CHANGES: 'Enregistrer les modifications',
                SAVE_LOADING: 'Enregistrement en cours...',
                notification: {
                    SUCCESS: 'Les paramètres de votre assistant ont été modifiés avec succès.',
                    ERROR: 'Erreur interne du serveur',
                },
            },
            inferface: {
                TITLE: 'Interface de chat',
                DESCRIPTION: "s'applique lorsqu'il est intégré sur un site Web",
                INITIAL_MESSAGES: 'Message initial',
                RESET: 'Réinitialiser',
                INITIAL_DES: 'Entrez chaque message dans une nouvelle ligne.',
                SUGGESTED_MESSAGES: 'Messages suggérés',
                SUGGEST_DES: 'Entrez chaque message dans une nouvelle ligne.',
                SUGGEST_PLACEHOLDER: "Qu'est-ce que example.com ? Comment ça marche ?",
                theme: {
                    TITLE: 'Thème',
                    LIGHT: 'Clair',
                    DARK: 'Sombre',
                },
                PICTURE: "Changer l'image de profil de l'assistant AI (format suggéré : 160x160)",
                PICTURE_UPLOAD: 'Cliquez pour uploader',
                REMOVE_PICTURE: "Supprimer l'image de profil de l'assistant AI",
                DISPLAY_NAME: "Nom d'affichage",
                PICTURE_COLOR: 'Couleur du message utilisateur',
                PICTURE_DES:
                    "**Si les modifications apportées ici n'apparaissent pas immédiatement sur votre site Web, essayez de vider le cache de votre navigateur ou utilisez le mode incognito. (Les nouveaux utilisateurs verront les modifications immédiatement)**",
                ICON: "Mettre à jour l'icône de chat",
                ICON_UPLOAD: 'Cliquez pour uploader',
                REMOVE_ICON: "Supprimer l'icône de chat",
                ICON_COLOR: 'Couleur du bouton de bulle de chat',
                ICON_ALIGN: 'Alignement du bouton de bulle de chat',
                ALIGN_LEFT: 'Gauche',
                ALIGN_RIGHT: 'Droite',
                HI: 'Salut !',
                INPUT_PLACEHOLDER: 'Saisissez un message.',
                SAVE_CHANGES: "Enregistrer les modifications de l'interface",
                SAVE_LOADING: 'Enregistrement en cours...',
                notification: {
                    SUCCESS: "Les paramètres de l'interface ont été modifiés avec succès.",
                    ERROR: 'Erreur interne du serveur',
                },
            },
        },
        manageprompts: {
            TITLE: 'Gérer les prompts',
            baseprompt: {
                TITLE: 'Prompts de base',
                ADD_TEMPLATE: 'Ajouter un modèle',
                SAVE_TEMPLATES: 'Enregistrer les modèles',
                TEMPLATES_SAVING: 'Enregistrement en cours...',
                notification: {
                    SUCCESS: "Les modèles d'invite de base ont été enregistrés avec succès.",
                    ERROR: 'Erreur interne du serveur',
                },
            },
            context: {
                TITLE: 'Contextes',
                ADD_TEMPLATE: 'Ajouter un modèle',
                SAVE_TEMPLATES: 'Enregistrer les modèles',
                TEMPLATES_SAVING: 'Enregistrement en cours...',
                notification: {
                    SUCCESS: 'Les modèles de contexte ont été enregistrés avec succès.',
                    ERROR: 'Erreur interne du serveur',
                },
            },
        },
        errors: {
            TITLE: "Rapports d'erreur",
            ID: 'ID',
            URL: "URL de l'erreur",
            CODE: "Code d'erreur",
            MESSAGE: "Message d'erreur",
            STATUS: "Statut d'erreur",
            STATUSTEXT: "Statut d'erreur en texte",
            CREATEDAT: 'Créé le',
            ACTION: 'Action',
            ERROR_INFO: "Informations sur l'erreur",
            DELETE_TITLE: 'Supprimer',
            DELETE_ALL: 'Tout supprimer',
            DELETE_DESCRIPTION: 'Êtes-vous sûr de vouloir supprimer?',
            DELETE_OKTEXT: 'OK',
            DELETE_CANCELTEXT: 'Annuler',
            notification: {
                SUCCESS_MSG: 'Erreur résolue',
                SUCCESS_DES: "L'erreur a été résolue avec succès.",
                ERROR_MSG: 'Erreur',
                ERROR_DES: "Une erreur s'est produite, réessayez.",
            },
        },
        users: {
            TITLE: 'Utilisateurs',
            SHOW_SIGNUP: "Afficher l'enregistrement",
            ADMIN_TITLE: 'Administrateurs',
            USER_TITLE: 'Utilisateurs',
            ID: 'ID',
            NAME: 'Nom',
            EMAIL: 'Email',
            VERIFIABLE: 'Vérifiable',
            BOTLIMIT: 'Limite de bots',
            QUERYLIMIT: 'Limite de requêtes',
            CHARACTERLIMIT: 'Limite de caractères',
            ASSISTANT_LIMIT: " limite d'assistance",
            QUERY_LIMIT: ' limite de requête',
            ACTIVE: 'Actif',
            INCOMPLETE: 'Incomplet',
            EXPIRE: 'Expire le: ',
            level: {
                TITLE: 'Niveau',
                BASIC: 'Basique',
                PREMIUM: 'Premium',
                EXPERT: 'Expert',
            },
            ACTION: 'Action',
            VERIFIED: 'Vérifié',
            NOT_VERIFIED: 'Non vérifié',
            FILES_DES: 'Fichiers, ',
            TEXT_DES: 'Texte brut',
            WEBSITE_DES: ', site Web',
            VIDEO_DES: ', Vidéos',
            NEW_ADMIN: 'Nouvel administrateur',
            new_admin: {
                TITLE: 'Ajouter un nouvel administrateur',
                NAME: 'Nom',
                EMAIL: 'Email',
                PASSWORD: 'Mot de passe',
                CONFIRM_PASSWORD: 'Confirmer le mot de passe',
                ADD_ADMIN: 'Ajouter un administrateur',
                CANCEL: 'Annuler',
            },
            update_admin: {
                TITLE: "Modifier l'administrateur",
                NAME: 'Nom',
                EMAIL: 'Email',
                UPDATE_ADMIN: "Mettre à jour l'administrateur",
                CANCEL: 'Annuler',
            },
            NEW_USER: 'Nouvel utilisateur',
            new_user: {
                TITLE: 'Ajouter un nouvel utilisateur',
                NAME: 'Nom',
                EMAIL: 'Email',
                VALIDATE: 'Validation par e-mail',
                PASSWORD: 'Mot de passe',
                CONFIRM_PASSWORD: 'Confirmer le mot de passe',
                LEVEL: 'Niveau',
                BOTLIMIT: 'Limite de bots',
                QUERYLIMIT: 'Limite de requêtes',
                CHARACTERLIMIT: 'Limite de caractères',
                DATA_FORMAT: 'Format des données',
                FILES: 'Fichiers',
                WEBSITE: 'Site Web',
                VIDEOS: 'Vidéos',
                ADD_USER: 'Ajouter un utilisateur',
                CANCEL: 'Annuler',
            },
            update_user: {
                TITLE: "Modifier l'utilisateur",
                NAME: 'Nom',
                EMAIL: 'Email',
                LEVEL: 'Niveau',
                BOTLIMIT: 'Limite de bots',
                QUERYLIMIT: 'Limite de requêtes',
                CHARACTERLIMIT: 'Limite de caractères',
                DATA_FORMAT: 'Format des données',
                FILES: 'Fichiers',
                WEBSITE: 'Site Web',
                VIDEOS: 'Vidéos',
                UPDATE_USER: "Mettre à jour l'utilisateur",
                CANCEL: 'Annuler',
            },
            delete: {
                TITLE: 'Supprimer',
                DESCRIPTION: 'Êtes-vous sûr de vouloir supprimer ?',
                OK: 'OK',
                CANCEL: 'Annuler',
            },
            validation: {
                NAME: "Veuillez entrer votre nom d'utilisateur!",
                EMAIL: 'Veuillez entrer une adresse email valide!',
                PASSWORD: 'Veuillez entrer votre mot de passe!',
                CONFIRM_PASSWORD: 'Veuillez confirmer votre mot de passe!',
                LEVEL: 'Veuillez sélectionner le niveau !',
                BOTLIMIT: 'Veuillez saisir botlimit !',
                QUERYLIMIT: 'Veuillez saisir la limite de requête !',
                CHARACTERLIMIT: 'Veuillez saisir une limite de caractères !',
            },
            notification: {
                ADD_ADMIN_SUCCESS: 'Le nouvel administrateur a été créé avec succès.',
                UPDATE_ADMIN_SUCCESS: "L'administrateur a été mis à jour avec succès.",
                ADD_USER_SUCCESS: 'Le nouvel utilisateur a été créé avec succès.',
                UPDATE_USER_SUCCESS: "L'utilisateur a été mis à jour avec succès.",
                DELETE_SUCCESS: 'Supprimé avec succès.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        levels: {
            TITLE: "Niveau de l'utilisateur",
            ID: 'ID',
            NAME: 'Nom',
            BOTLIMIT: 'Limite de bots',
            QUERYLIMIT: 'Limite de requêtes',
            CHARACTERLIMIT: 'Limite de caractères',
            DATASOURCE: 'La source de données',
            ACTION: 'Action',
            FILES: 'Fichiers',
            TEXT: 'Texte brut',
            WEBSITE: 'site Web',
            VIDEOS: 'Vidéos',
            ADD_LEVEL: 'Ajouter un niveau',
            EDIT_LEVEL: 'Modifier le niveau',
            DELETE_TITLE: 'Supprimer',
            DELETE_DESCRIPTION: 'Êtes-vous sûr de vouloir supprimer ?',
            OK: 'OK',
            CANCEL: 'Annuler',
            GPT4: 'GPT-4',
            REMOVE_BRANDING: 'Supprimer la marque',
            REMOVE: 'Supprimer ',
            TRUE: 'Vrai',
            FALSE: 'Faux',
            YES: 'Oui',
            NO: 'Non',
            validation: {
                NAME: 'Veuillez saisir le nom du niveau !',
                BOTLIMIT: 'Veuillez saisir botlimit !',
                QUERYLIMIT: 'Veuillez saisir la limite de requête !',
                CHARACTERLIMIT: 'Veuillez saisir une limite de caractères !',
            },
            notification: {
                ADD_SUCCESS: 'Le nouveau niveau est créé avec succès.',
                UPDATE_SUCCESS: 'Le niveau est mis à jour avec succès.',
                DELETE_SUCCESS: 'Supprimé avec succès.',
                ERROR: 'Erreur interne du serveur',
            },
        },
        prompt_logs: {
            TITLE: 'Historiques de consigne',
            ID: 'ID',
            CHATID: 'Identifiant de chat',
            BOTNAME: 'Nom du Bot',
            USER: 'Utilisateur',
            CHATSOURCE: 'Source de discussion',
            UPDATEDAT: 'Date de journalisation',
            LOG_INFO: 'Informations de journal',
            CUSTOMER: 'Utilisateur : ',
            AI_ASSISTANT: 'Assistant AI : ',
            DURATION_TEXT01: 'Réglage Initial : ',
            DURATION_TEXT02: 'Créez des vecteurs à rechercher : ',
            DURATION_TEXT03: 'Obtenez une réponse pertinente : ',
            DURATION_TEXT04: 'Obtenir le résumé : ',
            DURATION_TEXT05: 'Obtenez la réponse finale : ',
            TOTAL_DURATION: 'Durée totale: ',
        },
        responses: {
            TITLE: 'Analyse des réponses',
            ID: 'ID',
            BOTID: 'BotID',
            NAME: 'Nom',
            USER: 'Utilisateur',
            UPDATEDAT: 'Mise à jour à',
            CREATEDAT: 'Créé le',
            NODATA: 'Aucune donnée',
            AVERAGE_RESPONSE: 'Réponse moyenne :',
            EXPORT_TO_CSV: 'Exporter vers CSV',
            PERFORMANCE: 'Performance(secondes)',
            RESPONSE_DURATION: 'Durée de réponse',
            DATETIME: 'Date / Heure',
            PROMPT: 'Consigne',
            RESPONSE: 'Réponse',
            DURATION: 'Durée',
            DURATION_TEXT01: 'Réglage Initial : ',
            DURATION_TEXT02: 'Créez des vecteurs à rechercher : ',
            DURATION_TEXT03: 'Obtenez une réponse pertinente : ',
            DURATION_TEXT04: 'Obtenir le résumé : ',
            DURATION_TEXT05: 'Obtenez la réponse finale : ',
            TOTAL_DURATION: 'Durée totale: ',
        },
        assistant: {
            CHATSOURCE_ASSIST: 'Bots en masse',
            ERROR_MSG: 'Vous ne pouvez envoyer que 10 messages sur cette version.',
            AI_ASSISTANT: 'Mon assistant AI entraîné sur MON entreprise',
            INPUT_PLACEHOLDER: 'Posez une question sur votre entreprise !',
            ACCESS_ERROR_MSG: 'Vous ne pouvez pas accéder librement à cet assistant AI.',
            FIRST_TEXT:
                "🤖 Découvrez le futur de l'AI avec Mr Smith : Testez gratuitement votre assistant virtuel ! 🚀",
            SECOND_TEXT: "Votre propre assistant AI est prêt à révolutionner votre façon d'interagir avec vos clients.",
            THIRD_TEXT:
                '(Cette page n’est qu’un aperçu du potentiel extraordinaire que l’AI de Mr Smith peut offrir dans une version de démonstration entraînée sur ',
            FOURTH_TEXT:
                "Testez dès aujourd'hui l'efficacité de l’AI Mr Smith entraînée sur 10 pages de votre propre site web !",
            FIFTH_TEXT: 'Le futur de votre entreprise commence ici, avec Mr Smith ! ',
            SIXTH_TEXT: 'Demandez votre assistant AI dès maintenant',
            HELLO_HEAD: '👋Bonjour! Je suis une AI formée sur ',
            HELLO_END: ', vous pouvez me poser vos questions !',
            CALENDLY_BUTTON_TEXT:
                'Réservez sans tarder un appel avec nos experts en Intelligence Artificielle pour former votre nouvel assistant!',
            SEND: 'Envoyer le message',
        },
    },
    loadingtext: {
        first: [
            "AI Assistant apprend et s'adapte pour vous offrir la meilleure expérience.",
            "Veuillez patienter pendant que l'AI Assistant analyse les données pour améliorer ses connaissances.",
            "En cours de formation, l'AI Assistant travaille dur pour améliorer ses capacités.",
            "Formation en cours : l'AI Assistant acquiert de nouvelles compétences et élargit ses connaissances.",
            "Asseyez-vous bien ! L'Assistant AI est en cours de formation pour mieux vous comprendre et vous aider.",
        ],
        second: 'AI Assistant apprend...',
    },
};

export default fr;
