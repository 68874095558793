import { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Divider } from 'antd';
import Chart from 'react-apexcharts';
import { CSVLink } from 'react-csv';
import { getResponses, getChatbots } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function Responses() {
    const translation = useTranslation().admin.responses;
    const [bots, setBots] = useState([]);
    const [responses, setResponses] = useState([]);
    const [csvData, setcsvData] = useState([]);
    const [xaxis, setXaxis] = useState([]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);

    const durationsText = ['duration01', 'duration02', 'duration03', 'duration04', 'duration05'];

    const fetchResponse = async (botId) => {
        try {
            const res = await getResponses(botId);
            return res.data;
        } catch (err) {
            throw err;
        }
    };

    const fetchBots = async () => {
        try {
            setLoading(true);
            const res = await getChatbots();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            const responseTemp = await Promise.all(temp.map((data) => fetchResponse(data.key)));
            setBots(temp);
            setResponses(responseTemp);
            let csvTemp = [];
            let averageTemp = [];
            let xaxisTemp = [];
            if (responseTemp.length) {
                responseTemp.map((response) => {
                    if (response.length) {
                        const restemp = response.map((item) => {
                            return {
                                botId: item.botId,
                                botName: item.botName,
                                userEmail: item.userEmail,
                                prompt: item.prompt,
                                response: item.response,
                                duration: `${translation.DURATION_TEXT01}${(item.duration[0].duration01 / 1000).toFixed(
                                    1,
                                )}s\n
      ${translation.DURATION_TEXT02}${(item.duration[0].duration02 / 1000).toFixed(1)}s\n
      ${translation.DURATION_TEXT03}${(item.duration[0].duration03 / 1000).toFixed(1)}s\n
      ${translation.DURATION_TEXT04}${(item.duration[0].duration04 / 1000).toFixed(1)}s\n
      ${translation.DURATION_TEXT05}${(item.duration[0].duration05 / 1000).toFixed(1)}s\n
      ${translation.TOTAL_DURATION}${(item.duration[0].total_duration / 1000).toFixed(1)}s\n`,
                                createdAt: new Date(item.createdAt).toLocaleString(),
                            };
                        });
                        csvTemp = csvTemp.concat(restemp);
                        const avgtemp = durationsText.map(
                            (duration) =>
                                response.reduce((total, data) => total + data.duration[0][duration], 0) /
                                response.length,
                        );
                        averageTemp.push(avgtemp);
                        xaxisTemp.push(`${response[0].botId}`);
                    }
                });
            }
            const seriesTemp = [
                {
                    name: translation.DURATION_TEXT01,
                    data: averageTemp.map((item) => (item ? item[0] / 1000 : 0).toFixed(1)),
                },
                {
                    name: translation.DURATION_TEXT02,
                    data: averageTemp.map((item) => (item ? item[1] / 1000 : 0).toFixed(1)),
                },
                {
                    name: translation.DURATION_TEXT03,
                    data: averageTemp.map((item) => (item ? item[2] / 1000 : 0).toFixed(1)),
                },
                {
                    name: translation.DURATION_TEXT04,
                    data: averageTemp.map((item) => (item ? item[3] / 1000 : 0).toFixed(1)),
                },
                {
                    name: translation.DURATION_TEXT05,
                    data: averageTemp.map((item) => (item ? item[4] / 1000 : 0).toFixed(1)),
                },
            ];
            setcsvData(csvTemp);
            setXaxis(xaxisTemp);
            setSeries(seriesTemp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchBots();
    }, []);

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.BOTID,
            dataIndex: 'botId',
            key: 'botId',
            width: 240,
            render: (text, record) => record._id,
        },
        {
            title: translation.NAME,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translation.USER,
            dataIndex: 'userEmail',
            key: 'userEmail',
        },
        {
            title: translation.UPDATEDAT,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date) => new Date(date).toLocaleString(),
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        dataLabels: {
            enabled: false,
        },
        series: series.length ? series : [{ data: [{ x: '', y: 0 }] }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900,
                        },
                        formatter: function (val) {
                            return val.toFixed(3);
                        },
                    },
                },
            },
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: {
            title: {
                text: translation.PERFORMANCE,
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + 's';
                },
            },
        },
    };

    const csvHeaders = [
        { key: 'botId', label: translation.BOTID },
        { key: 'botName', label: translation.NAME },
        { key: 'userEmail', label: translation.USER },
        { key: 'prompt', label: translation.PROMPT },
        { key: 'response', label: translation.RESPONSE },
        { key: 'duration', label: translation.DURATION },
        { key: 'createdAt', label: translation.CREATEDAT },
    ];

    const expandedRowRender = (record, index) => {
        if (record) {
            const subColumns = [
                {
                    title: translation.DATETIME,
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (date) => new Date(date).toLocaleString(),
                },
                {
                    title: translation.PROMPT,
                    dataIndex: 'prompt',
                    key: 'prompt',
                },
                {
                    title: translation.RESPONSE,
                    dataIndex: 'response',
                    key: 'response',
                },
                {
                    title: translation.DURATION,
                    dataIndex: 'duration',
                    key: 'duration',
                    render: (duration) => (
                        <>
                            <p>
                                {translation.DURATION_TEXT01}
                                {duration[0].duration01 / 1000}s
                            </p>
                            <p>
                                {translation.DURATION_TEXT02}
                                {duration[0].duration02 / 1000}s
                            </p>
                            <p>
                                {translation.DURATION_TEXT03}
                                {duration[0].duration03 / 1000}s
                            </p>
                            <p>
                                {translation.DURATION_TEXT04}
                                {duration[0].duration04 / 1000}s
                            </p>
                            <p>
                                {translation.DURATION_TEXT05}
                                {duration[0].duration05 / 1000}s
                            </p>
                            <p>
                                <b>
                                    {translation.TOTAL_DURATION}
                                    {duration[0].total_duration / 1000}s
                                </b>
                            </p>
                        </>
                    ),
                    width: 240,
                },
            ];
            const response = responses[index];
            const subData = response.map((res) => ({
                key: res._id,
                ...res,
            }));

            const averages = durationsText.map(
                (duration) => response.reduce((total, data) => total + data.duration[0][duration], 0) / response.length,
            );

            const total_average =
                response.reduce((total, data) => total + data.duration[0].total_duration, 0) / response.length;

            const suboptions = {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    stacked: true,
                },
                dataLabels: {
                    enabled: false,
                },
                series: [
                    {
                        name: translation.DURATION_TEXT01,
                        data: response.map((res) => (res.duration[0].duration01 / 1000).toFixed(1)),
                    },
                    {
                        name: translation.DURATION_TEXT02,
                        data: response.map((res) => (res.duration[0].duration02 / 1000).toFixed(1)),
                    },
                    {
                        name: translation.DURATION_TEXT03,
                        data: response.map((res) => (res.duration[0].duration03 / 1000).toFixed(1)),
                    },
                    {
                        name: translation.DURATION_TEXT04,
                        data: response.map((res) => (res.duration[0].duration04 / 1000).toFixed(1)),
                    },
                    {
                        name: translation.DURATION_TEXT05,
                        data: response.map((res) => (res.duration[0].duration05 / 1000).toFixed(1)),
                    },
                ],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            total: {
                                enabled: false,
                            },
                        },
                    },
                },
                xaxis: {
                    categories: response.map((res) => `${new Date(res.createdAt).toLocaleString()}`),
                },
                yaxis: {
                    title: {
                        text: translation.PERFORMANCE,
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + 's';
                        },
                    },
                },
            };

            const subcsvData = response.length
                ? response.map((item) => {
                      return {
                          botId: item.botId,
                          botName: item.botName,
                          userEmail: item.userEmail,
                          prompt: item.prompt,
                          response: item.response,
                          duration: `${translation.DURATION_TEXT01}${(item.duration[0].duration01 / 1000).toFixed(1)}s\n
${translation.DURATION_TEXT02}${(item.duration[0].duration02 / 1000).toFixed(1)}s\n
${translation.DURATION_TEXT03}${(item.duration[0].duration03 / 1000).toFixed(1)}s\n
${translation.DURATION_TEXT04}${(item.duration[0].duration04 / 1000).toFixed(1)}s\n
${translation.DURATION_TEXT05}${(item.duration[0].duration05 / 1000).toFixed(1)}s\n
${translation.TOTAL_DURATION}${(item.duration[0].total_duration / 1000).toFixed(1)}s\n`,
                          createdAt: new Date(item.createdAt).toLocaleString(),
                      };
                  })
                : [];

            const subexportDataToCSV = () => {
                document.getElementById(`subcsvexport_${response.length ? response[0].botId : ''}`).click();
            };

            return (
                <>
                    {response.length ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <div>
                                    <h1>{translation.AVERAGE_RESPONSE}</h1>
                                    <Row gutter={[16, 16]}>
                                        <Col>
                                            <span>
                                                {translation.DURATION_TEXT01}
                                                {(averages[0] / 1000).toFixed(1)}s
                                            </span>
                                        </Col>
                                        <Col>
                                            <span>
                                                {translation.DURATION_TEXT02}
                                                {(averages[1] / 1000).toFixed(1)}s
                                            </span>
                                        </Col>
                                        <Col>
                                            <span>
                                                {translation.DURATION_TEXT03}
                                                {(averages[2] / 1000).toFixed(1)}s
                                            </span>
                                        </Col>
                                        <Col>
                                            <span>
                                                {translation.DURATION_TEXT04}
                                                {(averages[3] / 1000).toFixed(1)}s
                                            </span>
                                        </Col>
                                        <Col>
                                            <span>
                                                {translation.DURATION_TEXT05}
                                                {(averages[4] / 1000).toFixed(1)}s
                                            </span>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Row>
                                        <span>
                                            {translation.TOTAL_DURATION}
                                            {(total_average / 1000).toFixed(1)}s
                                        </span>
                                    </Row>
                                </div>
                                <div>
                                    <CSVLink
                                        id={`subcsvexport_${response[0].botId}`}
                                        style={{ display: 'none' }}
                                        data={subcsvData}
                                        headers={csvHeaders}
                                        filename={`${response[0].botId}_chat_analytics.csv`}
                                    />
                                    <Button type="primary" onClick={subexportDataToCSV}>
                                        {translation.EXPORT_TO_CSV}
                                    </Button>
                                </div>
                            </div>
                            <Chart options={suboptions} series={suboptions.series} type="bar" height={350} />
                            <Table
                                columns={subColumns}
                                dataSource={subData}
                                bordered
                                scroll={{
                                    x: 700,
                                }}></Table>
                        </>
                    ) : (
                        <>
                            <h1>{translation.NODATA}</h1>
                        </>
                    )}
                </>
            );
        }
    };

    const exportDataToCSV = () => {
        document.getElementById('csvexport').click();
    };

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700, margin: 12 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <span>{translation.TITLE}</span>
                        {csvData.length ? (
                            <div>
                                <CSVLink
                                    id="csvexport"
                                    style={{ display: 'none' }}
                                    data={csvData}
                                    headers={csvHeaders}
                                    filename="chat_analytics.csv"
                                />
                                <Button type="primary" onClick={exportDataToCSV}>
                                    {translation.EXPORT_TO_CSV}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
                {series.length ? (
                    <Chart options={options} series={options ? options.series : []} type="bar" height={350} />
                ) : null}
                <Table
                    columns={columns}
                    dataSource={bots}
                    bordered
                    expandable={{ expandedRowRender }}
                    scroll={{
                        x: 700,
                    }}
                />
            </div>
        </>
    );
}
