import axios from 'axios';
import { addError } from './adminServices';
import { csrf } from './authServices';

const instance = axios.create();

export async function uploads(formData) {
    try {
        const response = await instance.post('/api/chatbot/uploads', formData);
        const data = response.data.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function pictureuploads(formData) {
    try {
        const response = await instance.post('/api/chatbot/pictureuploads', formData);
        const data = response.data.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function crawl(url) {
    try {
        const sendData = {
            URL: url,
        };
        const response = await instance.post('/api/chatbot/crawl', sendData);
        const data = response.data.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function loadSiteMap(url) {
    try {
        const sendData = {
            URL: url,
        };
        const response = await instance.post('/api/chatbot/sitemap', sendData);
        const data = response.data.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function createChatbot(name, files, content, links, videos, allocatedUsers) {
    try {
        await csrf();
        const sendData = {
            name: name,
            files: files,
            content: content,
            links: links,
            videos: videos,
            allocatedUsers: allocatedUsers,
        };
        const response = await instance.post('/api/chatbot/createChatbot', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getChatbots() {
    try {
        const response = await instance.get('/api/chatbot/getChatbots');
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getChatbot(botId) {
    try {
        const response = await instance.get(`/api/chatbot/getChatbot/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function retrainChatbot(botId, name, files, content, links, videos, allocatedUsers) {
    try {
        await csrf();
        const sendData = {
            botId: botId,
            name: name,
            files: files,
            content: content,
            links: links,
            videos: videos,
            allocatedUsers: allocatedUsers,
        };
        const response = await instance.post('/api/chatbot/retrainChatbot', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getInterface(botId) {
    try {
        const response = await instance.get(`/api/chatbot/getInterface/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateInterface(
    botId,
    initialMsg,
    suggestMsg,
    theme,
    picture,
    removePicture,
    displayName,
    msgColor,
    icon,
    removeIcon,
    buttonColor,
    buttonAlign,
) {
    try {
        await csrf();
        const sendData = {
            botId: botId,
            initialMsg: initialMsg,
            suggestMsg: suggestMsg,
            theme: theme,
            picture: picture,
            removePicture: removePicture,
            displayName: displayName,
            msgColor: msgColor,
            icon: icon,
            removeIcon: removeIcon,
            buttonColor: buttonColor,
            buttonAlign: buttonAlign,
        };
        const response = await instance.post('/api/chatbot/updateInterface', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getSetting(botId) {
    try {
        const response = await instance.get(`/api/chatbot/getSetting/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function updateBotSettings(
    botId,
    name,
    baseprompt,
    temperature,
    visibility,
    domains,
    limit,
    limittime,
    limitmsg,
    addContext,
    context,
    rememberconv,
    numberconv,
    addlogs,
    addsource,
    summarize,
    model,
    maxTokens,
    topP,
    bestOf,
    frequency,
    presence,
    language,
    tone,
    profession,
) {
    try {
        await csrf();
        const sendData = {
            botId,
            name,
            baseprompt,
            temperature,
            visibility,
            domains,
            limit,
            limittime,
            limitmsg,
            addContext,
            context,
            rememberconv,
            numberconv,
            addlogs,
            addsource,
            summarize,
            model,
            maxTokens,
            topP,
            bestOf,
            frequency,
            presence,
            language,
            tone,
            profession,
        };
        const response = await instance.post('/api/chatbot/updateBotSettings', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function getHistory(botId, startDate, endDate) {
    try {
        const sendData = {
            botId: botId,
            startDate: startDate,
            endDate: endDate,
        };
        const response = await instance.post('/api/chatbot/getHistory', sendData);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function deleteChatbot(botId) {
    try {
        await csrf();
        const response = await instance.delete(`/api/chatbot/deleteChatbot/${botId}`);
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function transcribe(videos) {
    try {
        const response = await instance.post('/api/chatbot/transcribe', {
            videos: videos,
        });
        const data = response.data.data;
        return data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}

export async function addDomains(botId, domains) {
    try {
        await csrf();
        const response = await instance.post('/api/chatbot/addDomains', {
            botId: botId,
            domains: domains,
        });
        return response.data;
    } catch (err) {
        const error = JSON.stringify(err);
        await addError(err.code, err.config.url, err.message, err.response.status, err.response.statusText, error);
        throw err;
    }
}
