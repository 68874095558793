import { useState, useEffect } from 'react';
import { Table, Button, notification, Popconfirm, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { deleteError, getErrors, deleteErrors } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function Errors() {
    const translation = useTranslation().admin.errors;
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [codeString, setCodeString] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetchErrors = async () => {
        try {
            setLoading(true);
            const res = await getErrors();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            setData(temp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchErrors();
    }, []);

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.URL,
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => (
                <Button type="link" onClick={() => errorDetail(record)} disabled={deleting}>
                    {record.url}
                </Button>
            ),
        },
        {
            title: translation.CODE,
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: translation.MESSAGE,
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: translation.STATUS,
            dataIndex: 'status',
            key: 'status',
            width: 70,
        },
        {
            title: translation.STATUSTEXT,
            dataIndex: 'statusText',
            key: 'statusText',
        },
        {
            title: translation.CREATEDAT,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => new Date(date).toLocaleString(),
        },
        {
            title: translation.ACTION,
            dataIndex: 'action',
            key: 'action',
            width: 70,
            render: (text, record) => (
                <Popconfirm
                    title={translation.DELETE_TITLE}
                    description={translation.DELETE_DESCRIPTION}
                    onConfirm={() => handleDelete(record)}
                    okText={translation.DELETE_OKTEXT}
                    cancelText={translation.DELETE_CANCELTEXT}
                    okButtonProps={{
                        loading: deleting,
                    }}>
                    <Button danger icon={<DeleteOutlined />} type="text" disabled={deleting} />
                </Popconfirm>
            ),
        },
    ];

    const handleDelete = async (record) => {
        try {
            setDeleting(true);
            await deleteError(record._id);
            setData(data.filter((item) => item._id !== record._id));
            notification.success({
                message: translation.notification.SUCCESS_MSG,
                description: translation.notification.SUCCESS_DES,
            });
        } catch (error) {
            console.log(error);
            notification.error({
                message: translation.notification.ERROR_MSG,
                description: translation.notification.ERROR_DES,
            });
        } finally {
            setDeleting(false);
        }
    };

    const handleDeleteAll = async () => {
        try {
            setDeleting(true);
            await deleteErrors(selectedRowKeys);
            setData(data.filter((item) => !selectedRowKeys.includes(item._id)));
            notification.success({
                message: translation.notification.SUCCESS_MSG,
                description: translation.notification.SUCCESS_DES,
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: translation.notification.ERROR_MSG,
                description: translation.notification.ERROR_DES,
            });
        } finally {
            setDeleting(false);
            setSelectedRowKeys([]);
        }
    };

    const errorDetail = (record) => {
        const code = JSON.parse(record.error);
        setCodeString(code);
        setShowDetail(true);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div
                    style={{
                        fontSize: 32,
                        fontWeight: 700,
                        margin: 12,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <span>{translation.TITLE}</span>
                    <Button
                        danger
                        type="primary"
                        onClick={handleDeleteAll}
                        disabled={!hasSelected}
                        loading={hasSelected && deleting}>
                        {translation.DELETE_ALL}
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowSelection={rowSelection}
                    bordered
                    scroll={{
                        x: 700,
                    }}
                />
            </div>
            <Modal
                open={showDetail}
                title={translation.ERROR_INFO}
                footer={null}
                closable={false}
                onCancel={() => setShowDetail(false)}>
                <SyntaxHighlighter style={tomorrowNight}>{JSON.stringify(codeString, null, 2)}</SyntaxHighlighter>
            </Modal>
        </>
    );
}
