import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import { Layout, Button } from 'antd';
import useMediaQuery from 'use-media-antd-query';
import { MenuOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;

export default function Main({ children }) {
    const [collapsed, setCollapsed] = useState(true);
    const siderRef = useRef();
    const layoutRef = useRef();
    const isMdScreen = useMediaQuery();

    const toggleSider = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        if (isMdScreen === 'sm' || isMdScreen === 'xs') {
            const handleOutsideClick = (event) => {
                if (
                    !collapsed &&
                    siderRef.current &&
                    (siderRef.current.contains(event.target) || layoutRef.current.contains(event.target))
                ) {
                    setCollapsed(true);
                }
            };

            document.addEventListener('click', handleOutsideClick);
            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }
    }, [collapsed, isMdScreen]);

    return (
        <>
            <Layout className="layout-dashboard" ref={layoutRef}>
                {collapsed ? <Button icon={<MenuOutlined />} onClick={toggleSider} className="sider-button" /> : null}

                <Sider
                    ref={siderRef}
                    width={310}
                    theme="light"
                    className="sider-primary ant-layout-sider-primary"
                    breakpoint="md"
                    trigger={null}
                    collapsedWidth={0}
                    collapsible
                    collapsed={collapsed}
                    onCollapse={setCollapsed}
                    style={{ position: 'fixed', height: '100vh' }}
                    zeroWidthTriggerStyle={{ top: '20px', right: '5px' }}>
                    <div className="brand text-center">
                        <img src="/logo.svg" alt="MrSmith AI" />
                    </div>
                    <hr />
                    <Sidebar style={{ marginBottom: 8 }} />
                </Sider>
                <Layout className="layout">
                    <Content>{children}</Content>
                </Layout>
            </Layout>
        </>
    );
}
