import { useState, useEffect } from 'react';
import { Button, Input, notification, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { updateBasePrompt, getBasePrompt, updateContext, getContext } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function ManagePrompts() {
    const translation = useTranslation().admin.manageprompts;
    const [baseprompts, setBaseprompts] = useState([]);
    const [baseprompt, setBaseprompt] = useState('');
    const [isBaseSaving, setIsBaseSaving] = useState(false);

    const [contexts, setContexts] = useState([]);
    const [context, setContext] = useState('');
    const [isContextSaving, setIsContextSaving] = useState(false);

    const fetchBasePrompt = async () => {
        try {
            await getBasePrompt().then((res) => {
                setBaseprompts(res.data);
            });
        } catch (err) {
            console.log(err);
        }
    };

    const fetchContext = async () => {
        try {
            await getContext().then((res) => {
                setContexts(res.data);
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchBasePrompt();
        fetchContext();
    }, []);

    const handleBaseChange = (e) => {
        setBaseprompt(e.target.value);
    };

    const handleContextChange = (e) => {
        setContext(e.target.value);
    };

    const handleContextAdd = () => {
        setContexts([...contexts, context]);
        setContext('');
    };

    const handleBaseAdd = () => {
        setBaseprompts([...baseprompts, baseprompt]);
        setBaseprompt('');
    };

    const handleBaseDelete = (index) => {
        const updatedPrompts = [...baseprompts];
        updatedPrompts.splice(index, 1);
        setBaseprompts(updatedPrompts);
    };

    const handleContextDelete = (index) => {
        const updatedContexts = [...contexts];
        updatedContexts.splice(index, 1);
        setContexts(updatedContexts);
    };

    const handleBaseSave = async () => {
        setIsBaseSaving(true);
        try {
            await updateBasePrompt(baseprompts)
                .then((res) => {
                    notification.success({
                        message: translation.baseprompt.notification.SUCCESS,
                        placement: 'topRight',
                    });
                })
                .catch((err) => {
                    notification.error({
                        message: translation.baseprompt.notification.ERROR,
                        placement: 'topRight',
                    });
                });
        } catch (err) {
            console.log(err);
        }
        setIsBaseSaving(false);
    };

    const handleContextSave = async () => {
        setIsContextSaving(true);
        try {
            await updateContext(contexts)
                .then((res) => {
                    notification.success({
                        message: translation.context.notification.SUCCESS,
                        placement: 'topRight',
                    });
                })
                .catch((err) => {
                    notification.error({
                        message: translation.context.notification.ERROR,
                        placement: 'topRight',
                    });
                });
        } catch (err) {
            console.log(err);
        }
        setIsContextSaving(false);
    };

    return (
        <>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700 }}>{translation.TITLE}</div>
                <div style={{ marginTop: 12, padding: 20 }}>
                    <span className="settings-title">{translation.baseprompt.TITLE}</span>
                    <TextArea
                        className="width-100"
                        style={{ marginTop: 20 }}
                        rows={5}
                        value={baseprompt}
                        onChange={handleBaseChange}
                    />
                    <Button
                        className="float-right"
                        style={{ marginTop: 20 }}
                        size="large"
                        type="primary"
                        onClick={handleBaseAdd}>
                        {translation.baseprompt.ADD_TEMPLATE}
                    </Button>
                    <div style={{ marginTop: 72 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={baseprompts}
                            bordered
                            style={{ maxHeight: 400, overflow: 'auto' }}
                            renderItem={(item, index) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleBaseDelete(index)}
                                            type="text"
                                        />,
                                    ]}>
                                    {item}
                                </List.Item>
                            )}
                        />
                        <Button
                            className="float-right"
                            style={{ marginTop: 20 }}
                            size="large"
                            type="primary"
                            loading={isBaseSaving}
                            onClick={handleBaseSave}>
                            {isBaseSaving
                                ? translation.baseprompt.TEMPLATES_SAVING
                                : translation.baseprompt.SAVE_TEMPLATES}
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop: 12, padding: 20 }}>
                    <span className="settings-title">{translation.context.TITLE}</span>
                    <TextArea
                        className="width-100"
                        style={{ marginTop: 20 }}
                        rows={5}
                        value={context}
                        onChange={handleContextChange}
                    />
                    <Button
                        className="float-right"
                        style={{ marginTop: 20 }}
                        size="large"
                        type="primary"
                        onClick={handleContextAdd}>
                        {translation.context.ADD_TEMPLATE}
                    </Button>
                    <div style={{ marginTop: 72 }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={contexts}
                            bordered
                            style={{ maxHeight: 400, overflow: 'auto' }}
                            renderItem={(item, index) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleContextDelete(index)}
                                            type="text"
                                        />,
                                    ]}>
                                    {item}
                                </List.Item>
                            )}
                        />
                        <Button
                            className="float-right"
                            style={{ margin: '20px 0' }}
                            size="large"
                            type="primary"
                            loading={isContextSaving}
                            onClick={handleContextSave}>
                            {isContextSaving
                                ? translation.context.TEMPLATES_SAVING
                                : translation.context.SAVE_TEMPLATES}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
