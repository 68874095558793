import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LanguageContextProvider from './locales/LanguageContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#21dbcd',
                    },
                }}>
                <LanguageContextProvider>
                    <App />
                </LanguageContextProvider>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
