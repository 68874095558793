import { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, notification, Popconfirm, Form, Select, Switch, Checkbox, Badge } from 'antd';
import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import {
    getUsers,
    addAdmin,
    addUser,
    updateAdmin,
    updateUser,
    deleteUser,
    getSignUpSetting,
    updateSignUpSetting,
    getLevels,
    getChatbots,
} from '../../services/adminServices';
import { getUserInfo } from '../../services/authServices';
import useTranslation from '../../locales/translations';

export default function Users() {
    const translation = useTranslation().admin.users;
    const currentUser = getUserInfo();
    const [signup, setSignup] = useState(false);
    const [id, setId] = useState(null);
    const [admins, setAdmins] = useState([]);
    const [users, setUsers] = useState([]);
    const [levels, setLevels] = useState([]);
    const [levelValues, setLevelValues] = useState([]);
    const [bots, setBots] = useState([]);

    const [showAddAdmin, setShowAddAdmin] = useState(false);
    const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
    const [addAdminForm] = Form.useForm();
    const [updateAdminForm] = Form.useForm();

    const [showAddUser, setShowAddUser] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [addUserForm] = Form.useForm();
    const [updateUserForm] = Form.useForm();

    const [addingAdmin, setAddingAdmin] = useState(false);
    const [updatingAdmin, setUpdatingAdmin] = useState(false);
    const [addingUser, setAddingUser] = useState(false);
    const [validateUser, setValidateUser] = useState(true);
    const [isValidate, setIsValidate] = useState(true);
    const [updatingUser, setUpdatingUser] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchSignUpSetting = async () => {
        try {
            setLoading(true);
            await getSignUpSetting().then((res) => {
                const status = res.data[0];
                status === 'true' ? setSignup(true) : setSignup(false);
            });
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const res = await getUsers();
            const formattedData = res.data.reduce(
                (acc, data) => {
                    const temp = { key: data._id, ...data };
                    if (data.role === 'admin') {
                        acc.admins.push(temp);
                    } else {
                        acc.users.push(temp);
                    }
                    return acc;
                },
                { admins: [], users: [] },
            );

            let users = formattedData.users;
            for (const user of users) {
                user.status = 'active';
                user.expire_date = '';
            }
            setAdmins(formattedData.admins);
            setUsers([...users]);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const fetchLevels = async () => {
        try {
            setLoading(true);
            const res = await getLevels();
            setLevels(res.data);
            const temp = [];
            res.data.forEach((level) => {
                temp.push({ value: level.name, label: level.name });
            });
            setLevelValues(temp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const fetchBots = async (user) => {
        try {
            setLoading(true);
            const res = await getChatbots();
            setBots(res.data);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchSignUpSetting();
        fetchUsers();
        fetchLevels();
        fetchBots();
    }, []);

    const handleSignUpChange = async (checked) => {
        setSignup(checked);
        try {
            checked ? await updateSignUpSetting(true) : await updateSignUpSetting(false);
        } catch (err) {
            throw err;
        }
    };

    const handleAddAdmin = () => {
        setShowAddAdmin(true);
    };

    const handleSaveAdmin = async () => {
        setAddingAdmin(true);
        try {
            const values = await addAdminForm.validateFields();
            await addAdmin(values);
            notification.success({
                message: translation.notification.ADD_ADMIN_SUCCESS,
                placement: 'topRight',
            });
            setShowAddAdmin(false);
            fetchUsers();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setAddingAdmin(false);
        }
    };

    const handleEditAdmin = (record) => {
        updateAdminForm.setFieldsValue(record);
        setId(record._id);
        setShowUpdateAdmin(true);
    };

    const handleUpdateAdmin = async () => {
        setUpdatingAdmin(true);
        try {
            const values = await updateAdminForm.validateFields();
            await updateAdmin(id, values);
            notification.success({
                message: translation.notification.UPDATE_ADMIN_SUCCESS,
                placement: 'topRight',
            });
            setShowUpdateAdmin(false);
            fetchUsers();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setUpdatingAdmin(false);
        }
    };

    const handleAddUser = () => {
        setShowAddUser(true);
    };

    const handleSaveUser = async () => {
        setAddingUser(true);
        try {
            const values = await addUserForm.validateFields();
            values.validateUser = validateUser;
            await addUser(values);
            notification.success({
                message: translation.notification.ADD_USER_SUCCESS,
                placement: 'topRight',
            });
            setShowAddUser(false);
            fetchUsers();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setAddingUser(false);
        }
    };

    const handleEditUser = (record) => {
        updateUserForm.setFieldsValue(record);
        setIsValidate(!record.isVerified);
        setId(record._id);
        setShowUpdateUser(true);
    };

    const handleUpdateUser = async () => {
        setUpdatingUser(true);
        try {
            const values = await updateUserForm.validateFields();
            values.validateUser = isValidate && validateUser;
            await updateUser(id, values);
            notification.success({
                message: translation.notification.UPDATE_USER_SUCCESS,
                placement: 'topRight',
            });
            setShowUpdateUser(false);
            fetchUsers();
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setUpdatingUser(false);
        }
    };

    const handleDelete = async (userId) => {
        try {
            setDeleting(true);
            await deleteUser(userId);
            fetchUsers();
            notification.success({
                message: translation.notification.DELETE_SUCCESS,
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setDeleting(false);
        }
    };

    const handleValidateUser = (e) => {
        setValidateUser(e.target.checked);
    };

    const adminColumns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.NAME,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translation.EMAIL,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: translation.VERIFIABLE,
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => (record.isVerified ? `${translation.VERIFIED}` : `${translation.NOT_VERIFIED}`),
        },
        {
            title: translation.ACTION,
            dataIndex: 'action',
            key: 'action',
            width: 110,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        style={{ border: 'none', marginRight: 8 }}
                        onClick={() => handleEditAdmin(record)}
                        type="primary"
                        ghost
                    />
                    {record._id !== currentUser._id ? (
                        <Popconfirm
                            title={translation.delete.TITLE}
                            description={translation.delete.DESCRIPTION}
                            onConfirm={() => handleDelete(record._id)}
                            okText={translation.delete.OK}
                            cancelText={translation.delete.CANCEL}
                            okButtonProps={{
                                loading: deleting,
                            }}>
                            <Button danger icon={<DeleteOutlined />} type="text" />
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ];

    const userColumns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.NAME,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translation.EMAIL,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: translation.VERIFIABLE,
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => (record.isVerified ? `${translation.VERIFIED}` : `${translation.NOT_VERIFIED}`),
        },
        {
            title: translation.level.TITLE,
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: 'Limitations',
            dataIndex: 'limit',
            key: 'limit',
            render: (text, record) => {
                const userBots = bots && bots.filter((bot) => bot.userEmail === record.email);
                const allocatedBots =
                    bots && bots.filter((bot) => bot.allocatedUsers && bot.allocatedUsers.includes(record.email));
                const userLevel = levels && levels.filter((level) => level.name === record.level);
                return (
                    <>
                        <span>
                            {userBots.length + allocatedBots.length} / {userLevel.length && userLevel[0].numberOfBots}
                            {translation.ASSISTANT_LIMIT}
                        </span>
                        <br />
                        <span>
                            {record.queries} / {userLevel.length && userLevel[0].numberOfQueries}
                            {translation.QUERY_LIMIT}
                        </span>
                    </>
                );
            },
        },
        {
            title: 'Subscriptions',
            dataIndex: 'subscription',
            key: 'subscription',
            render: (text, record) => {
                return (
                    <>
                        <span>
                            {record.status ? (
                                <Badge
                                    status={record.status === 'active' ? 'success' : 'error'}
                                    text={record.status === 'active' ? translation.ACTIVE : translation.INCOMPLETE}
                                />
                            ) : null}
                        </span>
                        <br />
                        <span>
                            {record.expire_date ? `${translation.EXPIRE}${record.expire_date.toLocaleString()}` : null}
                        </span>
                    </>
                );
            },
        },
        {
            title: translation.ACTION,
            dataIndex: 'action',
            key: 'action',
            width: 110,
            render: (text, record) => (
                <>
                    <Button
                        icon={<EditOutlined />}
                        style={{ border: 'none', marginRight: 8 }}
                        onClick={() => handleEditUser(record)}
                        type="primary"
                        ghost
                    />
                    <Popconfirm
                        title={translation.delete.TITLE}
                        description={translation.delete.DESCRIPTION}
                        onConfirm={() => handleDelete(record._id)}
                        okText={translation.delete.OK}
                        cancelText={translation.delete.CANCEL}
                        okButtonProps={{
                            loading: deleting,
                        }}>
                        <Button danger icon={<DeleteOutlined />} type="text" />
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700, marginBottom: 12 }}>{translation.TITLE}</div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ marginRight: 12 }}>{translation.SHOW_SIGNUP}</span>
                    <Switch onChange={handleSignUpChange} checked={signup} />
                </div>

                <Table
                    columns={adminColumns}
                    dataSource={admins}
                    title={() => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <span style={{ margin: -4, fontSize: 20 }}>{translation.ADMIN_TITLE}</span>
                                <Button type="primary" icon={<UserAddOutlined />} onClick={() => handleAddAdmin()}>
                                    {translation.NEW_ADMIN}
                                </Button>
                            </div>
                        </>
                    )}
                    style={{ marginBottom: 24 }}
                    bordered
                    scroll={{
                        x: 700,
                    }}
                />
                <Table
                    columns={userColumns}
                    dataSource={users}
                    title={() => (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <span style={{ margin: -4, fontSize: 20 }}>{translation.USER_TITLE}</span>
                                <Button type="primary" icon={<UserAddOutlined />} onClick={() => handleAddUser()}>
                                    {translation.NEW_USER}
                                </Button>
                            </div>
                        </>
                    )}
                    style={{ marginBottom: 24 }}
                    bordered
                    scroll={{
                        x: 700,
                    }}
                />

                <Modal
                    title={translation.new_admin.TITLE}
                    closable={false}
                    open={showAddAdmin}
                    onCancel={() => setShowAddAdmin(false)}
                    footer={null}>
                    <Form form={addAdminForm} layout="vertical" onFinish={handleSaveAdmin}>
                        <Form.Item
                            label={translation.new_admin.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.new_admin.NAME} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_admin.EMAIL}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: translation.validation.EMAIL,
                                },
                            ]}>
                            <Input placeholder={translation.new_admin.EMAIL} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_admin.PASSWORD}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.PASSWORD,
                                },
                            ]}
                            hasFeedback>
                            <Input.Password
                                placeholder={translation.new_admin.PASSWORD}
                                type="password"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_admin.CONFIRM_PASSWORD}
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.CONFIRM_PASSWORD,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Password No match'));
                                    },
                                }),
                            ]}
                            hasFeedback>
                            <Input.Password
                                placeholder={translation.new_admin.CONFIRM_PASSWORD}
                                type="password"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={addingAdmin}>
                                {translation.new_admin.ADD_ADMIN}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowAddAdmin(false)}>
                                {translation.new_admin.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={translation.update_admin.TITLE}
                    closable={false}
                    open={showUpdateAdmin}
                    onCancel={() => setShowUpdateAdmin(false)}
                    footer={null}>
                    <Form form={updateAdminForm} layout="vertical" onFinish={handleUpdateAdmin}>
                        <Form.Item
                            label={translation.update_admin.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.update_admin.NAME} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.update_admin.EMAIL}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: translation.validation.EMAIL,
                                },
                            ]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={updatingAdmin}>
                                {translation.update_admin.UPDATE_ADMIN}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowUpdateAdmin(false)}>
                                {translation.update_admin.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={translation.new_user.TITLE}
                    closable={false}
                    open={showAddUser}
                    onCancel={() => {
                        setShowAddUser(false);
                        setValidateUser(true);
                    }}
                    footer={null}>
                    <Form form={addUserForm} layout="vertical" onFinish={handleSaveUser}>
                        <Form.Item
                            label={translation.new_user.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.new_user.NAME} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_user.EMAIL}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: translation.validation.EMAIL,
                                },
                            ]}>
                            <Input placeholder={translation.new_user.EMAIL} autoComplete="off" />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox valuePropName="checked" checked={validateUser} onChange={handleValidateUser}>
                                {translation.new_user.VALIDATE}
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={translation.new_user.PASSWORD}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.PASSWORD,
                                },
                            ]}
                            hasFeedback>
                            <Input.Password
                                placeholder={translation.new_user.PASSWORD}
                                type="password"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_user.CONFIRM_PASSWORD}
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.CONFIRM_PASSWORD,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Password No match'));
                                    },
                                }),
                            ]}
                            hasFeedback>
                            <Input.Password
                                placeholder={translation.new_user.CONFIRM_PASSWORD}
                                type="password"
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            label={translation.new_user.LEVEL}
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.LEVEL,
                                },
                            ]}>
                            <Select options={levelValues} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={addingUser}>
                                {translation.new_user.ADD_USER}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowAddUser(false)}>
                                {translation.new_user.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={translation.update_user.TITLE}
                    closable={false}
                    open={showUpdateUser}
                    onCancel={() => {
                        setShowUpdateUser(false);
                        setValidateUser(true);
                        setIsValidate(null);
                    }}
                    footer={null}>
                    <Form form={updateUserForm} layout="vertical" onFinish={handleUpdateUser}>
                        <Form.Item
                            label={translation.update_user.NAME}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.NAME,
                                },
                            ]}>
                            <Input placeholder={translation.update_user.NAME} autoComplete="off" />
                        </Form.Item>
                        <Form.Item label={translation.update_user.EMAIL} name="email">
                            <Input disabled />
                        </Form.Item>
                        {isValidate ? (
                            <Form.Item>
                                <Checkbox valuePropName="checked" checked={validateUser} onChange={handleValidateUser}>
                                    {translation.new_user.VALIDATE}
                                </Checkbox>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label={translation.update_user.LEVEL}
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: translation.validation.LEVEL,
                                },
                            ]}>
                            <Select options={levelValues} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary" loading={updatingUser}>
                                {translation.update_user.UPDATE_USER}
                            </Button>
                            <Button
                                htmlType="button"
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => setShowUpdateUser(false)}>
                                {translation.update_user.CANCEL}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    );
}
