import { Button, Row, Col, Divider, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getChatbots } from '../../services/chatbotServices';
import { getUserInfo } from '../../services/authServices';
import { getLevel } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function MyChatbot() {
    const translation = useTranslation().chatbot;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/createnewbot');
    };
    const handleCardClick = (botId) => {
        navigate(`/chatbot/${botId}`);
    };
    const [level, setLevel] = useState();
    const [myList, setMyList] = useState([]);
    const [otherList, setOtherList] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = getUserInfo();

    const fetchBotList = async () => {
        try {
            try {
                setLoading(true);
                await getChatbots().then((res) => {
                    const data = res.data;
                    const my = [];
                    const other = [];
                    let temp = [];
                    user.role === 'admin'
                        ? data.forEach((bot, index) => {
                              if (bot.userEmail === user.email) {
                                  my.push(bot);
                              } else {
                                  if (index > 0 && bot.userEmail !== data[index - 1].userEmail) {
                                      if (temp.length) other.push(temp);
                                      temp = [];
                                  }
                                  temp.push(bot);
                              }
                          })
                        : data.forEach((bot) => {
                              my.push(bot);
                          });
                    if (temp.length) other.push(temp);
                    setMyList(my);
                    setOtherList(other);
                });
                setLoading(false);
            } catch (err) {
                throw err;
            }
        } catch (err) {
            throw err;
        }
    };

    const fetchLevel = async () => {
        try {
            // setLoading(true);
            const res = await getLevel(user.level);
            setLevel(res.data);
            // setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchLevel();
        fetchBotList();
    }, []);

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="main-layout card-layout">
                <Row className="margin-32">
                    <Col span={12} className="text-left">
                        <span className="title">
                            <b>{translation.TITLE}</b>
                        </span>
                    </Col>
                    <Col span={12} className="text-right">
                        <Button type="primary" size="large" onClick={handleClick}>
                            {translation.CREATE_AI_ASSISTANT}
                        </Button>
                    </Col>
                </Row>
                <Divider />
                {myList.length || otherList.length ? (
                    <>
                        {myList.length ? (
                            <>
                                <span style={{ fontSize: 24 }}>
                                    {translation.MY_AI_ASSISTANT}
                                    {user.role === 'user' ? (
                                        <>{` (${myList.length} / ${level && level.numberOfBots} limits)`}</>
                                    ) : null}
                                </span>
                                <Row className="margin-32 chatbot-card-list">
                                    {myList.map((bot) => (
                                        <Card
                                            key={bot._id}
                                            className="chatbot-card"
                                            size="small"
                                            cover={
                                                <img
                                                    alt="example"
                                                    className="chatbot-card-image"
                                                    src="/MrSmith Bot.svg"
                                                />
                                            }
                                            onClick={() => handleCardClick(bot._id)}>
                                            <div className="card-body" style={{ overflowWrap: 'break-word' }}>
                                                <div>{bot.name}</div>
                                                <div>[{bot.userEmail}]</div>
                                            </div>
                                        </Card>
                                    ))}
                                </Row>
                            </>
                        ) : null}
                        {otherList.length ? (
                            <>
                                <span style={{ fontSize: 24 }}>{translation.OTHER_AI_ASSISTANT}</span>
                                <br />
                                <br />
                                {otherList.map((other) =>
                                    other.length ? (
                                        <>
                                            <span style={{ fontSize: 18, display: 'flex' }}>{other[0].userEmail}</span>
                                            <Row className="margin-32 chatbot-card-list">
                                                {other.map((bot) => (
                                                    <Card
                                                        key={bot._id}
                                                        className="chatbot-card"
                                                        size="small"
                                                        cover={
                                                            <img
                                                                alt="example"
                                                                className="chatbot-card-image"
                                                                src="/MrSmith Bot.svg"
                                                            />
                                                        }
                                                        onClick={() => handleCardClick(bot._id)}>
                                                        <div
                                                            className="card-body"
                                                            style={{ overflowWrap: 'break-word' }}>
                                                            <div>{bot.name}</div>
                                                            <div>[{bot.userEmail}]</div>
                                                        </div>
                                                    </Card>
                                                ))}
                                            </Row>
                                        </>
                                    ) : null,
                                )}
                            </>
                        ) : null}
                    </>
                ) : (
                    <div className="main-layout">
                        <span style={{ fontSize: 32 }}>{translation.EMPTY_DESCRIPTION}</span>
                    </div>
                )}
            </div>
        </>
    );
}
