import { Tooltip, Button, Divider, Input, message } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { SyncOutlined, SendOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { getInterface, getSetting } from '../../services/chatbotServices';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import bcrypt from 'bcryptjs';
import base64url from 'base64url';
import useTranslation from '../../locales/translations';

export default function Chatbotwidget() {
    const translation = useTranslation().chatbot.mainwindows;
    const { botId, hashValue } = useParams();
    const [initialMsg, setInitialMsg] = useState('');
    const [suggestMsg, setSuggestMsg] = useState('');
    const [picture, setPicture] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [messageState, setMessageState] = useState({
        messages: [],
        pending: '',
    });
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    //-----------------
    const [theme, setTheme] = useState('light');
    const [msgColor, setMsgColor] = useState('rgb(33, 219, 205)');
    //-----------------

    const chatContentRef = useRef(null);
    const suggestMsgRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(60);

    const [baseprompt, setBaseprompt] = useState('');
    const [temperature, setTemperature] = useState(0.7);
    const [rememberconv, setRememberconv] = useState('');
    const [addContext, setAddContext] = useState('');
    const [context, setContext] = useState('');
    const [numberconv, setNumberconv] = useState(20);
    const [addlogs, setAddlogs] = useState('');
    const [addsource, setAddsource] = useState('');
    const [summarize, setSummarize] = useState('');
    const [model, setModel] = useState('');
    const [maxTokens, setMaxTokens] = useState(1000);
    const [topP, setTopP] = useState(0.01);
    const [bestOf, setBestOf] = useState(1);
    const [frequency, setFrequency] = useState(0.01);
    const [presence, setPresence] = useState(0.01);
    const [language, setLanguage] = useState('french');
    const [tone, setTone] = useState('friendly');
    const [profession, setProfession] = useState('none');
    const [chatId, setChatId] = useState(null);
    const chatSource = translation.CHATSOURCE_FRAME;
    //-----------------
    const [limit, setLimit] = useState(20);
    const [limittime, setLimittime] = useState(240);
    const [limitmsg, setLimitmsg] = useState('');
    //-----------------

    const { messages, pending } = messageState;
    const [queryCount, setQueryCount] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const isMatch = useMemo(
        () => (hashValue === undefined ? false : bcrypt.compareSync(botId, base64url.toBase64(hashValue))),
        [botId, hashValue],
    );

    const openURLInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const urlToOpen = 'https://mr-smith.ai/';

    const fetchInterface = async () => {
        try {
            await getInterface(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    setInitialMsg(bot.initialMsg);
                    setSuggestMsg(bot.suggestMsg);
                    setPicture(bot.picture);
                    setDisplayName(bot.displayName);
                    //-----------------
                    setTheme(bot.theme);
                    setMsgColor(bot.msgColor);
                    //-----------------
                    const temp = [];
                    if (bot.initialMsg)
                        bot.initialMsg.split('\n').map((msg) => {
                            temp.push({ content: msg, role: 'assistant' });
                            return null;
                        });
                    setMessageState((state) => ({
                        ...state,
                        messages: temp,
                    }));
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchSetting = async () => {
        try {
            const res = await getSetting(botId);
            if (res.data) {
                const bot = res.data;
                setBaseprompt(bot.baseprompt);
                setTemperature(bot.temperature);
                setAddContext(bot.addContext);
                setContext(bot.context);
                setRememberconv(bot.rememberconv);
                setNumberconv(bot.numberconv);
                setAddlogs(bot.addlogs);
                setAddsource(bot.addsource);
                setSummarize(bot.summarize);
                setModel(bot.model);
                setMaxTokens(bot.maxTokens);
                setTopP(bot.topP);
                setBestOf(bot.bestOf);
                setFrequency(bot.frequency);
                setPresence(bot.presence);
                setLanguage(bot.language);
                setTone(bot.tone);
                setProfession(bot.profession);
                //-----------------
                setLimit(bot.limit);
                setLimittime(bot.limittime);
                setLimitmsg(bot.limitmsg);
                //-----------------
            }
        } catch (err) {
            console.error(err);
        }
    };

    const initSetting = useCallback(async () => {
        fetchInterface();
        await fetchSetting();
        document.getElementById('query').addEventListener('keypress', function (event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (document.getElementById('query') !== '') {
                    document.getElementById('send').click();
                }
            }
        });

        const intervalId = setInterval(() => {
            setQueryCount(0);
        }, limittime * 1000);
        return () => clearInterval(intervalId);
    }, [botId, limit, limitmsg, limittime]);

    useEffect(() => {
        initSetting();
    }, [initSetting]);

    useEffect(() => {
        chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        if (query) {
            setMessageState((state) => ({
                ...state,
                messages: [...state.messages, { content: query, role: 'user' }],
                pending: '',
            }));
            setIsFetching(true);
        }
        const suggestMsgHeight = suggestMsgRef.current ? suggestMsgRef.current.offsetHeight : 0;
        setMarginBottom(60 + suggestMsgHeight);
    }, [query]);

    useEffect(() => {
        if (isFetching) {
            document.getElementById('loading').style.display = 'flex';
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
            const ctrl = new AbortController();
            async function fetchAnswer() {
                try {
                    await fetchEventSource(`/api/chatbot/getReply`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            namespace: botId,
                            messages,
                            baseprompt,
                            temperature,
                            addContext,
                            context,
                            rememberconv,
                            numberconv,
                            addlogs,
                            addsource,
                            summarize,
                            model,
                            maxTokens,
                            topP,
                            bestOf,
                            frequency,
                            presence,
                            language,
                            tone,
                            profession,
                            chatId,
                            chatSource,
                            isIncrease: true,
                        }),
                        signal: ctrl.signal,
                        onmessage: (event) => {
                            document.getElementById('loading').style.display = 'none';
                            if (JSON.parse(event.data).data === '[DONE]') {
                                setMessageState((state) => ({
                                    messages: [
                                        ...state.messages,
                                        {
                                            content: state.pending ?? '',
                                            role: 'assistant',
                                        },
                                    ],
                                    pending: '',
                                }));
                                setQuery('');
                                setIsFetching(false);
                                setMsg('');
                                ctrl.abort();
                            } else {
                                const data = JSON.parse(event.data);
                                const chatId = JSON.parse(event.data).chatId;
                                setChatId(chatId);
                                setMessageState((state) => ({
                                    ...state,
                                    pending: (state.pending ?? '') + data.data,
                                }));
                            }
                        },
                    });
                } catch (err) {
                    document.getElementById('loading').style.display = 'none';
                    messageApi.open({
                        type: 'warning',
                        content: err.response?.data?.message || err.message,
                    });
                }
            }
            fetchAnswer();
        }
    }, [isFetching]);

    const chatMessages = useMemo(() => {
        return [
            ...messages,
            ...(pending
                ? [
                      {
                          content: pending,
                          role: 'assistant',
                      },
                  ]
                : []),
        ];
    }, [messages, pending]);

    //scroll to bottom of chat
    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [chatMessages]);

    useEffect(() => {
        document.body.style.background = theme === 'light' ? 'white' : '#2e2d2c';
    }, [theme]);

    const handleSendQuery = async (message) => {
        setQueryCount(queryCount + 1);
        if (queryCount >= limit) {
            messageApi.open({
                type: 'warning',
                content: limitmsg,
            });
        } else if (!query) {
            setQuery(message);
        }
    };

    const pageReload = () => {
        if (!query) {
            setMessageState({ messages: [], pending: '' });
            const temp = [];
            initialMsg.split('\n').map((msg) => {
                temp.push({ content: msg, role: 'assistant' });
                return null;
            });
            setMessageState((state) => ({
                ...state,
                messages: temp,
            }));
            setChatId(null);
        }
    };

    const handleInputChange = (e) => {
        setMsg(e.target.value);
    };

    const getLuminance = (color) => {
        const rgb = color.match(/\d+/g);

        const r = rgb[0];
        const g = rgb[1];
        const b = rgb[2];

        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luminance;
    };

    return (
        <>
            <div className="text-center margin-12">
                {contextHolder}
                <div
                    className="widget"
                    style={{
                        background: `${theme === 'light' ? 'white' : '#2e2d2c'}`,
                    }}>
                    <div className="display-flex margin-12 align-center">
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    fontWeight: 700,
                                    fontSize: 20,
                                    alignItems: 'center',
                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                }}>
                                {picture ? (
                                    <img
                                        src={'/uploads/' + picture}
                                        alt="profile"
                                        className="profile-image"
                                        crossOrigin="anonymous"
                                    />
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 283.46 283.46"
                                        className="profile-image">
                                        <g id="Calque_2" data-name="Calque 2">
                                            <g id="Calque_1-2" data-name="Calque 1">
                                                <circle
                                                    cx="141.73"
                                                    cy="141.73"
                                                    r="141.73"
                                                    style={{ fill: '#21dbcd' }}
                                                />
                                                <circle
                                                    cx="108.6"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                />
                                                <circle
                                                    cx="174.88"
                                                    cy="142.2"
                                                    r="22.09"
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                />
                                                <path
                                                    style={{
                                                        fill: '#fff',
                                                    }}
                                                    d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                )}
                                &nbsp;{displayName}
                            </div>
                        </div>
                        <Tooltip title={translation.REFRESH}>
                            <Button
                                icon={<SyncOutlined style={{ fontSize: 20 }} />}
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                }}
                                onClick={pageReload}
                            />
                        </Tooltip>
                    </div>
                    <Divider
                        style={{
                            background: `${theme === 'light' ? 'gray' : 'white'}`,
                            margin: 0,
                        }}
                    />
                    <div className="chat-content" ref={chatContentRef} style={{ marginBottom: marginBottom }}>
                        {chatMessages.map((message, index) => (
                            <div
                                key={index}
                                className={`${message.role === 'user' ? 'customer' : 'bot'}-text`}
                                style={
                                    message.role === 'user'
                                        ? {
                                              backgroundColor: `${msgColor}`,
                                              color: `${getLuminance(msgColor) > 180 ? '#2e2d2c' : 'white'}`,
                                          }
                                        : null
                                }>
                                <ReactMarkdown
                                    remarkPlugins={[remarkMath, rehypeKatex]}
                                    linkTarget="_blank"
                                    rel="noopener noreferrer">
                                    {message.content}
                                </ReactMarkdown>
                            </div>
                        ))}

                        <div className="bot-text" id="loading" style={{ display: 'none' }}>
                            <div className="dot-flashing" />
                        </div>
                    </div>
                    <div className="suggestmsg-container" ref={suggestMsgRef}>
                        {suggestMsg.split('\n').map((msg, index) => (
                            <Button
                                key={index}
                                className="suggestmsg"
                                style={{ display: `${msg.length ? '' : 'none'}` }}
                                onClick={() => handleSendQuery(msg)}>
                                {msg}
                            </Button>
                        ))}
                    </div>
                    <div className="question-container">
                        <Input
                            id="query"
                            placeholder={translation.INPUT_PLACEHOLDER}
                            value={msg}
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            className={`${theme === 'light' ? 'input-black' : 'input-white'}`}
                            onChange={handleInputChange}
                            disabled={isFetching}
                            suffix={
                                <Tooltip title={translation.SEND}>
                                    <Button
                                        id="send"
                                        icon={<SendOutlined style={{ fontSize: 20 }} />}
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                        }}
                                        onClick={() => handleSendQuery(msg)}
                                    />
                                </Tooltip>
                            }
                            autoComplete="off"
                        />
                    </div>
                </div>
                {!isMatch ? (
                    <span
                        className="font-weight-normal"
                        style={{
                            fontSize: 13,
                            color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                        }}>
                        Powered by{' '}
                        <Link
                            onClick={() => openURLInNewTab(urlToOpen)}
                            style={{ color: '#21dbcd', textDecoration: 'none' }}>
                            <b>Mr Smith</b>
                        </Link>
                    </span>
                ) : null}
            </div>
        </>
    );
}
