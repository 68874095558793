import './App.css';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import SignIn from './components/authentication/signin';
import SignUp from './components/authentication/signup';
import Confirm from './components/authentication/confirm';
import ResetPassword from './components/authentication/resetPassword';
import Main from './layouts/Main';
import Dashboard from './components/dashboard';
import CreateNewBot from './components/dashboard/createnewbot';
import MyChatbot from './components/mychatbot';
import PineconeIndexes from './components/admin';
import Chatbot from './components/mychatbot/chatbot';
import Createbotbulk from './components/admin/createbotbulk';
import Chatbotwidget from './components/mychatbot/chatbotwidget';
import BotsInBulk from './components/admin/botsinbulk';
import Assistant from './components/admin/assistant';
import DefaultSettings from './components/admin/defaultsettings';
import ManagePrompts from './components/admin/manageprompts';
import Errors from './components/admin/errors';
import Users from './components/admin/users';
import PromptLogs from './components/admin/promptlogs';
import Responses from './components/admin/responses';
import Levels from './components/admin/levels';

import { getAuthToken, getUserRole } from './services/authServices';

function PrivateRoute() {
    return getAuthToken() ? <Outlet /> : <Navigate to="/signin" />;
}

function AdminRoute() {
    return getUserRole() === 'admin' ? <Outlet /> : <Navigate to="/" />;
}

function App() {
    return (
        <>
            <Routes>
                <Route path="/signin" exact element={<SignIn />} />
                <Route path="/signup" exact element={<SignUp />} />
                <Route path="/confirm/:token" exact element={<Confirm />} />
                <Route path="/resetPassword/:resetToken" exact element={<ResetPassword />} />
                <Route path="/chatbotwidget/:botId/:hashValue?" exact element={<Chatbotwidget />} />
                <Route exact path="/assistant/:botId" element={<Assistant />} />

                <Route exact path="/" element={<PrivateRoute />}>
                    <Route
                        path="/"
                        exact
                        element={
                            <Main>
                                <Dashboard />
                            </Main>
                        }
                    />
                    <Route
                        path="/createnewbot"
                        exact
                        element={
                            <Main>
                                <CreateNewBot />
                            </Main>
                        }
                    />
                    <Route
                        path="/chatbot/:botId"
                        exact
                        element={
                            <Main>
                                <Chatbot />
                            </Main>
                        }
                    />
                    <Route
                        path="/mychatbots"
                        exact
                        element={
                            <Main>
                                <MyChatbot />
                            </Main>
                        }
                    />
                </Route>

                <Route exact path="/" element={<PrivateRoute />}>
                    <Route exact path="/" element={<AdminRoute />}>
                        <Route
                            path="/pineconeindex"
                            exact
                            element={
                                <Main>
                                    <PineconeIndexes />
                                </Main>
                            }
                        />
                        <Route
                            path="/createbotbulk"
                            exact
                            element={
                                <Main>
                                    <Createbotbulk />
                                </Main>
                            }
                        />
                        <Route
                            path="/botsinbulk"
                            exact
                            element={
                                <Main>
                                    <BotsInBulk />
                                </Main>
                            }
                        />
                        <Route
                            path="/defaultsettings"
                            exact
                            element={
                                <Main>
                                    <DefaultSettings />
                                </Main>
                            }
                        />
                        <Route
                            path="/manageprompts"
                            exact
                            element={
                                <Main>
                                    <ManagePrompts />
                                </Main>
                            }
                        />
                        <Route
                            path="/errors"
                            exact
                            element={
                                <Main>
                                    <Errors />
                                </Main>
                            }
                        />
                        <Route
                            path="/users"
                            exact
                            element={
                                <Main>
                                    <Users />
                                </Main>
                            }
                        />
                        <Route
                            path="/responses"
                            exact
                            element={
                                <Main>
                                    <Responses />
                                </Main>
                            }
                        />
                        <Route
                            path="/promptlogs"
                            exact
                            element={
                                <Main>
                                    <PromptLogs />
                                </Main>
                            }
                        />
                        <Route
                            path="/levels"
                            exact
                            element={
                                <Main>
                                    <Levels />
                                </Main>
                            }
                        />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default App;
