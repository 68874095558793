import { useState, useEffect } from 'react';
import { Button, Table, Modal, Divider, Row, Col } from 'antd';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { getPromptLogs } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

export default function PromptLogs() {
    const translation = useTranslation().admin.prompt_logs;
    const [data, setData] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [codeString, setCodeString] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchLogs = async () => {
        try {
            setLoading(true);
            const res = await getPromptLogs();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            setData(temp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.CHATID,
            dataIndex: 'chatId',
            key: 'chatId',
            width: 240,
            render: (text, record) => (
                <Button type="link" onClick={() => logDetail(record)}>
                    {record._id}
                </Button>
            ),
        },
        {
            title: translation.BOTNAME,
            dataIndex: 'botName',
            key: 'botName',
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => a.botName.localeCompare(b.botName),
                multiple: 1,
            },
        },
        {
            title: translation.USER,
            dataIndex: 'user',
            key: 'user',
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => a.userEmail.localeCompare(b.userEmail),
                multiple: 1,
            },
            render: (text, record) => record.userEmail,
        },
        {
            title: translation.CHATSOURCE,
            dataIndex: 'chatSource',
            key: 'chatSource',
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => a.chatSource.localeCompare(b.chatSource),
                multiple: 1,
            },
        },
        {
            title: translation.UPDATEDAT,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
                multiple: 2,
            },
            render: (date) => new Date(date).toLocaleString(),
        },
    ];

    const logDetail = (record) => {
        let code = [];
        if (record) {
            const model = record.settings[0].model;
            const tempMessages = record.messages;
            let messages = [];
            tempMessages.forEach((msg) => {
                messages.push({ role: msg.role, content: msg.content });
            });
            const temperature = record.settings[0].temperature;
            const max_tokens = record.settings[0].max_tokens;
            const frequency_penalty = record.settings[0].frequency_penalty;
            const presence_penalty = record.settings[0].presence_penalty;
            const top_p = record.settings[0].top_p;
            const best_of = record.settings[0].best_of;
            const tone = record.settings[0].tone;
            const profession = record.settings[0].profession;
            const language = record.settings[0].language;
            const baseprompt = record.settings[0].baseprompt;
            const conversationHistory = record.settings[0].conversationHistory;
            const matches = record.settings[0].matches;
            const qaTemplate = record.settings[0].qaTemplate;
            // const inquiryTemplate = record.settings[0].inquiryTemplate;
            code.push({
                model,
                messages,
                temperature,
                max_tokens,
                frequency_penalty,
                presence_penalty,
                top_p,
                best_of,
                tone,
                profession,
                language,
                baseprompt,
                conversationHistory,
                matches,
                qaTemplate,
                // inquiryTemplate,
            });
            setCodeString(code);
            setShowDetail(true);
        }
    };

    const onChange = (pagination, filters, sorter) => {
        console.log('Sorter:', sorter);
    };

    const expandedRowRender = (record) => {
        if (record) {
            const durations = record.durations;
            return (
                <>
                    {durations.map((duration, index) => (
                        <>
                            {index > 0 ? <Divider /> : null}
                            <p>
                                {translation.CUSTOMER}
                                {duration.msg[0].content}
                            </p>
                            <p>
                                {translation.AI_ASSISTANT}
                                {duration.msg[1].content}
                            </p>
                            <div style={{ width: '80%', margin: 'auto' }}>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <span>
                                            {translation.DURATION_TEXT01}
                                            {`${duration.duration01}`}ms
                                        </span>
                                    </Col>
                                    <Col>
                                        <span>
                                            {translation.DURATION_TEXT02}
                                            {duration.duration02}ms
                                        </span>
                                    </Col>
                                    <Col>
                                        <span>
                                            {translation.DURATION_TEXT03}
                                            {duration.duration03}ms
                                        </span>
                                    </Col>
                                    <Col>
                                        <span>
                                            {translation.DURATION_TEXT04}
                                            {duration.duration04}ms
                                        </span>
                                    </Col>
                                    <Col>
                                        <span>
                                            {translation.DURATION_TEXT05}
                                            {duration.duration05}ms
                                        </span>
                                    </Col>
                                </Row>
                                <Divider style={{ margin: '8px 0' }} />
                                <Row>
                                    <span>
                                        {translation.TOTAL_DURATION}
                                        {duration.total_duration}ms
                                    </span>
                                </Row>
                            </div>
                        </>
                    ))}
                </>
            );
        }
    };

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700, margin: 12 }}>{translation.TITLE}</div>
                <Table
                    columns={columns}
                    dataSource={data}
                    bordered
                    expandable={{ expandedRowRender }}
                    scroll={{
                        x: 700,
                    }}
                    onChange={onChange}
                />
            </div>
            <Modal
                open={showDetail}
                title={translation.LOG_INFO}
                footer={null}
                closable={false}
                onCancel={() => setShowDetail(false)}
                width="80%">
                <SyntaxHighlighter style={tomorrowNight}>{JSON.stringify(codeString, null, 2)}</SyntaxHighlighter>
            </Modal>
        </>
    );
}
