import { Tooltip, Button, Divider, Input, notification } from 'antd';
import { SyncOutlined, SendOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef, useMemo } from 'react';
import { getInterface, getSetting } from '../../services/chatbotServices';
import { getUserInfo } from '../../services/authServices';
import { getLevel } from '../../services/adminServices';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import useTranslation from '../../locales/translations';

export default function MainWindows(props) {
    const translation = useTranslation().chatbot.mainwindows;
    const botId = props.botId;
    const [initialMsg, setInitialMsg] = useState('');
    const [suggestMsg, setSuggestMsg] = useState('');
    const [picture, setPicture] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [messageState, setMessageState] = useState({
        messages: [],
        pending: '',
        sources: [],
    });
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [baseprompt, setBaseprompt] = useState('');
    const [temperature, setTemperature] = useState(0.7);
    const [rememberconv, setRememberconv] = useState('');

    const chatContentRef = useRef(null);
    const suggestMsgRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(60);

    const [addContext, setAddContext] = useState('');
    const [context, setContext] = useState('');
    const [numberconv, setNumberconv] = useState(20);
    const [addlogs, setAddlogs] = useState('');
    const [addsource, setAddsource] = useState('');
    const [summarize, setSummarize] = useState('');
    const [model, setModel] = useState('');
    const [maxTokens, setMaxTokens] = useState(1000);
    const [topP, setTopP] = useState(0.01);
    const [bestOf, setBestOf] = useState(1);
    const [frequency, setFrequency] = useState(0.01);
    const [presence, setPresence] = useState(0.01);
    const [language, setLanguage] = useState('french');
    const [tone, setTone] = useState('friendly');
    const [profession, setProfession] = useState('none');
    const [chatId, setChatId] = useState(null);
    const [level, setLevel] = useState();
    const chatSource = translation.CHATSOURCE_SITE;

    const { messages, pending } = messageState;
    const [user] = useState(getUserInfo());

    const fetchInterface = async () => {
        try {
            await getInterface(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    setInitialMsg(bot.initialMsg);
                    setSuggestMsg(bot.suggestMsg);
                    setPicture(bot.picture);
                    setDisplayName(bot.displayName);
                    const temp = [];
                    if (bot.initialMsg)
                        bot.initialMsg.split('\n').map((msg) => {
                            temp.push({ content: msg, role: 'assistant' });
                            return null;
                        });
                    setMessageState((state) => ({
                        ...state,
                        messages: temp,
                    }));
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchSetting = async () => {
        try {
            await getSetting(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    setBaseprompt(bot.baseprompt);
                    setTemperature(bot.temperature);
                    setAddContext(bot.addContext);
                    setContext(bot.context);
                    setRememberconv(bot.rememberconv);
                    setNumberconv(bot.numberconv);
                    setAddlogs(bot.addlogs);
                    setAddsource(bot.addsource);
                    setSummarize(bot.summarize);
                    setModel(bot.model);
                    setMaxTokens(bot.maxTokens);
                    setTopP(bot.topP);
                    setBestOf(bot.bestOf);
                    setFrequency(bot.frequency);
                    setPresence(bot.presence);
                    setLanguage(bot.language);
                    setTone(bot.tone);
                    setProfession(bot.profession);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchLevel = async () => {
        try {
            const res = await getLevel(user.level);
            setLevel(res.data);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchInterface();
        fetchSetting();
        fetchLevel();
        document.getElementById('query').addEventListener('keypress', function (event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (document.getElementById('query') !== '') {
                    document.getElementById('send').click();
                }
            }
        });
    }, [botId]);

    useEffect(() => {
        chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        if (query) {
            setMessageState((state) => ({
                ...state,
                messages: [...state.messages, { content: query, role: 'user' }],
                pending: '',
            }));
            setIsFetching(true);
        }
        const suggestMsgHeight = suggestMsgRef.current ? suggestMsgRef.current.offsetHeight : 0;
        setMarginBottom(user.role === 'user' ? 80 + suggestMsgHeight : 60 + suggestMsgHeight);
    }, [query]);

    useEffect(() => {
        if (isFetching) {
            document.getElementById('loading').style.display = 'flex';
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
            const ctrl = new AbortController();
            async function fetchAnswer() {
                try {
                    await fetchEventSource(`/api/chatbot/getReply`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            namespace: botId,
                            messages,
                            baseprompt,
                            temperature,
                            addContext,
                            context,
                            rememberconv,
                            numberconv,
                            addlogs,
                            addsource,
                            summarize,
                            model,
                            maxTokens,
                            topP,
                            bestOf,
                            frequency,
                            presence,
                            language,
                            tone,
                            profession,
                            chatId,
                            chatSource,
                            isIncrease: user.role === 'user' ? true : false,
                            botUserID: user._id,
                        }),
                        signal: ctrl.signal,
                        onmessage: (event) => {
                            document.getElementById('loading').style.display = 'none';
                            if (JSON.parse(event.data).data === '[DONE]') {
                                setMessageState((state) => ({
                                    messages: [
                                        ...state.messages,
                                        {
                                            content: state.pending ?? '',
                                            role: 'assistant',
                                        },
                                    ],
                                    pending: '',
                                    sources: state.sources,
                                }));
                                setQuery('');
                                setIsFetching(false);
                                setMsg('');
                                props.setIsGetAnswer(true);
                                ctrl.abort();
                            } else {
                                const data = JSON.parse(event.data);
                                const chatId = JSON.parse(event.data).chatId;
                                if (chatId !== null) {
                                    setChatId(chatId);
                                }
                                setMessageState((state) => ({
                                    ...state,
                                    pending: (state.pending ?? '') + data.data,
                                    sources: data.matches,
                                }));
                            }
                        },
                    });
                } catch (err) {
                    document.getElementById('loading').style.display = 'none';
                    notification.error({
                        message: translation.notification.ERROR,
                        placement: 'topRight',
                    });
                }
            }
            fetchAnswer();
        }
    }, [isFetching]);

    const chatMessages = useMemo(() => {
        return [
            ...messages,
            ...(pending
                ? [
                      {
                          content: pending,
                          role: 'assistant',
                      },
                  ]
                : []),
        ];
    }, [messages, pending]);

    //scroll to bottom of chat
    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [chatMessages]);

    useEffect(() => {
        if (props.isSaved) {
            fetchInterface();
            fetchSetting();
            props.setIsSaved(false);
        }
    }, [props.isSaved]);

    const handleSendQuery = (message) => {
        if (!query) {
            setQuery(message);
        }
    };

    const pageReload = () => {
        if (!query) {
            setMessageState({ messages: [], pending: '' });
            const temp = [];
            initialMsg.split('\n').map((msg) => {
                temp.push({ content: msg, role: 'assistant' });
                return null;
            });
            setMessageState((state) => ({
                ...state,
                messages: temp,
            }));
            setChatId(null);
        }
    };

    const handleInputChange = (e) => {
        setMsg(e.target.value);
    };

    return (
        <>
            <div className="history">
                <div className="display-flex margin-12 align-center">
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                fontWeight: 700,
                                fontSize: 20,
                                alignItems: 'center',
                            }}>
                            {picture ? (
                                <img
                                    src={'/uploads/' + picture}
                                    alt="profile"
                                    className="profile-image"
                                    crossOrigin="anonymous"
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 283.46 283.46"
                                    className="profile-image">
                                    <g id="Calque_2" data-name="Calque 2">
                                        <g id="Calque_1-2" data-name="Calque 1">
                                            <circle cx="141.73" cy="141.73" r="141.73" style={{ fill: '#21dbcd' }} />
                                            <circle
                                                cx="108.6"
                                                cy="142.2"
                                                r="22.09"
                                                style={{
                                                    fill: '#fff',
                                                }}
                                            />
                                            <circle
                                                cx="174.88"
                                                cy="142.2"
                                                r="22.09"
                                                style={{
                                                    fill: '#fff',
                                                }}
                                            />
                                            <path
                                                style={{
                                                    fill: '#fff',
                                                }}
                                                d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            )}
                            &nbsp;{displayName}
                        </div>
                    </div>
                    <Tooltip title={translation.REFRESH}>
                        <Button icon={<SyncOutlined style={{ fontSize: 20 }} />} type="text" onClick={pageReload} />
                    </Tooltip>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className="chat-content" ref={chatContentRef} style={{ marginBottom: marginBottom }}>
                    {chatMessages.map((message, index) => (
                        <div key={index} className={`${message.role === 'user' ? 'customer' : 'bot'}-text`}>
                            <ReactMarkdown
                                remarkPlugins={[remarkMath, rehypeKatex]}
                                linkTarget="_blank"
                                rel="noopener noreferrer">
                                {message.content}
                            </ReactMarkdown>
                        </div>
                    ))}

                    <div className="bot-text" id="loading" style={{ display: 'none' }}>
                        <div className="dot-flashing" />
                    </div>
                </div>
                {user.role === 'user' ? (
                    <span className="message-credit">
                        {level && level.numberOfQueries - user.queries}
                        {translation.MESSAGE_LEFT}
                    </span>
                ) : null}
                <div
                    className="suggestmsg-container"
                    style={user.role === 'user' ? { bottom: 88 } : {}}
                    ref={suggestMsgRef}>
                    {suggestMsg.split('\n').map((msg, index) => (
                        <Button
                            key={index}
                            className="suggestmsg"
                            style={{ display: `${msg.length ? '' : 'none'}` }}
                            onClick={() => handleSendQuery(msg)}>
                            {msg}
                        </Button>
                    ))}
                </div>
                <div className="question-container">
                    <Input
                        id="query"
                        placeholder={translation.INPUT_PLACEHOLDER}
                        size="large"
                        value={msg}
                        onChange={handleInputChange}
                        disabled={isFetching}
                        suffix={
                            <Tooltip title={translation.SEND}>
                                <Button
                                    id="send"
                                    icon={<SendOutlined style={{ fontSize: 20 }} />}
                                    type="text"
                                    onClick={() => handleSendQuery(msg)}
                                />
                            </Tooltip>
                        }
                        autoComplete="off"
                    />
                </div>
            </div>
            {messageState.sources && !isFetching ? (
                <div className="sources">
                    <p className="settings-title">Sources</p>
                    {messageState.sources.map((source, index) => (
                        <>
                            <div className="source-text">{source.text}</div>
                            {index !== messageState.sources.length - 1 ? <Divider /> : null}
                        </>
                    ))}
                </div>
            ) : null}
        </>
    );
}
