import { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, notification, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getChatbots, getList, deleteOne, updateOne } from '../../services/adminServices';
import { deleteChatbot } from '../../services/chatbotServices';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function PineconeIndexes() {
    const translation = useTranslation().admin.pinecone;
    const [data, setData] = useState([]);
    const [details, setDetails] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [editText, setEditText] = useState(null);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchChatbots = async () => {
        try {
            setLoading(true);
            const res = await getChatbots();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            setData(temp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    const fetchDetail = async () => {
        try {
            setLoading(true);
            const res = await getList();
            const temp = res.data.map((data) => ({
                key: data._id,
                ...data,
            }));
            setDetails(temp);
            setLoading(false);
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        fetchChatbots();
        fetchDetail();
    }, []);

    const columns = [
        {
            title: translation.ID,
            dataIndex: 'id',
            key: 'id',
            width: 70,
            render: (text, record, index) => index + 1,
        },
        {
            title: translation.NAMESPACE,
            dataIndex: 'botId',
            key: 'botId',
            width: 220,
            render: (text, record) => record._id,
        },
        {
            title: translation.USER,
            dataIndex: 'userEmail',
            key: 'userEmail',
            width: 200,
        },
        {
            title: translation.NAME,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translation.SOURCES,
            dataIndex: 'sources',
            key: 'sources',
            render: (text, record) => (
                <span>
                    {false && record.files.length ? `${record.files.length} ${translation.FILES}` : ''}
                    {false && record.files.length && (record.text.length || record.links.length || record.videos.length)
                        ? ', '
                        : ''}
                    {record.text.length ? `${record.text.length} ${translation.CHARACTERS}` : ''}
                    {false && record.text.length && (record.links.length || record.videos.length) ? ', ' : ''}
                    {false && record.links.length ? `${record.links.length} ${translation.LINKS}` : ''}
                    {false && record.links.length && record.videos.length ? ', ' : ''}
                    {false && record.videos.length ? `${record.videos.length} ${translation.VIDEOS}` : ''}
                </span>
            ),
        },
        {
            title: translation.ACTION,
            dataIndex: 'action',
            key: 'action',
            width: 70,
            render: (text, record) => (
                <Popconfirm
                    title={translation.DELETE_TITLE}
                    description={translation.DESCRIPTION}
                    onConfirm={() => handleDelete(record)}
                    okText={translation.DELETE_OKTEXT}
                    cancelText={translation.DELETE_CANCELTEXT}
                    okButtonProps={{
                        loading: deleting,
                    }}>
                    <Button danger icon={<DeleteOutlined />} type="text" disabled={deleting} />
                </Popconfirm>
            ),
        },
    ];

    const handleDelete = async (record) => {
        try {
            setDeleting(true);
            await deleteChatbot(record._id);
            fetchChatbots();
            fetchDetail();
            notification.success({
                message: translation.notification.DELETE_SUCCESS,
                placement: 'topRight',
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setDeleting(false);
        }
    };

    const handleDetailDelete = async (detail, record) => {
        try {
            setDeleting(true);
            const data = detail.filter((data) => data._id !== record._id);
            await deleteOne(data, record);
            fetchChatbots();
            fetchDetail();
            notification.success({
                message: translation.notification.DELETE_SUCCESS,
                placement: 'topRight',
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setDeleting(false);
        }
    };

    const handleOpen = (record) => {
        setOpenEdit(true);
        setEditText(record.text);
        setCurrentRecord(record);
    };

    const handleCancel = () => {
        setOpenEdit(false);
        setEditText(null);
        setCurrentRecord(null);
    };

    const handleDetailChange = async (detail, record) => {
        try {
            setUpdating(true);
            const data = detail.map((data) => (data._id === record._id ? { ...data, text: editText } : data));
            const recordData = { ...record, text: editText };
            await updateOne(data, recordData);
            fetchChatbots();
            fetchDetail();
            notification.success({
                message: translation.notification.EDIT_SUCCESS,
                placement: 'topRight',
            });
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
        } finally {
            setUpdating(false);
            setOpenEdit(false);
            setEditText(null);
            setCurrentRecord(null);
        }
    };

    const expandedRowRender = (record) => {
        const detail = details.filter((data) => data.namespace === record.key);
        const columns = [
            {
                title: translation.detail.ID,
                dataIndex: 'id',
                key: 'id',
                width: 40,
                render: (text, record, index) => index + 1,
            },
            {
                title: translation.detail.TYPE,
                dataIndex: 'type',
                key: 'type',
                width: 80,
            },
            {
                title: translation.detail.SOURCE,
                dataIndex: 'source',
                key: 'source',
            },
            {
                title: translation.detail.DATA,
                dataIndex: 'text',
                key: 'text',
                render: (text, record) => (
                    <div className="history-content" style={{ color: 'black' }}>
                        {text}
                    </div>
                ),
            },
            {
                title: translation.detail.LAST_UPDATED,
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: 170,
                render: (date) => new Date(date).toLocaleString(),
            },
            {
                title: translation.detail.ACTION,
                dataIndex: 'action',
                key: 'action',
                width: 90,
                render: (text, record) => (
                    <>
                        <Button
                            icon={<EditOutlined />}
                            style={{ border: 'none' }}
                            onClick={() => handleOpen(record)}
                            type="primary"
                            ghost
                        />
                        &nbsp;&nbsp;
                        <Popconfirm
                            title={translation.DELETE_TITLE}
                            description={translation.DESCRIPTION}
                            onConfirm={() => handleDetailDelete(detail, record)}
                            okText={translation.DELETE_OKTEXT}
                            cancelText={translation.DELETE_CANCELTEXT}
                            okButtonProps={{
                                loading: deleting,
                            }}>
                            <Button danger icon={<DeleteOutlined />} type="text" disabled={deleting} />
                        </Popconfirm>
                    </>
                ),
            },
        ];
        return (
            <>
                <Table columns={columns} dataSource={detail} pagination={false} bordered size="small" />
                <Modal
                    title={translation.EDIT_TITLE}
                    open={openEdit}
                    onOk={() => handleDetailChange(detail, currentRecord)}
                    onCancel={handleCancel}
                    okText={translation.EDIT_OKTEXT}
                    cancelText={translation.EDIT_CANCELTEXT}
                    width={1024}
                    okButtonProps={{ loading: updating }}>
                    <TextArea rows={10} value={editText} onChange={(e) => setEditText(e.target.value)}></TextArea>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div
                className="main-loading"
                style={{
                    display: loading ? 'block' : 'none',
                }}>
                <div
                    className="container"
                    style={{
                        display: loading ? 'block' : 'none',
                    }}>
                    <div className="main-lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="dashboard main-layout margin-12">
                <div style={{ fontSize: 32, fontWeight: 700 }}>{translation.TITLE}</div>
                <br />
                <Table
                    columns={columns}
                    dataSource={data}
                    bordered
                    expandable={{ expandedRowRender }}
                    scroll={{
                        x: 700,
                    }}
                />
            </div>
        </>
    );
}
