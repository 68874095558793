import { useState, useEffect, useRef } from 'react';
import { Input, Button, Select, Row, Col, Divider, notification, Checkbox, ColorPicker } from 'antd';
import { SyncOutlined, SendOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { getInterface, pictureuploads, updateInterface } from '../../services/chatbotServices';
import { getDefaultInterface } from '../../services/adminServices';
import useTranslation from '../../locales/translations';

const { TextArea } = Input;

export default function Interface(props) {
    const translation = useTranslation().chatbot.inferface;
    const botId = props.botId;

    const [initialMsg, setInitialMsg] = useState('');
    const [suggestMsg, setSuggestMsg] = useState('');
    const [theme, setTheme] = useState(null);
    const [pictureFile, setPictureFile] = useState(null);
    const [picture, setPicture] = useState('');
    const [removePicture, setRemovePicture] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [msgColor, setMsgColor] = useState(null);
    const [iconFile, setIconFile] = useState(null);
    const [icon, setIcon] = useState('');
    const [removeIcon, setRemoveIcon] = useState(null);
    const [buttonColor, setButtonColor] = useState(null);
    const [buttonAlign, setButtonAlign] = useState(null);
    const [formatRgb, setFormatRgb] = useState(null);

    const [defaultInitialMsg, setDefaultInitialMsg] = useState(null);
    const [defaultMsgColor, setDefaultMsgColor] = useState(null);
    const [defaultButtonColor, setDefaultButtonColor] = useState(null);

    const chatContentRef = useRef(null);
    const suggestMsgRef = useRef(null);
    const [marginBottom, setMarginBottom] = useState(60);
    const [isSaving, setIsSaving] = useState(false);

    const fetchInterface = async () => {
        try {
            await getInterface(botId).then((res) => {
                if (res.data) {
                    const bot = res.data;
                    if (bot.initialMsg && bot.initialMsg.length) {
                        setInitialMsg(bot.initialMsg);
                    }
                    if (bot.suggestMsg && bot.suggestMsg.length) {
                        setSuggestMsg(bot.suggestMsg);
                    }
                    if (bot.theme && bot.theme.length) {
                        setTheme(bot.theme);
                    }
                    if (bot.picture && bot.picture.length) {
                        setPicture(bot.picture);
                    }
                    setRemovePicture(bot.removePicture);
                    if (bot.displayName && bot.displayName.length) {
                        setDisplayName(bot.displayName);
                    }
                    if (bot.msgColor && bot.msgColor.length) {
                        setMsgColor(bot.msgColor);
                    }
                    if (bot.icon && bot.icon.length) {
                        setIcon(bot.icon);
                    }
                    setRemoveIcon(bot.removeIcon);
                    if (bot.buttonColor && bot.buttonColor.length) {
                        setButtonColor(bot.buttonColor);
                    }
                    if (bot.buttonAlign && bot.buttonAlign.length) {
                        setButtonAlign(bot.buttonAlign);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fetchDefaultInterface = async () => {
        try {
            await getDefaultInterface().then((res) => {
                if (res.data) {
                    const bot = res.data[0];
                    if (bot.initialMsg && bot.initialMsg.length) {
                        setDefaultInitialMsg(bot.initialMsg);
                    }
                    if (bot.msgColor && bot.msgColor.length) {
                        setDefaultMsgColor(bot.msgColor);
                    }
                    if (bot.buttonColor && bot.buttonColor.length) {
                        setDefaultButtonColor(bot.buttonColor);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchDefaultInterface();
        fetchInterface();
    }, [botId]);

    useEffect(() => {
        const suggestMsgHeight = suggestMsgRef.current ? suggestMsgRef.current.offsetHeight : 0;
        setMarginBottom(60 + suggestMsgHeight);
    }, [suggestMsg]);

    //interface settings

    const handleInitialMsg = (e) => {
        setInitialMsg(e.target.value);
    };

    const resetInitialMsg = () => {
        setInitialMsg(defaultInitialMsg);
    };

    const handleSuggestMsg = (e) => {
        setSuggestMsg(e.target.value);
    };

    const handleChangeTheme = (e) => {
        setTheme(e);
    };

    const handleProfileClick = () => {
        document.getElementById('profile').click();
    };

    const handleChangeProfile = (event) => {
        const file = event.target.files[0];
        setPictureFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            setPicture(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemovePicture = (e) => {
        setRemovePicture(e.target.checked);
        if (e.target.checked) {
            setPicture('');
            setPictureFile(null);
        }
    };

    const handleChangeDisplayName = (e) => {
        setDisplayName(e.target.value);
    };

    const handleMsgColor = (e) => {
        setMsgColor(e.toRgbString());
    };

    const resetMsgColor = () => {
        setMsgColor(defaultMsgColor);
    };

    const handleIconClick = () => {
        document.getElementById('icon').click();
    };

    const handleChangeIcon = (event) => {
        const file = event.target.files[0];
        setIconFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            setIcon(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = (e) => {
        setRemoveIcon(e.target.checked);
        if (e.target.checked) {
            setIcon('');
            setIconFile(null);
        }
    };

    const handleButtonColor = (e) => {
        setButtonColor(e.toRgbString());
    };

    const resetButtonColor = () => {
        setButtonColor(defaultButtonColor);
    };

    const handleButtonAlign = (e) => {
        setButtonAlign(e);
    };

    const getLuminance = (color) => {
        if (color !== null) {
            const rgb = color.match(/\d+/g);

            const r = rgb[0];
            const g = rgb[1];
            const b = rgb[2];

            const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

            return luminance;
        }
    };

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            setIsSaving(true);
            let pictureImg = '';
            let iconImg = '';
            if (pictureFile) {
                const formData = new FormData();
                formData.append('image', pictureFile);
                pictureImg = await pictureuploads(formData);
            } else {
                pictureImg = picture;
            }

            if (iconFile) {
                const formData = new FormData();
                formData.append('image', iconFile);
                iconImg = await pictureuploads(formData);
            } else {
                iconImg = icon;
            }

            await updateInterface(
                botId,
                initialMsg,
                suggestMsg,
                theme,
                pictureImg,
                removePicture,
                displayName,
                msgColor,
                iconImg,
                removeIcon,
                buttonColor,
                buttonAlign,
            );
            notification.success({
                message: translation.notification.SUCCESS,
                placement: 'topRight',
            });

            setIsSaving(false);
            props.setIsSaved(true);
        } catch (err) {
            notification.error({
                message: translation.notification.ERROR,
                placement: 'topRight',
            });
            setIsSaving(false);
        }
    };

    return (
        <>
            <div className="text-left margin-32">
                <div id="interfaceSettings">
                    <div className="settings-title">{translation.MAIN_TITLE}</div>
                    <p className="settings-content" style={{ color: 'black' }}>
                        {translation.DESCRIPTION}
                    </p>
                    <br />
                    <Row gutter={[32, 16]}>
                        <Col className="interface-col">
                            <div className="display-flex">
                                <div className="settings-subtitle">{translation.INITIAL_MESSAGES}</div>
                                <Button size="small" onClick={resetInitialMsg}>
                                    {translation.RESET}
                                </Button>
                            </div>
                            <TextArea rows={2} value={initialMsg} onChange={handleInitialMsg} />
                            <div className="settings-content">
                                <p>{translation.INITIAL_DES}</p>
                            </div>
                            <div className="settings-subtitle">{translation.SUGGESTED_MESSAGES}</div>
                            <TextArea
                                rows={2}
                                placeholder={translation.SUGGEST_PLACEHOLDER}
                                value={suggestMsg}
                                onChange={handleSuggestMsg}
                            />
                            <div className="settings-content">
                                <p>{translation.SUGGEST_DES}</p>
                            </div>
                            <div className="settings-subtitle">{translation.theme.TITLE}</div>
                            <Select
                                className="width-100"
                                value={theme}
                                options={[
                                    {
                                        value: 'light',
                                        label: translation.theme.LIGHT,
                                    },
                                    {
                                        value: 'dark',
                                        label: translation.theme.DARK,
                                    },
                                ]}
                                onChange={handleChangeTheme}
                            />
                            <br />
                            <br />

                            {removePicture ? (
                                <></>
                            ) : (
                                <>
                                    <div className="settings-subtitle">{translation.PICTURE}</div>
                                    <Input
                                        type="file"
                                        id="profile"
                                        accept=".png,.jpg,.bmp,.jpeg,.ico"
                                        multiple
                                        onChange={handleChangeProfile}
                                        style={{ display: 'none' }}
                                        autoComplete="off"
                                    />
                                    <Button icon={<UploadOutlined />} onClick={handleProfileClick}>
                                        {translation.PICTURE_UPLOAD}
                                    </Button>
                                    <br />
                                </>
                            )}

                            <div className="settings-subtitle">{translation.REMOVE_PICTURE}</div>
                            <Checkbox checked={removePicture} onChange={handleRemovePicture} />
                            <br />
                            <br />
                            <div className="settings-subtitle">{translation.DISPLAY_NAME}</div>
                            <Input value={displayName} onChange={handleChangeDisplayName} autoComplete="off" />
                            <br />
                            <br />
                            <div className="display-flex">
                                <div className="settings-subtitle">{translation.PICTURE_COLOR}</div>
                                <Button size="small" onClick={resetMsgColor}>
                                    {translation.RESET}
                                </Button>
                            </div>
                            <ColorPicker
                                value={msgColor}
                                onChange={handleMsgColor}
                                format={formatRgb}
                                onFormatChange={setFormatRgb}
                            />
                            <p className="settings-content" style={{ color: 'black' }}>
                                {translation.PICTURE_DES}
                            </p>
                            {removeIcon ? (
                                <></>
                            ) : (
                                <>
                                    <div className="settings-subtitle">{translation.ICON}</div>
                                    <Input
                                        type="file"
                                        id="icon"
                                        accept=".png,.jpg,.bmp,.jpeg,.ico"
                                        multiple
                                        onChange={handleChangeIcon}
                                        style={{ display: 'none' }}
                                        autoComplete="off"
                                    />
                                    <Button icon={<UploadOutlined />} onClick={handleIconClick}>
                                        {translation.ICON_UPLOAD}
                                    </Button>
                                    <br />
                                </>
                            )}
                            <div className="settings-subtitle">{translation.REMOVE_ICON}</div>
                            <Checkbox checked={removeIcon} onChange={handleRemoveIcon} />
                            <br />
                            <br />
                            <div className="display-flex">
                                <div className="settings-subtitle">{translation.ICON_COLOR}</div>
                                <Button size="small" onClick={resetButtonColor}>
                                    {translation.RESET}
                                </Button>
                            </div>
                            <ColorPicker
                                value={buttonColor}
                                onChange={handleButtonColor}
                                format={formatRgb}
                                onFormatChange={setFormatRgb}
                            />
                            <br />
                            <br />
                            <div className="settings-subtitle">{translation.ICON_ALIGN}</div>
                            <Select
                                className="width-100"
                                value={buttonAlign}
                                options={[
                                    {
                                        value: 'right',
                                        label: translation.ALIGN_RIGHT,
                                    },
                                    {
                                        value: 'left',
                                        label: translation.ALIGN_LEFT,
                                    },
                                ]}
                                onChange={handleButtonAlign}
                            />
                        </Col>
                        <Col className="interface-col">
                            <div
                                className="history"
                                style={{
                                    background: `${theme === 'light' ? 'white' : '#2e2d2c'}`,
                                }}>
                                <div className="display-flex margin-12 align-center">
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                fontWeight: 700,
                                                fontSize: 20,
                                                alignItems: 'center',
                                                color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                            }}>
                                            {picture ? (
                                                <img
                                                    src={
                                                        pictureFile
                                                            ? picture
                                                            : '/uploads/' + picture
                                                    }
                                                    alt="profile"
                                                    className="profile-image"
                                                    crossOrigin="anonymous"
                                                />
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 283.46 283.46"
                                                    className="profile-image">
                                                    <g id="Calque_2" data-name="Calque 2">
                                                        <g id="Calque_1-2" data-name="Calque 1">
                                                            <circle
                                                                cx="141.73"
                                                                cy="141.73"
                                                                r="141.73"
                                                                style={{ fill: '#21dbcd' }}
                                                            />
                                                            <circle
                                                                cx="108.6"
                                                                cy="142.2"
                                                                r="22.09"
                                                                style={{
                                                                    fill: '#fff',
                                                                }}
                                                            />
                                                            <circle
                                                                cx="174.88"
                                                                cy="142.2"
                                                                r="22.09"
                                                                style={{
                                                                    fill: '#fff',
                                                                }}
                                                            />
                                                            <path
                                                                style={{
                                                                    fill: '#fff',
                                                                }}
                                                                d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            )}
                                            &nbsp;{displayName}
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            icon={<SyncOutlined style={{ fontSize: 20 }} />}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                            }}
                                        />
                                        <Button
                                            icon={<CloseOutlined style={{ fontSize: 20 }} />}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                            }}
                                        />
                                    </div>
                                </div>
                                <Divider
                                    style={{
                                        background: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                        margin: 0,
                                    }}
                                />
                                <div
                                    className="chat-content"
                                    ref={chatContentRef}
                                    style={{ marginBottom: marginBottom }}>
                                    {`${initialMsg}`.split('\n').map((msg, index) => (
                                        <div
                                            key={index}
                                            className="bot-text"
                                            style={{
                                                display: `${msg.length ? 'flex' : 'none'}`,
                                                padding: 12,
                                            }}>
                                            {msg}
                                        </div>
                                    ))}

                                    <div
                                        className="customer-text"
                                        style={{
                                            backgroundColor: `${msgColor}`,
                                            color: `${getLuminance(msgColor) > 180 ? '#2e2d2c' : 'white'}`,
                                            padding: 12,
                                        }}>
                                        {translation.HI}
                                    </div>
                                </div>
                                <div className="suggestmsg-container" ref={suggestMsgRef}>
                                    {`${suggestMsg}`.split('\n').map((msg, index) => (
                                        <Button
                                            key={index}
                                            className="suggestmsg"
                                            style={{ display: `${msg.length ? '' : 'none'}` }}>
                                            {msg}
                                        </Button>
                                    ))}
                                </div>
                                <div className="question-container">
                                    <Input
                                        placeholder={translation.INPUT_PLACEHOLDER}
                                        size="large"
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                        className={`${theme === 'light' ? 'input-black' : 'input-white'}`}
                                        suffix={
                                            <Button
                                                icon={<SendOutlined style={{ fontSize: 20 }} />}
                                                style={{
                                                    border: 'none',
                                                    background: 'transparent',
                                                    color: `${theme === 'light' ? '#2e2d2c' : 'white'}`,
                                                }}
                                            />
                                        }
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <br />
                            {icon ? (
                                <img
                                    src={iconFile ? icon : '/uploads/' + icon}
                                    alt="icon"
                                    crossOrigin="anonymous"
                                    className={`button-image ${buttonAlign === 'right' ? 'float-right' : 'float-left'}`}
                                />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 283.46 283.46"
                                    className={`button-image ${
                                        buttonAlign === 'right' ? 'float-right' : 'float-left'
                                    }`}>
                                    <g id="Calque_2" data-name="Calque 2">
                                        <g id="Calque_1-2" data-name="Calque 1">
                                            <circle
                                                cx="141.73"
                                                cy="141.73"
                                                r="141.73"
                                                style={{ fill: `${buttonColor}` }}
                                            />
                                            <circle
                                                cx="108.6"
                                                cy="142.2"
                                                r="22.09"
                                                style={{
                                                    fill: `${getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'}`,
                                                }}
                                            />
                                            <circle
                                                cx="174.88"
                                                cy="142.2"
                                                r="22.09"
                                                style={{
                                                    fill: `${getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'}`,
                                                }}
                                            />
                                            <path
                                                style={{
                                                    fill: `${getLuminance(buttonColor) > 180 ? '#2e2d2c' : 'white'}`,
                                                }}
                                                d="M174.88,75.92h-11L141.74,53.83,119.65,75.92H108.6s-66.27,0-66.27,66.28,66.27,66.27,66.27,66.27h11.05l22.09-22.09H108.6s-44.18,0-44.18-44.18S108.6,98,108.6,98h66.28s44.18,0,44.18,44.19-44.18,44.18-44.18,44.18H141.74l22.09,22.09h11s66.28,0,66.28-66.27S174.88,75.92,174.88,75.92Z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </Col>
                    </Row>
                </div>
                <Button size="large" type="primary" className="float-right" onClick={handleSave} loading={isSaving}>
                    {isSaving ? translation.SAVE_LOADING : translation.SAVE_CHANGES}
                </Button>
            </div>
        </>
    );
}
